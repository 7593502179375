import {
  Alert,
  Button,
  ButtonGroup,
  EditableText,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useContext, useState } from "react";
import {  useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Page } from "../../utils/Layout/Layout";
import ListComponent from "../../utils/ListComponent/ListComponent";
import StatusBar from "../../utils/StatusBar/StatusBar";
import Status from "../../utils/StatusLabel/Status";
import TextEdit from "../../utils/TextEdit/TextEdit";
import { dateTimeFormatter } from "../../utils/Utils/Utils";
import RelatedEquipments from "../Equipment/RelatedEquipments";
import { TorgaContext } from "../TorgaContext";
import { ObjectTypes, urlToActivityID, urlToReview } from "../TorgaRouter";
import {
  actionActivityAdd,
  actionActivityEquipmentAdd,
  actionActivityEquipmentRemove,
  actionActivityPersist,
} from "./actions";
import "./activity.css";
import Fields from "./Fields";
import { useNavigate } from "react-router-dom";

const Input = styled.input`
  width: 100%;
  border: none;

  padding: 4px 0;
  color: #ced9e0;

  &:focus {
    color: #182026;
  }
  &::placeholder {
    color: #ced9e0;
  }
`;

export default function Activity() {
  const { state, dispatch, dispatchAction } = useContext(TorgaContext);
  const [alertOpen, setAlertOpen] = useState(false);
  let { id: prefixedID } = useParams();
  const id = prefixedID?.slice(1);
  const { [id]: activity } = state.activities.byID;
  const navigate = useNavigate();

  if (!id) {
    return <NonIdealState icon={IconNames.PULSE} />;
  }

  if (!activity) {
    return <div>Loading...</div>;
  }

  const { [activity.parent_id]: parent } = state.activities.byID;
  const { [activity.id]: childrenIDs } = state.activities.idsBy.parentID;
  const activities = childrenIDs?.map(
    (activityID) => state.activities.byID[activityID]
  );

  function handleChange(field, value) {
    dispatch({ type: "ACTIVITY_CHANGE", payload: { id, field, value } });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionActivityPersist(id, field, value));
  }

  function drawLine(field, placeholder, multiline) {
    return (
      <tr>
        <td>{field.charAt(0).toUpperCase() + field.slice(1)}</td>
        <td>
          <EditableText
            multiline={multiline}
            placeholder={placeholder || "Add " + field + " ..."}
            value={activity[field]}
            onChange={(e) => handleChange(field, e)}
            onConfirm={(e) => handleConfirm(field, e)}
            selectAllOnFocus
          />
        </td>
      </tr>
    );
  }

  return (
    <Page>
      <h1>
        {" "}
        A{activity.id}
        {" - "}
        <div style={{ float: "right", textAlign: "right" }}>
          <EditableText
            placeholder="Icon"
            value={activity.icon}
            onChange={(e) => handleChange("icon", e)}
            onConfirm={(e) => handleConfirm("icon", e)}
            selectAllOnFocus={true}
          />
        </div>
        <EditableText
          placeholder="Activity Name"
          value={activity.name}
          onChange={(e) => handleChange("name", e)}
          onConfirm={(e) => handleConfirm("name", e)}
          selectAllOnFocus={true}
        />
      </h1>
      <StatusBar
        status={activity.status}
        onChange={(status) => handleConfirm("status", status)}
      />{" "}
      <ButtonGroup>
        <Button
          text="Move"
          draggable={true}
          onDragEnd={(e) => { }}
          onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", id);
            e.dataTransfer.dropEffect = "move";
          }}
        />
      </ButtonGroup>{" "}
      <ButtonGroup>
        <Button
          onClick={(e) => {
            handleConfirm("next_review", new Date());
            navigate(urlToReview());
          }}
        >
          Reviewed
        </Button>
      </ButtonGroup>{" "}
      <Button icon={IconNames.TRASH} onClick={(e) => setAlertOpen(true)}>
        Delete
      </Button>
      <hr />


      <Fields
        object={activity}
        handleConfirm={(field, value) => {
          dispatchAction(actionActivityPersist(id, field, value));
        }}
      />


      <table>
        <tbody>

          {parent !== undefined && (
            <tr>
              <td>Parent</td>
              <td>
                <Link to={urlToActivityID(activity.parent_id)}>
                  {parent.name}
                </Link>
              </td>
            </tr>
          )}



        </tbody>
      </table>
      <hr />
      <EditableText
        multiline
        minLines={3}
        value={activity.content}
        onChange={(e) => handleChange("content", e)}
        onConfirm={(e) => handleConfirm("content", e)}
      />
      <ListComponent
        objectType={ObjectTypes.ACTIVITIES}
        values={activities}
        render={(activity) => [activity.name]}
        quickentry={{ placeholder: "Add new Activity" }}
        onCreate={(name) =>
          dispatchAction(actionActivityAdd(activity.id, name))
        }
      />
      <RelatedEquipments
        equipmentIDs={state.equipments.idsBy.activityID[id]}
        onEquipementAdd={(equipment_id) =>
          dispatchAction(actionActivityEquipmentAdd(id, equipment_id))
        }
        onEquipementRemove={(equipment_id) =>
          dispatchAction(actionActivityEquipmentRemove(id, equipment_id))
        }
      />
      <h3>Helpers</h3>
      <h4>NEDT</h4>
      <TextEdit
        value={activity.nedt}
        placeholder="NEDT"
        onConfirm={(v) => handleConfirm("nedt", v)}
        onChange={(v) => handleChange("nedt", v)}
      />
      <h4>ATUS</h4>
      <TextEdit
        value={activity.atus}
        placeholder="ATUS"
        onChange={(v) => handleChange("atus", v)}
        onConfirm={(v) => handleConfirm("atus", v)}
      />
      <h4>GTD</h4>
      <TextEdit
        value={activity.gtd}
        placeholder="GTD"
        onConfirm={(v) => handleConfirm("gtd", v)}
        onChange={(v) => handleChange("gtd", v)}
      />
      <div style={{ height: "500px" }}></div>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={alertOpen}
        canEscapeKeyCancel={true}
        onCancel={(e) => setAlertOpen(false)}
        onConfirm={(e) =>
          dispatchAction(
            actionActivityPersist(id, "deleted", true, () => {
              setAlertOpen(false);
            })
          )
        }
      >
        <p> Delete activity ? </p>
      </Alert>
    </Page>
  );
}
