import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchActivities() {
  return (dispatch) =>
    tget("/api/v2/activity").then((result) => {
      dispatch({ type: "FETCH_ACTIVITY_COMPLETE", payload: result });
    });
}

export function actionActivityPersist(id, field, value, callback = null) {
  return (dispatch) =>
    tpost(`/api/v2/activity/${id}/${field}`, {
      [field]: value,
    }).then((result) => {
      dispatch({
        type: "ACTIVITY_PERSIST_COMPLETED",
        payload: [result],
      });
      callback && callback(result);
    });
}

export function actionActivityAdd(parentID, name) {
  return (dispatch) =>
    tpost(`/api/v2/activity`, {
      name: name,
      parentID: parentID,
      next_review: new Date().getTime(),
    }).then((resp) => {
      dispatch({
        type: "ACTIVITY_ADD_COMPLETE",
        payload: [resp],
      });
    });
}

export function actionFetchActivityEquipments() {
  return (dispatch) =>
    tget("/api/v2/activityequipments").then((result) => {
      dispatch({
        type: "ACTIVITYEQUIPMENTS_FETCH_COMPLETE",
        payload: result,
      });
    });
}

export function actionActivityEquipmentAdd(activity_id, equipment_id) {
  return (dispatch) => {
    tpost(`/api/v2/activityequipments`, {
      activity_id,
      equipment_id,
      deleted: false,
    }).then((result) => {
      dispatch({
        type: "ACTIVITYEQUIPMENTS_FETCH_COMPLETE",
        payload: [result],
      });
    });
  };
}
export function actionActivityEquipmentRemove(activity_id, equipment_id) {
  return (dispatch) => {
    tpost(`/api/v2/activityequipments`, {
      activity_id,
      equipment_id,
      deleted: true,
    }).then((result) => {
      dispatch({
        type: "ACTIVITYEQUIPMENTS_FETCH_COMPLETE",
        payload: [result],
      });
    });
  };
}
