import { Button, Icon, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import { actionAppLogout } from "../actions";
import { useAppContext } from "../AppContext";
import "./SideDrawer.css";

const SideDrawer = ({ show, hideDrawer, compact, toggleCompact }) => {
  const { state, dispatchAction } = useAppContext();

  const { menus } = state;

  let drawerClasses = ["side-drawer"];
  if (show) {
    drawerClasses.push("open");
  }

  if (compact) {
    drawerClasses.push("compact");
  }

  return (
    <nav className={drawerClasses.join(" ")}>
      <div className="side-drawer__menuitems">
        <ul>
          {menus?.map((section) => (
            <React.Fragment key={section.name}>
              <div className="drawer__group">{section.name}</div>
              {section.entries.map((entry, id) => (
                <li key={id}>
                  <NavLink
                    to={entry.path}
                    className={({ isActive }) => isActive ? "active" : ""}
                    onClick={() => {
                      hideDrawer();
                    }}
                  >
                    <Icon icon={entry.icon} iconSize={18} />{" "}
                    <span className="menu__text">{entry.name}</span>{" "}
                    {entry.badge &&
                      (state.badges?.[entry.badge] ? (
                        <Tag round>{state.badges?.[entry.badge]}</Tag>
                      ) : (
                        <></>
                      ))}
                    {entry.highlight &&
                      (state.highlights?.[entry.highlight] ? (
                        <div className="sidedrawer__highlight">
                          {state.highlights?.[entry.highlight]}
                        </div>
                      ) : (
                        <></>
                      ))}
                  </NavLink>
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>

      <div className="menu__text app_version">
        {state.auth?.env} {state.auth?.version}
      </div>

      <div className="drawer__footer">
        <div>
          <Button
            icon="log-out"
            onClick={() => dispatchAction(actionAppLogout())}
            minimal
          />
        </div>
        <div>
          <Button
            icon={compact ? IconNames.CHEVRON_RIGHT : IconNames.CHEVRON_LEFT}
            minimal
            onClick={() => {
              toggleCompact();
            }}
          />
        </div>

        <div>
          <NavLink to="/admin" className={(({ isActive }) => isActive ? "active" : "")}>
            <Button icon="cog" minimal />
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default SideDrawer;
