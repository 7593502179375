import React, { useContext } from "react";
import styled from "styled-components";

const Error = styled.div`
  color: red;
`;

export default function ErrorDisplay({ context }) {
  const { state } = useContext(context);

  if (state.error) {
    return <Error>Error: {JSON.stringify(state.error)}</Error>;
  }
  return <div></div>;
}
