import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./app/App";
import { AppContextProvider } from "./app/AppContext";

import "@blueprintjs/core/lib/css/blueprint.css";
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

const root = ReactDOM.createRoot(document.getElementById("app-root"));


root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>
);
