import { tdelete, tget, tpost } from "../utils/Utils/Utils";

export function actionLogin(email, password) {
  return (dispatch) => {
    return fetch("/auth", {
      method: "post",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res });
      })
      .catch((err) => dispatch({ type: "LOGOUT", payload: err }));
  };
}
export function actionSignup(name, email, password) {
  return (dispatch) => {
    return fetch("/register", {
      method: "post",
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res });
      });
  };
}

export function actionAppLogout() {
  return (dispatch) => {
    return tdelete("/auth").then(
      () => {
        dispatch({ type: "LOGOUT" });
      },
      (error) => {
        dispatch({ type: "LOGOUT" });
      }
    );
  };
}

export function actionAppSettingsLoadUsers() {
  return (dispatch) => {
    return (
      tget("/api/v2/user").then((users) => {
        dispatch({ type: "SETTINGS_FETCH_USERS_COMPLETE", payload: users });
      }),
      (error) => {
        dispatch({ type: "ERROR", payload: error });
      }
    );
  };
}

export function actionAppSettingsUpdateUserPassword(userID, newPassword) {
  return (dispatch) => {
    return tpost("/api/v2/user/" + userID + "/password", {
      userid: userID,
      password: newPassword,
    }); // TODO: Feedback in the UI, dispatch something
  };
}

export function actionAppSettingsUpdateUserSettings(userID, settings) {
  return (dispatch) => {
    return tpost("/api/v2/user/" + userID + "/config", {
      config: settings,
    }).then((user) => {
      dispatch({ type: "SETTINGS_UPDATE_USER_COMPLETE", payload: user });
    });
  };
}
