import React from "react";
import { useNavigate } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTorgaContext } from "../TorgaContext";
import { ObjectTypes, urlToDiaryPost } from "../TorgaRouter";
import { actionCreateNewDiaryPost } from "./actions";
import DiaryPost from "./DiaryPost";
import { searchDiaryPosts } from "./search";
import { allDiaryPosts } from "./selectors";
import "./style.css";

export default function Diary() {
  const { state, dispatchAction } = useTorgaContext();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={
            search && search != ""
              ? searchDiaryPosts(state.diaryposts, { text: search })
              : allDiaryPosts(state)
          }
          objectType={ObjectTypes.DIARYPOSTS}
          urlToObject={urlToDiaryPost}
          onChange={setSearch}
          onCreate={() => {
            dispatchAction(
              actionCreateNewDiaryPost((newDiaryPostID) =>
                navigate(urlToDiaryPost(newDiaryPostID))
              )
            );
          }}
        />
      </List>
      <Page>
        <DiaryPost />
      </Page>
    </Browser>
  );
}
