import React from "react";
import { useAppContext } from "../AppContext";

export default function Highlight({ context, name, valueFct }) {
  const { state } = React.useContext(context);
  const { dispatch } = useAppContext();
  const value = valueFct(state);
  React.useEffect(() => {
    dispatch({
      type: "HIGHLIGHT",
      payload: {
        key: name,
        value: value,
      },
    });
  }, [value]);

  return null;
}
