export default {
  errors: [],
  all_errors: {
    byID: {},
    allIDs: [],
  },
  refreshKey: 0,
  auth: {
    auth: false,
    config: {},
    apiToken: {},
    env: null,
    isLoaded: false,
    loading: false,
    message: "",
    sessionID: null,
    userID: null,
    version: "-",
  },
  menus: [],
  badges: {},
  highlights: {},
  console: { display: false },
  apis: [],
  users: {
    byID: {},
    allIDs: [],
  },
};
