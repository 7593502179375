import { Intent, Tag } from "@blueprintjs/core";
import React from "react";

export default function Status({ status }) {
  if (status !== null) {
    const color =
      (status == "To Do" && Intent.PRIMARY) ||
      (status == "In Progress" && Intent.WARNING) ||
      (status == "Done" && Intent.SUCCESS) ||
      Intent.NONE;
    return (
      <Tag intent={color} interactive>
        {status}
      </Tag>
    );
  } else {
    return null;
  }
}
