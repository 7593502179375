import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import styled from "styled-components";
import { useAppContext } from "../AppContext";

const ConsoleControls = styled.div`
  border-bottom: solid 1px grey;
  padding: 1rem;
  background-color: #333;
`;

const ConsoleErrors = styled.div`
  background-color: #ff7373;
  border-bottom: solid 1px grey;
  padding: 1rem;
  white-space: pre;
`;

export default function Console() {
  const { state, dispatch } = useAppContext();
  const { all_errors, apis } = state;

  return (
    <div className="mainrouter__console__container">
      {state.console?.display && (
        <ConsoleControls>
          {apis?.map((api, i) => (
            <div key={i}>
              {api.name} <button onClick={() => api.refresh()}>Refresh</button>
              <button onClick={() => api.logState()}>LogState</button>
            </div>
          ))}
        </ConsoleControls>
      )}

      {all_errors && all_errors.allIDs.length > 0 && (
        <ConsoleErrors>
          {all_errors.allIDs
            .map((id) => all_errors.byID[id])
            .map((error) => (
              <div key={error.id}>
                {error.id} {error.error}
              </div>
            ))}{" "}
          <Button
            icon={IconNames.DELETE}
            onClick={() => {
              apis.forEach((api) => api.clearErrors());
              dispatch({ type: "CLEAR_ERRORS" });
            }}
          >
            Clear Errors
          </Button>
        </ConsoleErrors>
      )}
    </div>
  );
}
