import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToDiaryPost } from "../TorgaRouter";

export function searchDiaryPosts(diaryposts, query) {
  function buildSearchString(e) {
    return ["D" + e.id, e.name, e.content].join("|");
  }
  return searchIn(
    ObjectTypes.DIARYPOSTS,
    diaryposts,
    query,
    buildSearchString,
    (diarypost) => {
      return {
        id: diarypost.id,
        key: "D" + diarypost.id,
        name: diarypost.name,
        details: diarypost.content?.substring(0, 100),
        link: urlToDiaryPost(diarypost.id),
      };
    }
  );
}
