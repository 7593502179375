import React from "react";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToMeetingNoteID } from "../TaskOneRouter";
import MeetingNote from "./MeetingNote";
import { searchMeetingNotes } from "./search";
import { allMeetingNotesListItem } from "./selectors";

export default function Projects() {
  const { state, dispatchAction } = useTaskOneContext();
  const [search, setSearch] = React.useState("");

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={
            search && search != ""
              ? searchMeetingNotes(state, { text: search })
              : allMeetingNotesListItem(state)
          }
          objectType={ObjectTypes.MeetingNotes}
          onChange={setSearch}
          urlToObject={urlToMeetingNoteID}
        />
      </List>

      <Page>
        <MeetingNote />
      </Page>
    </Browser>
  );
}
