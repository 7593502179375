import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchMeetingNotes() {
  return (dispatch) => {
    tget(`/api/v2/meetingnote`).then((result) =>
      dispatch({ type: "MEETINGNOTE_FETCH_COMPLETE", payload: result })
    );
  };
}

export function actionMeetingNoteCreate(meetingID, weekID) {
  return (dispatch) => {
    tpost(`/api/v2/meetingnote`, {
      meeting_id: meetingID,
      week_id: weekID,
    }).then((result) => {
      dispatch({ type: "MEETINGNOTE_CREATE_COMPLETE", payload: [result] });
    });
  };
}

export function actionMeetingnotePersist(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/meetingnote/${id}`, {
      [field]: value,
    }).then((result) =>
      dispatch({ type: "MEETINGNOTE_PERSIST_COMPLETE", payload: [result] })
    );
  };
}
