import { searchAccounts } from "./Accounts/search";
import { searchBankOperations } from "./Operation/search";

export function search(state, query) {
  return {
    operations: searchBankOperations(state.operations, query),
    accounts: searchAccounts(state.accounts, query),
    budgets: [],
  };
}
