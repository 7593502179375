import { searchActionItem } from "./ActionItem/search";
import { searchMeetings } from "./Meeting/search";
import { searchMeetingNotes } from "./MeetingNote/search";
import { searchProjects } from "./Project/search";
import { searchWeeks } from "./Week/search";

export function search(state, query) {
  return {
    weeks: searchWeeks(state.weeks, query),
    projects: searchProjects(state.projects, query),
    meetings: searchMeetings(state.meetings, query),
    actionItems: searchActionItem(state.actionitems, query),
    meetingNotes: searchMeetingNotes(state, query),
    contact: [],
  };
}
