import { Button, HTMLTable } from "@blueprintjs/core";
import React from "react";
import Operation from "../Operation/Operation";

export default function Check({ line, onFixBudget }) {
  const isCredit = line.budgetCredit > 0 || line.operationsCredit > 0;
  const isDebit = line.budgetDebit > 0 || line.operationsDebit > 0;
  const [showOperations, setShowOperations] = React.useState(false);

  let creditBudgetColor = "";
  let debitBudgetColor = "";

  // No operations for a budgeted line
  if (line.budgetCredit > 0 && line.operationIDs == 0) {
    creditBudgetColor = "red";
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <div style={{ color: "grey", fontSize: "80%" }}> </div>
      <HTMLTable className="bp3-html-table" bordered condensed>
        <thead>
          <tr>
            <td>{line.account}</td>
            <td>
              {line.month}/{line.year}
            </td>
            <td>Budget ({line.budgetIDs.length})</td>
            <td onClick={() => setShowOperations(!showOperations)}>
              Operations ({line.operationIDs.length}){" "}
            </td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {isDebit && (
            <tr>
              <td> {line.catego}</td>
              <td>Debit</td>
              <td style={{ color: debitBudgetColor }}>
                {line.budgetDebit.toFixed(2)}
              </td>
              <td>{line.operationsDebit.toFixed(2)}</td>
              <td>
                <Button
                  minimal
                  small
                  onClick={() => {
                    console.log(
                      `change budget debit from ${line.budgetDebit} to ${line.operationsDebit}`
                    );
                    onFixBudget(
                      line.budgetIDs[0] || null,
                      line.account,
                      line.year,
                      line.month,
                      line.catego,
                      line.budgetCredit,
                      line.operationsDebit
                    );
                  }}
                >
                  Fix
                </Button>
              </td>
            </tr>
          )}
          {isCredit && (
            <tr>
              <td> {line.catego}</td>
              <td>Credit</td>
              <td style={{ color: creditBudgetColor }}>
                {line.budgetCredit.toFixed(2)}
              </td>
              <td>{line.operationsCredit.toFixed(2)}</td>
              <td>
                <Button
                  minimal
                  small
                  onClick={() => {
                    console.log(
                      `change budget credit from ${line.budgetCredit} to ${line.operationsCredit}`
                    );
                    onFixBudget(
                      line.budgetIDs[0] || null,
                      line.account,
                      line.year,
                      line.month,
                      line.catego,
                      line.operationsCredit,
                      line.budgetDebit
                    );
                  }}
                >
                  Fix
                </Button>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot></tfoot>
      </HTMLTable>

      {showOperations && (
        <div>
          {line.operationIDs.map((id) => (
            <Operation key={id} id={id} />
          ))}
        </div>
      )}
    </div>
  );
}
