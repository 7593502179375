import { Button, ButtonGroup, Icon, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { capitalize } from "../Utils/Utils";
import "./listcomponent.css";

function ListComponentKey({ objectType, id, style, code, useCode = false }) {
  const key = objectType.key;
  const link = objectType.objectUri(id);

  return (
    <div style={style}>
      <Link to={link}>{useCode ? code : `${key}${id}`}</Link>
    </div>
  );
}

export default function ListComponent({
  objectType,
  values,
  render = (object) => ["Not Implemented"],
  add,
  createEditorComponent = (object, onComplete) => (
    <div>Add component is not implemented</div>
  ),
  remove,
  onCreate,
  quickentry,
  cards,
  table,
  filter,
  onRemove = null,
  onEdit = null,
  header = (title) => <h3>{title}</h3>,
  useCode = false,
  onClick = false,
  inLineEdit,
  editor,
}) {
  const [editMode, setEditMode] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [removeMode, setRemoveMode] = React.useState(false);
  const [quickEntryText, setQuickEntryText] = React.useState("");

  React.useEffect(() => {
    if (editMode) {
      setAddMode(false);
      setRemoveMode(false);
    }
  }, [editMode]);

  React.useEffect(() => {
    if (addMode) {
      setEditMode(false);
      setRemoveMode(false);
    }
  }, [addMode]);

  React.useEffect(() => {
    if (removeMode) {
      setAddMode(false);
      setEditMode(false);
    }
  }, [removeMode]);

  if (!values) {
    return <Suspense />;
  }

  const styles = ["list_component"];
  if (cards) {
    styles.push("list_component_cards");
  }
  if (table) {
    styles.push("list_component__table");
  }

  return (
    <>
      <div className="component__header">
        {header(capitalize(objectType.name))}

        {(add || remove || filter) && (
          <div>
            <ButtonGroup>
              {filter}
              {add && (
                <Button
                  icon={IconNames.ADD}
                  onClick={() => {
                    setAddMode(!addMode);
                  }}
                  active={addMode}
                ></Button>
              )}
              {remove && (
                <Button
                  icon={IconNames.REMOVE}
                  onClick={() => {
                    setRemoveMode(!removeMode);
                  }}
                  active={removeMode}
                ></Button>
              )}
            </ButtonGroup>
          </div>
        )}
      </div>
      {addMode &&
        createEditorComponent(null, () => {
          setAddMode(false);
        })}

      {table && (
        <div className={"list_component__table_row header"}>
          {table({}).map((value, i) => {
            return (
              <div
                key={i}
                style={{
                  width: (value.width || 100) + "px",
                  textAlign: value.align || "left",
                }}
              >
                {value.header}
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.join(" ")}>
        {values.map((object, k) => {
          if (!object) {
            return <Suspense />;
          }

          if (cards) {
            return cards(object, removeMode).map((value, i) => (
              <div key={i}>{value}</div>
            ));
          }

          if (table) {
            return (
              <div className={"list_component__table_row"} key={object.id}>
                {table(object).map((value, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: (value.width || 100) + "px",
                        textAlign: value.align || "left",
                      }}
                    >
                      {value.value}
                    </div>
                  );
                })}
              </div>
            );
          }

          return (
            <div
              className="component__line"
              onClick={() => {
                onClick && onClick(object.id);
              }}
              key={object.id}
            >
              {object.id && (
                <ListComponentKey
                  objectType={objectType}
                  id={object.id}
                  code={object.code}
                  useCode
                />
              )}

              {render(object, removeMode).map((value, i) => (
                <div key={i}>{value}</div>
              ))}
              <div style={{ flexGrow: 1 }}></div>

              {onEdit && (
                <div
                  className="component__remove"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditMode(object);
                  }}
                >
                  <Icon htmlTitle={"Remove"} icon={IconNames.EDIT}></Icon>
                </div>
              )}

              {onRemove && (
                <div
                  className="component__remove"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove(object.id);
                  }}
                >
                  <Icon htmlTitle={"Remove"} icon={IconNames.CROSS}></Icon>
                </div>
              )}
            </div>
          );
        })}

        {values?.length === 0 && !addMode && add && (
          <div
            style={{
              borderRadius: "5px",
              padding: "0px",
            }}
          >
            <Button
              icon={IconNames.PLUS}
              style={{ width: "100%" }}
              onClick={() => {
                setAddMode(true);
              }}
            />
          </div>
        )}
      </div>
      {editMode &&
        createEditorComponent(editMode, () => {
          setEditMode(false);
        })}
      {editMode && editor &&
        React.createElement(editor, {
          object: editMode,
          onComplete: () => {
            setEditMode(false);
          },
        })}
      {quickentry && (
        <InputGroup
          leftIcon={IconNames.PLUS}
          value={quickEntryText}
          onChange={(e) => setQuickEntryText(e.target.value)}
          placeholder={quickentry.placeholder}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();

              if (quickEntryText.trim() === "") {
                return;
              }

              onCreate(quickEntryText);
              setQuickEntryText("");
            }
          }}
        />
      )}
    </>
  );
}
