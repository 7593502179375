import { NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { dateTimeFormatter } from "../../utils/Utils/Utils";
import { useTorgaContext } from "../TorgaContext";
import { actionPersistDiaryPost } from "./actions";

export default function DiaryPost() {
  let { id } = useParams();
  const { state, dispatch, dispatchAction } = useTorgaContext();
  const [currentPost, setCurrentPost] = React.useState();
  const timers = React.useRef([]);

  const { [id]: post } = state.diaryposts.byID;

  useEffect(() => {
    if (timers.current[id]) {
      clearTimeout(timers.current[id]);
    }

    timers.current[id] = setTimeout(() => {
      if (
        currentPost?.content &&
        currentPost?.initialContent != currentPost.content
      ) {
        dispatchAction(
          actionPersistDiaryPost(id, "content", currentPost.content)
        );
      }
    }, 1000);
    return () => {
      //clearTimeout(timer1);
    };
  }, [currentPost]);

  // Clone post on edit start
  useEffect(() => {
    // Update currentPost only on ID change, not on content change
    if (post?.id != currentPost?.id) {
      setCurrentPost({
        ...post,
        content: post?.content || "",
        initialContent: post?.content || "",
      });
    }
  }, [post]);

  if (!id) {
    return <NonIdealState icon={IconNames.WRENCH} />;
  }

  if (!currentPost) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <h1>
        {currentPost?.createdAt &&
          dateTimeFormatter.format(new Date(currentPost.createdAt))}
      </h1>
      <textarea
        className="journal_textarea"
        value={currentPost.content}
        onChange={(e) => {
          setCurrentPost({ ...currentPost, content: e.target.value });
        }}
        autoFocus
      />

      <div>{currentPost.content.trim().split(" ").length} words</div>
    </>
  );
}
