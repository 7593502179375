export function slugify(str) {
  var map = {
    "-": " |_",
    a: "á|à|ã|â|À|Á|Ã|Â",
    e: "é|è|ê|É|È|Ê",
    i: "í|ì|î|Í|Ì|Î",
    o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
    u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
    c: "ç|Ç",
    n: "ñ|Ñ",
  };

  for (var pattern in map) {
    str = str.replace(new RegExp(map[pattern], "g"), pattern);
  }

  return str;
}

export function searchIn(
  objectType,
  collection,
  query,
  buildSearchString,
  buildResult
) {
  const queryText = slugify(query.text.toLowerCase());

  if (query.objectTypes?.length > 0) {
    if (!query.objectTypes.includes(objectType.name)) {
      return [];
    }
  }

  const allObjects = collection.allIDs
    .map((id) => collection.byID[id])
    .map((item) => {
      return {
        id: item.id,
        searchString: buildSearchString(item),
      };
    })
    .filter((searchObject) => {
      //console.log(searchObject);
      if (!searchObject) {
        return;
      }
      if (!searchObject.searchString) {
        return;
      }
      return slugify(searchObject.searchString.toLowerCase()).includes(
        queryText
      );
    })
    .map((searchObject) => {
      const item = collection.byID[searchObject.id];
      return buildResult(item);
    });

  return allObjects;
}

/*

function highlightText(text, query) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text) {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

*/
