import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import ListComponent from "../../utils/ListComponent/ListComponent";
import { actionCreateProject } from "../Project/actions";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes } from "../TaskOneRouter";
import { selectCurrentWeekID } from "../Week/selectors";
import { actionRemoveProjectFromWeek } from "./actions";

export default function WeekListComponent({ ids, context }) {
  const { state, dispatchAction } = useTaskOneContext();
  const [showArchived, setShowArchived] = React.useState(false);

  if (!ids) {
    ids = [];
  }

  const weeks = ids
    .map((id) => state.weeks.byID[id])
    .filter((week) => week.archived != true || showArchived);

  return (
    <div>
      <ListComponent
        add
        objectType={ObjectTypes.WEEKS}
        values={weeks}
        render={(week) => [`${week?.year}_W${week?.week}`]}
        onRemove={(weekID) =>
          dispatchAction(
            actionRemoveProjectFromWeek(context.project_id, weekID)
          )
        }
        onCreate={(object) => {
          dispatchAction(
            actionCreateProject(object.name, object.id, {
              week_id: selectCurrentWeekID(state),
            })
          );
        }}
        filter={
          <Button
            icon={IconNames.COMPRESSED}
            active={showArchived}
            onClick={() => setShowArchived(!showArchived)}
          />
        }
      />
    </div>
  );
}
