import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToMeetingID } from "../TaskOneRouter";

export function searchMeetings(meetings, query) {
  function buildSearchString(e) {
    return ["M" + e.id, e.name, e.content].join("|");
  }
  return searchIn(
    ObjectTypes.MEETINGS,
    meetings,
    query,
    buildSearchString,
    (meeting) => {
      return {
        id: meeting.id,
        key: "M" + meeting.id,
        name: meeting.name,
        details: meeting.content?.substring(0, 100),
        link: urlToMeetingID(meeting.id),
      };
    }
  );
}
