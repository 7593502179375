import React from "react";
import { useNavigate } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTorgaContext } from "../TorgaContext";
import { ObjectTypes, urlToTripID } from "../TorgaRouter";
import { actionCreateTrip } from "./action";
import { searchTrip } from "./search";
import Trip from "./Trip";

export default function Trips() {
  const { state, dispatchAction } = useTorgaContext();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();

  const trips =
    search && search == ""
      ? state.trips.allIDs.map((id) => state.trips.byID[id])
      : searchTrip(state.trips, { text: search });

  return (
    <>
      <Browser>
        <List>
          <ObjectSelector
            objects={trips}
            objectType={ObjectTypes.TRIPS}
            onChange={setSearch}
            onCreate={() =>
              dispatchAction(
                actionCreateTrip((createdTripID) => {
                  navigate(urlToTripID(createdTripID));
                })
              )
            }
            urlToObject={urlToTripID}
          />
        </List>
        <Page>
          <Trip />
        </Page>
      </Browser>
    </>
  );
}
