import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import ListComponent from "../../utils/ListComponent/ListComponent";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes } from "../TaskOneRouter";
import {
  actionCreateLink,
  actionRemoveHyperlinkFromProject,
  actionUpdateLink,
} from "./actions";
import "./hyperlink.css";
import HyperlinkEdit from "./HyperlinkEdit";

export default function HyperlinksComponent({ context, type, input }) {
  const { state, dispatchAction } = useTaskOneContext();

  let linkIDs = [];

  if (context.project_id) {
    linkIDs = state.hyperlinks.idsBy.projectID[context.project_id] || [];
  }

  if (context.meeting_id) {
    linkIDs = state.hyperlinks.idsBy.meetingID[context.meeting_id] || [];
  }

  const links = linkIDs
    .map((id) => state.hyperlinks.byID[id])
    .filter((hyperlink) => hyperlink?.deleted == 0);

  const editor = (object, onComplete) => (
    <HyperlinkEdit
      object={object}
      onComplete={onComplete}
      onSave={(hyperlink) => {
        if (object === null) {
          dispatchAction(actionCreateLink(hyperlink, context));
        } else {
          dispatchAction(
            actionUpdateLink(
              hyperlink.id,
              hyperlink.name,
              hyperlink.link,
              hyperlink.description
            )
          );
        }
      }}
    />
  );

  return (
    <ListComponent
      add
      createEditorComponent={editor}
      objectType={ObjectTypes.HYPERLINKS}
      values={links}
      onCreate={(link) => {
        dispatchAction(actionCreateLink(context, link));
      }}
      render={(hyperlink) => {
        const { id, name, link, description } = hyperlink;
        return [
          <Icon icon={IconNames.GLOBE}></Icon>,
          <a href={link} target="_blank">
            {name || link}
          </a>,
          description,
        ];
      }}
      onRemove={(id) => {
        dispatchAction(
          actionRemoveHyperlinkFromProject(id, context.project_id)
        );
      }}
      onEdit={(id) => {
        dispatchAction();
        //
      }}
    />
  );
}
