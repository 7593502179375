import React, { useEffect, useRef } from "react";
import { createReactEditorJS } from 'react-editor-js';
import isEqual from "react-fast-compare";
import { EDITOR_JS_TOOLS } from "./tools";

export default function RichTextEditor({
  onConfirm,
  property,
  placeholder,
  value,
  objectID,
}) {
  const ReactEditorJS = createReactEditorJS()

  let data = {};
  try {
    data = JSON.parse(value);
  } catch (e) {
    data = {
      time: 0,
      blocks: [
        ...value.split(/\n\n/).map((el) => ({
          type: "paragraph",
          data: { text: el.replaceAll("\n", "<br/>") },
        })),
      ],
      version: "2.19.1",
    };
  }

  return (
    <>
      <div>{JSON.stringify(data)}</div>
      <ReactEditorJS
        
        placeholder={"avion"}
        onInitialize={(editorCore) => {
          console.log(editorCore)

        }}
      />
    </>
  );
}
