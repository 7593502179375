export function actionItemForWeekID(state, weekID) {
  const { [weekID]: actionitemIDs } = state.actionitems?.idsBy?.weekID;
  return actionitemIDs?.map((id) => state.actionitems.byID[id]) || [];
}

export function getAllActiveWeeksNames(state) {
  return state.weeks.allIDs
    .map((id) => state.weeks.byID[id])
    .filter((week) => !week.archived)
    .sort((a, b) => b.year * 52 - a.year * 52 + b.week - a.week)
    .map((week) => ({ key: `${week.year}_W${week.week}`, id: week.id }));
}

export function selectCurrentWeekID(state) {
  const code = state.weeks.currentWeekCode;
  return state.weeks.idBy.code[code];
}

export function getActionItemsForProject(state, project_id) {
  const projectActionItemIDs = state.actionitems.idsBy.projectID[project_id];
  const openProjectActionItems = projectActionItemIDs
    ?.map((actionItemID) => state.actionitems.byID[actionItemID])
    .filter((a) => a.status !== "Done");

  const meetingIDs = state.meetings.idsBy.projectID[project_id];
  const meetingNoteIDs =
    meetingIDs?.flatMap(
      (meetingID) => state.meetingnotes.idsBy.meetingID[meetingID]
    ) || [];

  const meetingActionItemIDs = meetingNoteIDs
    ?.flatMap(
      (meetingNoteID) => state.actionitems.idsBy.meetingnoteID[meetingNoteID]
    )
    ?.filter((actionItemID) => actionItemID !== undefined);

  const openMeetingActionItems = meetingActionItemIDs
    .map((meetingActionItemID) => state.actionitems.byID[meetingActionItemID])
    ?.filter((meetingActionItem) => meetingActionItem.status !== "Done");

  return {
    project_id,
    projectActionItemIDs,
    openProjectActionItems,
    meetingActionItemIDs,
    openMeetingActionItems,
    totalActionItems:
      (projectActionItemIDs?.length || 0) + (meetingActionItemIDs?.length || 0),
    totalOpenActionItems:
      (openProjectActionItems?.length || 0) +
      (openMeetingActionItems?.length || 0),
  };
}

export function selectOpenActionItemsForMeeting(state, meeting_id) {
  const meetingNoteIDs = state.meetingnotes.idsBy.meetingID[meeting_id];
  const actionItemIDs = meetingNoteIDs
    ?.flatMap(
      (meetingNoteID) => state.actionitems.idsBy.meetingnoteID[meetingNoteID]
    )
    .filter((v) => v !== undefined);

  const openActionItem = actionItemIDs
    ?.map((actionItemID) => state.actionitems.byID[actionItemID])
    .filter((actionItem) => actionItem.status !== "Done");

  return openActionItem?.length;
}
