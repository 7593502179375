export function allProjectsListEntries(state) {
  return state.projects.allIDs
    .map((id) => state.projects.byID[id])
    .map((object) => ({
      id: object.id,
      name: object.name,
    }));
}

export function orphanProjectListEntries(state) {
  return state.projects.allIDs
    .map((id) => state.projects.byID[id])
    .filter((o) => o.parent_id == 0 && o.id != state.projects.rootID)
    .map((o) => ({ id: o.id, name: o.name }));
}
