import { Button, InputGroup } from "@blueprintjs/core";
import React from "react";
import "./hyperlink.css";

export default function HyperlinkEdit({
  object: link,
  onSave = () => {},
  onComplete = () => {},
}) {
  const create = link === null;

  const newLinkInitialValue = {
    id: link?.id || null,
    name: link?.name || "",
    link: link?.link || "",
    description: link?.description || "",
  };

  const [newLink, setNewLink] = React.useState(newLinkInitialValue);

  React.useEffect(() => {
    setNewLink(newLinkInitialValue);
  }, [link]);

  return (
    <div className="hyperlink__edit">
      <h4>{create ? "Add a new link" : `Edit link #${link?.id}`}</h4>
      <table className="hyperlink__table">
        <tbody>
          <tr>
            <td style={{ width: "180px" }}>Name</td>
            <td>
              <InputGroup
                autoFocus
                value={newLink.name}
                placeholder="Link name"
                onChange={(v) =>
                  setNewLink({ ...newLink, name: v.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Link</td>
            <td>
              <InputGroup
                value={newLink.link}
                placeholder="Link"
                onChange={(v) =>
                  setNewLink({ ...newLink, link: v.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              <InputGroup
                value={newLink.description}
                placeholder="Link Description"
                onChange={(v) =>
                  setNewLink({ ...newLink, description: v.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <Button
                onClick={(e) => {
                  onSave(newLink);
                  setNewLink(newLinkInitialValue);
                  onComplete();
                }}
              >
                {create ? "Create" : "Save"}
              </Button>
              <Button onClick={onComplete}> Cancel</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
