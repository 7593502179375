import { tget, tpost } from "../../utils/Utils/Utils";
import { actionAddProjectToWeek } from "../Week/actions";

export function actionFetchProjects() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/project").then((result) => {
      dispatch({ type: "PROJECT_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionCreateProject(
  name,
  parent_id,
  links,
  onProjectCreated = null
) {
  return (dispatch) => {
    tpost(`/api/v2/project`, {
      parent_id,
      name,
      delete: false,
    }).then((result) => {
      dispatch({ type: "PROJECT_CREATE_COMPLETE", payload: [result] });
      const { week_id } = links;
      if (week_id) {
        actionAddProjectToWeek(result.id, week_id)(dispatch);
      }

      onProjectCreated && onProjectCreated(result.id);
    });
  };
}

export function actionPersistProject(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/project/${id}`, { [field]: value }).then((result) =>
      dispatch({ type: "PROJECT_PERSIST_COMPLETE", payload: [result] })
    );
  };
}

export function actionFetchProjectsMeetings() {
  return (dispatch) => {
    tget("/api/v2/projectsmeetings").then((result) => {
      dispatch({ type: "PROJECSMEETINGS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionFetchProjectsLinks() {
  return (dispatch) => {
    tget(`/api/v2/projectslinks`).then((result) =>
      dispatch({ type: "PROJECTSLINKS_FETCH_COMPLETE", payload: result })
    );
  };
}

export function actionAddMeetingToProject(meeting_id, project_id) {
  return (dispatch) => {
    tpost(`/api/v2/projectsmeetings`, {
      meeting_id: meeting_id,
      project_id: project_id,
      deleted: false,
    }).then((result) =>
      dispatch({ type: "PROJECTSMEETINGS_ADD_COMPLETE", payload: [result] })
    );
  };
}

export function actionRemoveMeetingToProject(meeting_id, project_id) {
  return (dispatch) => {
    tpost(`/api/v2/projectsmeetings`, {
      meeting_id: meeting_id,
      project_id: project_id,
      deleted: true,
    }).then((result) =>
      dispatch({ type: "PROJECTSMEETINGS_REMOVE_COMPLETE", payload: [result] })
    );
  };
}

export function actionProjectDelete() {}
