import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { TaskOneContext } from "../TaskOneContext";
import TriggerSelector from "./TriggerSelector";

export default function TriggersSelectors({
  value,
  addTrigger,
  removeTrigger,
}) {
  const { state } = useContext(TaskOneContext);

  const [edit, setEdit] = useState(false);

  return (
    <div>
      {edit && (
        <TriggerSelector
          onSelect={(id) => {
            addTrigger(id);
            setEdit(false);
          }}
        />
      )}

      {!edit &&
        value !== undefined &&
        value.map((id) => {
          const trigger = state.triggers.byID[id];
          if (!trigger) {
            return <div key={id}>Loading...</div>;
          }

          return (
            <div key={id}>
              {id}. <Link to={`/t/trigger/T${id}`}> {trigger.name}</Link>{" "}
              <Button
                small
                minimal
                icon={IconNames.SMALL_CROSS}
                onClick={() => removeTrigger(id)}
              ></Button>
            </div>
          );
        })}
      {!edit && (
        <Button
          icon={IconNames.PLUS}
          minimal
          onClick={() => {
            setEdit(true);
          }}
        ></Button>
      )}
    </div>
  );
}
