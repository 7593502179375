import React from "react";
import { Route, Routes } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import Line from "../../utils/Line/Line";
import { TorgaContext, useTorgaContext } from "../TorgaContext";
import { ObjectTypes, urlToActivityID } from "../TorgaRouter";
import { actionActivityPersist } from "./actions";
import Activity from "./Activity";

export default function Activities() {
  const { state, dispatchAction } = useTorgaContext();
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Browser>
      <List>
        <Line
          id={state.activities.rootID}
          selectedID={params.id?.slice(1)}
          context={TorgaContext}
          type={ObjectTypes.ACTIVITIES}
          prefix={"A"}
          link={(id) => urlToActivityID(id)}
          onSelect={(id) => {
            navigate(urlToActivityID(id));
          }}
          moveObject={(id, newParent) => {
            dispatchAction(
              actionActivityPersist(id, "parent_id", newParent)
            );
          }}
        />
      </List>
      <Page>
        <Activity />
      </Page>
    </Browser>
  );
}
