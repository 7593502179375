import { searchActivities } from "./Activity/search";
import { searchEquipments } from "./Equipment/search";
import { searchTrip } from "./Trip/search";

export function search(state, query) {
  return {
    activities: searchActivities(state.activities, query),
    equipments: searchEquipments(state.equipments, query),
    trips: searchTrip(state.trips, query),
  };
}
