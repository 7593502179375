import { Button, ButtonGroup, Checkbox, Intent, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Browser, Line, List, Page as Page2 } from "../../utils/Layout/Layout";
import { decimalFormatter } from "../../utils/Utils/Utils";
import { actionBankCategoSet } from "../actions";
import { useBankContext } from "../BankContext";
import { urlToCategoID } from "../BankRouter";
import Operation from "../Operation/Operation";
import "./catego.css";
import { Prediction } from "./PredictionModel";

export const selectorBankOperationToCategorize = (state) =>
  state.operations.idsBy.year[null];

export default function Catego() {
  const { state, dispatchAction } = useBankContext();
  const [prediction, setPrediction] = useState([]);
  const [catego, setCatego] = useState(null);
  const [sortAZ, setSortAZ] = useState(false);
  const [moveNextMonth, setMoveNextMonth] = useState(false);
  const navigate = useNavigate();
  const { id: paramID } = useParams();

  const toCategorize = selectorBankOperationToCategorize(state);

  const operations = toCategorize?.map(
    (toCategorizeID) => state.operations.byID[toCategorizeID]
  );

  const id = paramID ? paramID : toCategorize && toCategorize[0];

  useEffect(() => {
    const operation = state.operations.byID[id];
    if (!operation) {
      return;
    }
    const predictionResult = Prediction(operation, state.operations);
    setPrediction(predictionResult);
    setCatego(predictionResult[0].catego);
    setMoveNextMonth(predictionResult[0].catego == "REVENUS.SALAIRE");
  }, [id, state.operations]);

  function shiftDate(shift, date_text) {
    let date = new Date(date_text);
    date.setDate(1);
    if (shift) {
      date.setMonth(date.getMonth() + 1);
    }
    return date;
  }

  if (!operations) {
    return <div>Loading ...</div>;
  }

  return (
    <Browser>
      <List>
        <h1>Catego</h1>
        <p>
          {toCategorize?.length} operation{toCategorize?.length > 1 ? "s" : ""}{" "}
          to categorize
        </p>

        {operations.map((operation) => (
          <Line
            key={operation.id}
            handleClick={() => {
              navigate(urlToCategoID(operation.id));
            }}
            selected={operation.id == id}
          >
            {operation.libelle}{" "}
            {decimalFormatter.format(operation.credit - operation.debit)}
          </Line>
        ))}
      </List>
      <Page2>
        {(() => {
          if (!id) {
            return (
              <div>
                Loading... ID
                {toCategorize && toCategorize[0]}
              </div>
            );
          }

          const operation = state.operations.byID[id];
          if (!operation) {
            return <div>Loading...</div>;
          }

          const dateOperation = shiftDate(
            moveNextMonth,
            operation.date_operation
          );

          const year = dateOperation.getFullYear();
          const month = dateOperation.getMonth() + 1;

          return (
            <div>
              <h2>Operation {operation.id}</h2>
              <Operation id={operation.id} />
              <div className="catego__controls">
                <div>
                  <Tag large>{catego}</Tag>
                </div>

                <div>
                  {month} / {year}
                </div>
                <div>
                  <Checkbox
                    checked={moveNextMonth}
                    onChange={(value) => {
                      setMoveNextMonth(value.target.checked);
                    }}
                  >
                    Move to next month
                  </Checkbox>
                </div>
                <div>
                  <ButtonGroup>
                    <Button
                      intent={Intent.SUCCESS}
                      onClick={() => {
                        dispatchAction(
                          actionBankCategoSet(operation.id, catego, year, month)
                        );
                      }}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
              <hr />
              <div>
                <ButtonGroup>
                  <Button
                    icon={IconNames.SORT_ALPHABETICAL}
                    onClick={() => setSortAZ(true)}
                    active={sortAZ}
                  />
                  <Button
                    icon={IconNames.SORT_DESC}
                    onClick={() => setSortAZ(false)}
                    active={!sortAZ}
                  />
                </ButtonGroup>
                <ul>
                  {prediction
                    .sort((a, b) => {
                      if (sortAZ) {
                        return ("" + a.catego).localeCompare(b.catego);
                      }
                      return b.score - a.score;
                    })
                    .map((r) => (
                      <li
                        key={r.catego}
                        onClick={() => {
                          setCatego(r.catego);
                        }}
                      >
                        {r.catego}{" "}
                        <Tag small={1} minimal>
                          {parseFloat(r.score.toFixed(1))}%
                        </Tag>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          );
        })()}
      </Page2>
    </Browser>
  );
}
