import { NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useParams } from "react-router";
import { useTaskOneContext } from "../TaskOneContext";
import MeetingNoteComponent from "./MeetingNoteComponent";

export default function MeetingNote() {
  const { state } = useTaskOneContext();
  const { id } = useParams();

  if (!id) {
    return <NonIdealState icon={IconNames.ANNOTATION}></NonIdealState>;
  }

  return <MeetingNoteComponent id={id} />;
}
