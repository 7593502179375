import {
  Button,
  Checkbox,
  Icon,
  InputGroup,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { capitalize } from "../Utils/Utils";

const StyledMoreOption = styled.div`
  border-top: solid 1px grey;
  padding: 1em 0 0 1em;
`;

const StyledDetails = styled.span`
  color: grey;
`;

export default function ObjectSelector({
  objects,
  objectType,
  onCreate,
  urlToObject,
  onChange,
  children,
  sliceAt = 100,
  onSelect = null,
}) {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  let { id: selected } = useParams();
  const objectName = capitalize(objectType?.name || "");
  const [showMore, setShowMore] = React.useState(false);
  const [showDeleted, setShowDeleted] = React.useState(false);
  const [slice, setSlice] = React.useState(sliceAt);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const filteredObjects = objects.filter((object) => {
    return showDeleted ? true : !object.deleted;
  });

  React.useEffect(() => {
    onChange(search);
  }, [search]);

  if (!objects) {
    return <div>Loading ...</div>;
  }

  function onKeyDown(e) {
    if (e.key == "ArrowDown") {
      e.preventDefault();
      setSelectedIndex(
        Math.min(selectedIndex + 1, Math.min(filteredObjects.length - 1, slice))
      );
    }
    if (e.key == "ArrowUp") {
      e.preventDefault();
      setSelectedIndex(Math.max(0, selectedIndex - 1));
    }
    if (e.key == "Escape") {
      setSearch("");
    }
    if (e.key == "Enter") {
      onSelect ? onSelect(highlightID) : navigate(urlToObject(highlightID));
    }
  }

  const highlightID = filteredObjects?.[selectedIndex]?.id;

  return (
    <div>
      <div className="equipmentselect__search">
        {onCreate && (
          <Button
            icon={IconNames.ADD}
            onClick={() => {
              onCreate();
            }}
          />
        )}
        <InputGroup
          autoFocus
          value={search}
          placeholder={`Search ${objectName}...`}
          leftElement={<Icon icon="search" />}
          rightElement={
            <Button
              icon="cross"
              onClick={() => {
                setSearch("");
              }}
            />
          }
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyDown={onKeyDown}
        />
        <Button
          icon={IconNames.MORE}
          minimal
          onClick={(e) => {
            setShowMore(!showMore);
          }}
        />
      </div>

      {showMore && (
        <StyledMoreOption>
          <Checkbox
            checked={showDeleted}
            onChange={(e) => {
              setShowDeleted(e.target.checked);
            }}
          >
            Show deleted
          </Checkbox>
        </StyledMoreOption>
      )}
      {children}
      <div className="equipmentselect__list">
        {filteredObjects.slice(0, slice).map((object) => (
          <div
            key={object.id}
            className={[
              selected == object.id ? "equipmentselect__item_selected" : "",
              highlightID == object.id ? "equipmentselect__item_hilight" : "",
            ].join(" ")}
            onClick={() => {
              onSelect
                ? onSelect(object.id)
                : navigate(urlToObject(object.id));
            }}
          >
            <Link to={urlToObject(object.id)}>{object.id}</Link> - {object.name}{" "}
            <StyledDetails>{object.details}</StyledDetails>
          </div>
        ))}
        {search && search != "" && filteredObjects.length == 0 && (
          <NonIdealState
            icon={IconNames.SEARCH}
            title={"No search results"}
            description={
              <>
                Your search didn't match any files.
                <br />
                Try searching for something else.
              </>
            }
          />
        )}

        {slice < filteredObjects.length && (
          <div>
            {slice} of {filteredObjects.length} displayed{" "}
            <Button onClick={(e) => setSlice(filteredObjects.length)}>
              Show All
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
