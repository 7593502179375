import { dateTimeFormatter } from "../../utils/Utils/Utils";
export function allDiaryPosts(state) {
  return state.diaryposts.allIDs
    .map((id) => state.diaryposts.byID[id])
    .map((object) => ({
      id: object.id,
      name: dateTimeFormatter.format(new Date(object.createdAt)),
      deleted: object.deleted,
    }));
}
