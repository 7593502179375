import React from "react";

export const currencyFormatter = new Intl.NumberFormat("fr", {
  style: "currency",
  currency: "EUR",
});

export const decimalFormatter = new Intl.NumberFormat("fr", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const dateTimeFormatter = new Intl.DateTimeFormat("fr", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export function dateShort(date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function dateLong(date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function currency(value, opt) {
  return new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
    ...opt,
  }).format(parseFloat(value));
}

export function tget(url) {
  return new Promise((resolve, reject) => {
    fetch(url, { credentials: "include" })
      .then((res) => {
        if (!res.ok) {
          console.error(res);
          reject({
            name: res.status,
            message: res.url + " " + res.status + " " + res.statusText,
          });
        } else {
          resolve(res.json());
        }
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export function tpost(url, object) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "post",
      body: JSON.stringify(object),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          //console.log("reject");
          //console.log(res.statusText);
          reject(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export function tdelete(url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "delete",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          console.log("reject");
          reject(new Error(res.statusText));
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export class BoxShadow extends React.Component {
  render() {
    return (
      <div style={{ boxShadow: "#8A9BA8 0px 0px 5px", overflow: "hidden" }}>
        {this.props.children}
      </div>
    );
  }
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
