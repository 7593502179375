import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchHyperlinks() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/hyperlinks").then((result) => {
      dispatch({ type: "HYPERLINKS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionCreateLink(linkObject, context) {
  return (dispatch) => {
    tpost(`/api/v2/hyperlinks`, {
      name: linkObject.name,
      link: linkObject.link,
      description: linkObject.description,
      deleted: false,
    }).then((result) => {
      dispatch({ type: "HYPERLINK_CREATE_COMPLETE", payload: [result] });
      const { project_id, meeting_id } = context;

      if (project_id) {
        actionAddHyperlinkToProject(result.id, project_id)(dispatch);
      }

      if (meeting_id) {
        actionAddHyperlinkToMeeting(result.id, meeting_id)(dispatch);
      }
    });
  };
}

export function actionUpdateLink(hyperlink_id, name, link, description) {
  return (dispatch) => {
    tpost(`/api/v2/hyperlinks/${hyperlink_id}`, {
      name,
      link,
      description,
      deleted: false,
    }).then((result) => {
      dispatch({ type: "HYPERLINK_UPDATE_COMPLETE", payload: [result] });
    });
  };
}

export function actionRemoveHyperlinkFromProject(hyperlink_id, project_id) {
  return (dispatch) => {
    tpost(`/api/v2/projectslinks`, {
      hyperlink_id,
      project_id,
      deleted: true,
    }).then((result) => {
      dispatch({ type: "PROJECT_LINKS_DELETE_COMPLETE", payload: [result] });
    });
  };
}

export function actionAddHyperlinkToProject(hyperlink_id, project_id) {
  return (dispatch) => {
    tpost(`/api/v2/projectslinks`, {
      project_id,
      hyperlink_id,
      deleted: false,
    }).then((result) =>
      dispatch({ type: "PROJECTS_LINKS_ADD_COMPLETE", payload: [result] })
    );
  };
}

export function actionAddHyperlinkToMeeting(hyperlink_id, meeting_id) {
  return (dispatch) => {
    tpost(`/api/v2/meetinglinks`, {
      meeting_id,
      hyperlink_id,
      deleted: false,
    }).then((result) =>
      dispatch({ type: "MEETING_LINKS_ADD_COMPLETE", payload: [result] })
    );
  };
}
