import React, { useEffect, useReducer } from "react";
import { useAppContext } from "../app/AppContext";
import {
  actionFetchActivities,
  actionFetchActivityEquipments,
} from "../torga/Activity/actions";
import { actionFetchEquipments } from "../torga/Equipment/actions";
import { actionFetchDiaryPosts } from "./Diary/actions";
import initialState from "./initialState";
import reducer from "./reducer";
import { actionFetchTrips } from "./Trip/action";

export const TorgaContext = React.createContext();

export const useTorgaContext = () => React.useContext(TorgaContext);

export const TorgaContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dispatch: appDispatch } = useAppContext();
  const { refreshKey, errors } = state;

  useEffect(() => {
    if (errors.length)
      appDispatch({
        type: "ERROR_UP",
        payload: errors,
      });
  }, [errors]);

  useEffect(() => {
    dispatchAction(actionFetchActivities());
    dispatchAction(actionFetchEquipments());
    dispatchAction(actionFetchActivityEquipments());
    dispatchAction(actionFetchTrips());
    dispatchAction(actionFetchDiaryPosts());
  }, [refreshKey]);

  async function dispatchAction(action, completeCallback) {
    try {
      await action(dispatch, completeCallback);
    } catch (err) {
      dispatch({ type: "ERROR", payload: err });
    }
  }

  return (
    <TorgaContext.Provider value={{ state, dispatch, dispatchAction }}>
      {props.children}
    </TorgaContext.Provider>
  );
};
