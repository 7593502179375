import React from "react";
import { useNavigate } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToMeetingID } from "../TaskOneRouter";
import { actionCreateMeeting } from "./actions";
import Meeting from "./Meeting";
import { searchMeetings } from "./search";
import { allMeetingsListItem } from "./selectors";

export default function Meetings() {
  const { state, dispatchAction } = useTaskOneContext();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={
            search && search != ""
              ? searchMeetings(state.meetings, { text: search })
              : allMeetingsListItem(state)
          }
          objectType={ObjectTypes.MEETINGS}
          urlToObject={urlToMeetingID}
          onChange={setSearch}
          onCreate={() => {
            dispatchAction(
              actionCreateMeeting((createdMeetingID) =>
                navigate(urlToMeetingID(createdMeetingID))
              )
            );
          }}
        />
      </List>

      <Page>
        <Meeting />
      </Page>
    </Browser>
  );
}
