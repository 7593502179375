import React from "react";
import styled from "styled-components";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { decimalFormatter } from "../../utils/Utils/Utils";
import { useBankContext } from "../BankContext";
import { ObjectTypes, urlToAccountID } from "../BankRouter";
import Account from "./Account";
import {
  getAccountBalances,
  getTotalBalanceFromAccountBalances,
} from "./selectors";

const SelectorDetails = styled.div`
  display: inline-block;
  float: right;
`;

const StyledTotal = styled.div`
  text-align: center;
  border-top: solid 1px grey;
  padding: 20px;
`;

export default function Accounts() {
  const { state } = useBankContext();

  const soldes = getAccountBalances(state);
  const total = getTotalBalanceFromAccountBalances(soldes);

  const soldesObject = soldes.reduce(
    (acc, curr) => ({ ...acc, [curr.account]: curr.solde }),
    {}
  );

  // Map to real name
  const comptes = state.accounts.allIDs
    .map((id) => state.accounts.byID[id])
    .map((account) => {
      return {
        id: account.id,
        name: account.name,
        deleted: account.archived,
        details: (
          <SelectorDetails>
            €{" "}
            {soldesObject[account.nom]
              ? decimalFormatter.format(soldesObject[account.nom])
              : "-"}
          </SelectorDetails>
        ),
      };
    });

  return (
    <Browser>
      <List>
        <List>
          <ObjectSelector
            objects={comptes}
            objectType={ObjectTypes.ACCOUNT}
            urlToObject={urlToAccountID}
            onChange={(value) => console.log(value)}
          />
        </List>
        <StyledTotal>
          <p>Total : € {decimalFormatter.format(total)}</p>
        </StyledTotal>
      </List>
      <Page>
        <Account />
      </Page>
    </Browser>
  );
}
