import React from "react";
import ListComponent from "../../utils/ListComponent/ListComponent";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes } from "../TaskOneRouter";
import { selectCurrentWeekID } from "../Week/selectors";
import { actionCreateProject } from "./actions";

export default function ProjectsComponent({ context, ids }) {
  const { state, dispatchAction } = useTaskOneContext();

  if (!ids) {
    ids = [];
  }

  const children = ids.map((id) => state.projects.byID[id]);

  return (
    <div>
      <ListComponent
        quickentry
        objectType={ObjectTypes.PROJECTS}
        values={children}
        render={(project) => [project.name]}
        onCreate={(name) => {
          dispatchAction(
            actionCreateProject(name, context.project_id, {
              week_id: selectCurrentWeekID(state),
            })
          );
        }}
      />
    </div>
  );
}
