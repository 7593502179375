import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToActionItemID } from "../TaskOneRouter";

export function searchActionItem(actionitems, query) {
  function buildSearchString(e) {
    return ["T" + e.id, e.name, e.content].join("|");
  }
  return searchIn(
    ObjectTypes.ACTION_ITEMS,
    actionitems,
    query,
    buildSearchString,
    (actionitem) => {
      return {
        id: actionitem.id,
        key: "T" + actionitem.id,
        name: actionitem.name,
        details: actionitem.content?.substring(0, 100),
        link: urlToActionItemID(actionitem.id),
      };
    }
  );
}
