export function Prediction(operation, operations) {
  const allOperationCategories = operations.allIDs
    .map((id) => operations.byID[id])
    .filter((operationItem) => operationItem.catego);

  const allCategories = allOperationCategories
    .map((o) => o.catego)
    .reduce((acc, curr) => {
      !acc.includes(curr) && acc.push(curr);
      return acc;
    }, []);

  const model = {};

  allOperationCategories.forEach((op) => {
    if (!model[op.catego]) {
      model[op.catego] = {};
    }
    op.libelle
      .split(" ")
      .map((p) => p.replace(/,/, ""))
      .filter((p) => p.length > 2)
      .filter((p) => p.indexOf("CARTE") != 0)
      .filter((p) => !p.match(/[0-9]+\/[0-9]+/g))
      .filter((p) => !p.match(/[0-9]*h[0-9]*/g))
      .filter((p) => !p.match(/[0-9]*(EUR|USD|GBP)/g))
      .filter((p) => !p.match(/^[0-9]*$/g))
      .forEach((p) => {
        if (!model[op.catego][p]) {
          model[op.catego][p] = 1;
        } else {
          model[op.catego][p] = model[op.catego][p] + 1;
        }
      });
  });

  const value = operation.libelle;
  const vpa = value
    .split(" ")
    .map((p) => p.replace(/,/, ""))
    .filter((p) => p.length > 2)
    .filter((p) => p.indexOf("CARTE") != 0)

    .filter((p) => !p.match(/[0-9]+\/[0-9]+/g))
    .filter((p) => !p.match(/[0-9]*h[0-9]*/g))
    .filter((p) => !p.match(/[0-9]*(EUR|USD|GBP)/g))
    .filter((p) => !p.match(/^[0-9]*$/g))
    /*.map((p) => {
      console.log(p);
      return p;
    })*/
    .map((p) => {
      const res = [];
      Object.keys(model).forEach((k) => {
        const count = model[k][p] + 1 || 1;
        res.push(count);
      });

      const sum = res.reduce((a, c) => a + c);
      return res.map((a) => a / sum);
    });

  const result = {};

  allCategories.map((c, i) => {
    vpa.forEach((ar) => {
      if (!result[c]) {
        result[c] = ar[i];
      } else {
        result[c] = result[c] * ar[i];
      }
    });

    return c;
  });

  const sorted = [...Object.entries(result)]
    .map(([a, b]) => ({ catego: a, score: b }))
    .sort((a, b) => b.score - a.score);

  // Normalize
  const sortedSum = sorted.map((a) => a.score).reduce((a, c) => a + c);
  return sorted.map((a) => ({
    catego: a.catego,
    score: (a.score * 100) / sortedSum,
  }));
}
