import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToTripID } from "../TorgaRouter";

function buildTripSearchString(o) {
  return ["V" + o.id, o.name, o.location].join("|");
}

export function searchTrip(tripCollection, query) {
  return searchIn(
    ObjectTypes.TRIPS,
    tripCollection,
    query,
    buildTripSearchString,
    (trip) => ({
      id: trip.id,
      key: "V" + trip.id,
      name: trip.name,
      details: trip.start_date?.toString(),
      link: urlToTripID(trip.id),
    })
  );
}
