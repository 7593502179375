import {
  Button,
  Checkbox,
  HTMLSelect,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useParams } from "react-router";
import { dateShort, dateTimeFormatter } from "../../utils/Utils/Utils";
import { useBankContext } from "../BankContext";
import "./account.css";
import { actionUpdateBankAccountProperty } from "./actions";

export default function Account() {
  const { state, dispatchAction } = useBankContext();
  const { id } = useParams();
  const [limit, setLimit] = React.useState(100);

  const account = state.accounts.byID[id];
  const lastImport =
    state.crawler.imports.latests?.filter(
      (i) => i.account == account?.nom
    )?.[0] || {};

  if (!id) {
    return <NonIdealState icon={IconNames.BANK_ACCOUNT} />;
  }

  if (!account) {
    return <div>Loading...</div>;
  }

  const operationIDs = state.operations.idsBy.account[account.nom];

  if (!operationIDs) {
    return <div>Loading Operations...</div>;
  }

  const operations = operationIDs
    .map((id) => state.operations.byID[id])
    .sort((a, b) => b.date_operation - a.date_operation);

  if (!account) {
    return <div>Loading ...</div>;
  }

  return (
    <div>
      <h1>{account.name}</h1>
      <p>{account.nom}</p>
      Type{" "}
      <div className="bp3-select .modifier">
        <HTMLSelect
          value={account.type}
          onChange={(e) => {
            dispatchAction(
              actionUpdateBankAccountProperty(account.id, {
                type: e.target.value,
              })
            );
          }}
        >
          {Object.keys(state.accounts.idsBy?.type).map((type) => {
            return (
              <option value={type} key={type}>
                {type}
              </option>
            );
          })}
        </HTMLSelect>
      </div>
      <Checkbox
        checked={account.archived}
        onChange={(e) => {
          dispatchAction(
            actionUpdateBankAccountProperty(account.id, {
              archived: e.target.checked,
            })
          );
        }}
      >
        Archived
      </Checkbox>
      <p>{account.archived}</p>
      <hr />
      Last import:{" "}
      {dateTimeFormatter.format(new Date(lastImport.datetime || 0))} (
      {lastImport.lines} rows / {lastImport.new} new) [{" "}
      {dateShort(new Date(lastImport.date_min || 0))} {" - "}
      {dateShort(new Date(lastImport.date_max || 0))} ]
      <hr />
      <div>
        {operations.slice(0, limit).map((o) => (
          <div key={o.id} className="bank__account_operation">
            <div>{dateShort(o.date_operation)}</div>
            <div>{o.libelle}</div>
            <div>
              <Tag minimal>{o.catego ? o.catego : "?"}</Tag>
            </div>

            <div>{(o.credit - o.debit).toFixed(2)} €</div>
          </div>
        ))}
      </div>
      <div>
        <Button
          onClick={() => {
            setLimit(limit + 100);
          }}
        >
          Show more ...
        </Button>
      </div>
    </div>
  );
}
