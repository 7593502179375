import React from "react";
import {
  ObjectFieldDateTime,
  ObjectFieldDateEditable,
  ObjectTextField,
} from "../../utils/ObjectField/ObjectField";
import {
  ObjectFieldEditableText,
  ObjectFieldBoolean,
  ObjectFieldStatus,
} from "../../utils/ObjectField/ObjectField";

export default function Fields({
  object,
  handleConfirm = (field, value) => {
    console.error("handleConfirm not implemented");
  },
}) {
  return (
    <>
      <ObjectTextField field="id" value={object.id} />

      <ObjectFieldEditableText
        field="type"
        value={object.type}
        handleConfirm={handleConfirm}
      />

      <ObjectFieldDateTime field="createdAt" value={object.createdAt} />
      <ObjectFieldDateTime field="updatedAt" value={object.updatedAt} />
      <ObjectFieldDateEditable
        field="next_review"
        value={object.next_review}
        handleConfirm={handleConfirm}
      />
      <ObjectFieldBoolean field="deleted" value={object.delete} />
      <ObjectFieldStatus field="status" value={object.status} />

      <ObjectFieldEditableText
        field="location"
        value={object.location}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
