export default {
  nav: null,
  errors: [],
  refreshKey: 0,
  activities: {
    rootID: null,
    byID: {},
    allIDs: [],
    idsBy: {
      equipmentID: {},
      parentID: {},
    },
  },
  equipments: {
    byID: {},
    allIDs: [],
    idsBy: {
      activityID: {},
    },
  },
  trips: {
    byID: {},
    allIDs: [],
  },
  diaryposts: {
    byID: {},
    allIDs: [],
  },
};
