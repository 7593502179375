import {
  Alert,
  Button,
  ButtonGroup,
  EditableText,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Page } from "../../utils/Layout/Layout";
import StatusBar from "../../utils/StatusBar/StatusBar";
import Status from "../../utils/StatusLabel/Status";
import { dateTimeFormatter } from "../../utils/Utils/Utils";
import HyperlinksComponent from "../Hyperlink/HyperlinksComponent";
import MeetingNotes from "../MeetingNote/MeetingNotesComponent";
import { useTaskOneContext } from "../TaskOneContext";
import { actionMeetingDelete, actionPersistMeeting } from "./actions";

export default function Meeting() {
  const { state: taskOneState, dispatch, dispatchAction } = useTaskOneContext();
  const [alertOpen, setAlertOpen] = useState(false);
  const [state, setState] = React.useState(null);
  let { id } = useParams();
  const { [id]: meetingFromCache } = taskOneState.meetings.byID;

  React.useEffect(() => {
    if (meetingFromCache) {
      setState(meetingFromCache);
    }
  }, [meetingFromCache]);

  if (!id) {
    return <NonIdealState icon={IconNames.PEOPLE} />;
  }

  if (!state) {
    return <div>Loading...</div>;
  }

  const { [state.parent_id]: parent } = taskOneState.meetings.byID;

  function handleChange(field, value) {
    setState({ ...state, [field]: value });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionPersistMeeting(id, field, value));
  }
  /*
  function selected(key) {
    if (key === state.status) {
      if (key == 'In Progress') return { intent: Intent.WARNING };
      if (key == 'Todo') return { intent: Intent.WARNING };
      if (key == 'Done') return { intent: Intent.SUCCESS };
      return { intent: Intent.PRIMARY };
    }
    return null;
  }
 */
  function drawLine(field, placeholder, multiline) {
    return (
      <tr>
        <td>{field.charAt(0).toUpperCase() + field.slice(1)}</td>
        <td>
          <EditableText
            multiline={multiline}
            placeholder={placeholder || "Add " + field + " ..."}
            value={state[field]}
            onChange={(e) => handleChange(field, e)}
            onConfirm={(e) => handleConfirm(field, e)}
            selectAllOnFocus
          />
        </td>
      </tr>
    );
  }

  return (
    <Page>
      <h1>
        <div style={{ float: "right", textAlign: "right" }}>
          <EditableText
            placeholder="Icon"
            value={state.icon}
            onChange={(e) => handleChange("icon", e)}
            onConfirm={(e) => handleConfirm("icon", e)}
            selectAllOnFocus={true}
          />
        </div>
        <EditableText
          placeholder="Meeting Name"
          value={state.name}
          onChange={(e) => handleChange("name", e)}
          onConfirm={(e) => handleConfirm("name", e)}
          selectAllOnFocus={true}
        />
      </h1>
      <StatusBar
        status={state.status}
        onChange={(status) => handleConfirm("status", status)}
      />{" "}
      <ButtonGroup>
        <Button text="Move" onClick={(e) => this.handleMoveSwitch()} />
      </ButtonGroup>{" "}
      <ButtonGroup>
        <Button onClick={(e) => this.handleReviewed()}> Reviewed</Button>
      </ButtonGroup>
      {/*       {this.taskOneState.moveSwitch && (
            <div style={{ marginBottom: "1.5em", marginTop: "1.5em" }}>
              <h3>Move to </h3>
              <Search onSelected={this.onSelected} />
            </div>
          )} */}
      <hr />
      <table>
        <tbody>
          <tr>
            <td>Id</td>
            <td>{state.id}</td>
          </tr>
          {drawLine("type")}

          {parent !== undefined && (
            <tr>
              <td>Parent</td>
              <td>
                <Link to={"/t/meeting/M" + state.parent_id}>{parent.name}</Link>
              </td>
            </tr>
          )}
          <tr>
            <td style={{ width: "200px" }}>Created on</td>
            <td>{dateTimeFormatter.format(new Date(state.createdAt))}</td>
          </tr>
          <tr>
            <td>Last Modified</td>
            <td>{dateTimeFormatter.format(new Date(state.updatedAt))}</td>
          </tr>
          <tr>
            <td>Deleted</td>
            <td>{state.deleted ? "🚫" : ""}</td>
          </tr>
          {state.status !== null && (
            <tr>
              <td>Status</td>
              <td>
                <Status status={state.status} />
              </td>
            </tr>
          )}

          {state.status == null && (
            <tr>
              <td>Status</td>
              <td> -</td>
            </tr>
          )}
          {drawLine("location")}
        </tbody>
      </table>
      <hr />
      <EditableText
        multiline
        minLines={3}
        value={state.content}
        onChange={(e) => handleChange("content", e)}
        onConfirm={(e) => handleConfirm("content", e)}
      />
      <hr />
      <HyperlinksComponent context={{ meeting_id: id }} type="MEETING" input />
      <MeetingNotes meetingID={state.id} />
      <hr />
      <Button
        icon="delete"
        intent={Intent.DANGER}
        onClick={(e) => setAlertOpen(true)}
      >
        Delete
      </Button>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={alertOpen}
        canEscapeKeyCancel={true}
        onCancel={(e) => setAlertOpen(false)}
        onConfirm={(e) =>
          dispatchAction(actionMeetingDelete(id), () => {
            setAlertOpen(false);
          })
        }
      >
        <p> Delete meeting ? </p>
      </Alert>
    </Page>
  );
}
