import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { Page } from "../../utils/Layout/Layout";
import {
  actionCreateNewBudgetDocument,
  actionLoadBankBudgetDocuments,
} from "../actions";
import { useBankContext } from "../BankContext";
import BudgetDocument from "./BudgetDocument";
import GoogleToken from "./GoogleToken";

export default function BudgetDocuments() {
  const { state, dispatchAction } = useBankContext();

  React.useEffect(() => {
    dispatchAction(actionLoadBankBudgetDocuments());
  }, [state.refreshKey]);

  return (
    <Page>
      <h1>Budget</h1>
      <h2>Google Auth Token</h2>
      <GoogleToken />
      <h2>
        Documents{" "}
        <Button
          icon={IconNames.ADD}
          onClick={() => dispatchAction(actionCreateNewBudgetDocument())}
        ></Button>
      </h2>

      {state.budgetDocuments.allIDs
        .map((id) => state.budgetDocuments.byID[id])
        .filter((o) => !(o.deleted && o.deleted == true))
        .map((o) => o.id)
        .map((id) => (
          <div key={id}>
            <BudgetDocument key={id} id={id} />
          </div>
        ))}
    </Page>
  );
}
