import { Tag } from "@blueprintjs/core";
import React from "react";
import { useNavigate } from "react-router";
import Status from "../../utils/StatusLabel/Status";
import { useTaskOneContext } from "../TaskOneContext";
import { urlToProjectID } from "../TaskOneRouter";
import { getActionItemsForProject } from "../Week/selectors";
import "./project.css";

function buildTree(state, id, selectedIDs, level = 0) {
  const children = state.projects.idsBy.parentID[id];
  const childrenObjects = children?.map((child_id) =>
    buildTree(state, child_id, selectedIDs, level + 1)
  );

  const project = state.projects.byID[id];
  const actionItems = getActionItemsForProject(state, project.id);

  return {
    id,
    name: project.name,
    status: project.status,
    level,
    totalOpenActionItems: actionItems.totalOpenActionItems,
    active: selectedIDs.includes(id),
    children: childrenObjects,
    hasActiveChild:
      childrenObjects
        ?.map((o) => o.active || o.hasActiveChild)
        .reduce((a, c) => a || c, false) || false,
  };
}

export default function ProjectListTree({ context }) {
  const { state, dispatchAction } = useTaskOneContext();

  const { week_id } = context;
  const items = [];

  if (week_id) {
    items.push.apply(items, state.projects.idsBy.weekID[week_id]);
  }

  const tree = buildTree(state, state.projects.rootID, items, -1);

  return (
    <div>
      <h3>Project</h3>
      <div className="projectlisttree_box">
        {tree.children
          ?.filter((o) => o.hasActiveChild || o.active)
          .map((o) => (
            <ProjectListLine key={o.id} item={o} />
          ))}
      </div>
    </div>
  );
}

function ProjectListLine({ item }) {
  const navigate = useNavigate();

  const styles = ["projectlisttree_line"];
  if (!item.active) {
    styles.push("dimm");
  }

  return (
    <div>
      <div
        className={styles.join(" ")}
        style={{ paddingLeft: `${10 + item.level * 25}px` }}
        onClick={() => navigate(urlToProjectID(item.id))}
      >
        <div>P{item.id}</div> <div>{item.name} </div>
        <Status status={item.status} />
        <div className="projectlisttree_spacer"></div>
        <div>
          {item.totalOpenActionItems > 0 && (
            <Tag round>{item.totalOpenActionItems}</Tag>
          )}
        </div>
      </div>
      {item.children
        ?.filter((o) => o.hasActiveChild || o.active)
        .map((o) => (
          <ProjectListLine key={o.id} item={o} />
        ))}
    </div>
  );
}
