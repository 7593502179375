import { Button, Checkbox } from "@blueprintjs/core";
import React, { useState } from "react";
import { Page } from "../../utils/Layout/Layout";
import {
  actionAppSettingsLoadUsers,
  actionAppSettingsUpdateUserPassword,
  actionAppSettingsUpdateUserSettings,
} from "../actions";
import { useAppContext } from "../AppContext";
import modules from "../modules";

function UserSettings({ userID }) {
  const { state, dispatchAction } = useAppContext();
  const [newPassword, setNewPassword] = useState("");

  const user = state.users.byID[userID];

  if (!user) {
    return <div> Loading...</div>;
  }

  // Deserialize user config
  let config = user.config;

  modules.forEach((m) => {
    if (!config[m]) {
      config[m] = false;
    }
  });

  return (
    <div>
      <h3>User</h3>
      ID: {user.id}
      <div>
        Name: {user.name}
        <br />
        Email: {user.email}
        <br />
        Admin: {user.is_admin ? "yes" : "no"}
        <br />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="button"
          value="Update password"
          onClick={(e) =>
            dispatchAction(
              actionAppSettingsUpdateUserPassword(user.id, newPassword)
            )
          }
        />
        <hr />
        <div>
          <h4>Modules</h4>
          {modules.map((m, id) => {
            return (
              <div key={id}>
                <Checkbox
                  checked={config[m]}
                  onChange={(e) =>
                    dispatchAction(
                      actionAppSettingsUpdateUserSettings(userID, {
                        ...config,
                        [m]: e.target.checked,
                      })
                    )
                  }
                >
                  {m}
                </Checkbox>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default function Settings() {
  const { state, dispatch, dispatchAction } = useAppContext();
  const [selectedUserID, setSelectedUserID] = useState(null);

  React.useEffect(() => {
    dispatchAction(actionAppSettingsLoadUsers());
  }, [state.refreshKey]);

  return (
    <Page>
      <h1> Settings</h1>

      <h3>Users</h3>
      <table>
        <tbody>
          <tr>
            <th>id</th>
            <th>email</th>
            <th>name</th>
          </tr>
          {state.users.allIDs.map((userID) => {
            const u = state.users.byID[userID];
            return (
              <tr key={u.id}>
                <td>{u.id}</td>
                <td>{u.name}</td>
                <td
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => {
                    setSelectedUserID(u.id);
                  }}
                >
                  {u.email}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {selectedUserID && <UserSettings userID={selectedUserID} />}

      <Button
        onClick={() => dispatch({ type: "ERROR", payload: Error("Fake") })}
      >
        Test Error
      </Button>
    </Page>
  );
}
