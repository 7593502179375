import { handleError, normalize } from "../utils/normalize";
import initialState from "./initialState";

function reducer(state, action) {
  try {
    const payload = action.payload;
    switch (action.type) {
      case "ERROR": {
        return {
          ...state,
          errors: handleError(state.errors, payload, "app"),
        };
      }
      case "ERROR_UP": {
        return { ...state, all_errors: normalize(state.all_errors, payload) };
      }

      case "CLEAR_ERRORS": {
        return { ...state, all_errors: { byID: {}, allIDs: [] } };
      }

      case "MENU_ADD": {
        return { ...state, menus: [...state.menus, { ...payload }] };
      }
      case "MENU_REMOVE": {
        const newState = {
          ...state,
          menus: state.menus.filter((m) => m.name != payload.name),
        };
        return newState;
      }

      case "SIGN_START":
        return {
          ...state,
          auth: { ...state.auth, loading: true, message: "" },
        };

      case "ADD_API": {
        return { ...state, apis: [...state.apis, payload] };
      }

      case "REMOVE_API": {
        return { ...state, apis: state.apis.filter((api) => api != payload) };
      }

      case "AUTH":
        return {
          ...state,
          auth: {
            ...state.auth,
            auth: payload.auth,
            sessionID: payload.sessionID,
            env: payload.env,
            isLoaded: true,
            loading: false,
            version: payload.version,
            userID: payload.userID,
            config: payload.config,
            apiToken: payload.apiToken,
          },
        };

      case "LOGIN":
        return {
          ...state,
          auth: {
            ...state.auth,
            isLoaded: true,
            sessionID: payload.sessionID,
            auth: payload.auth,
            env: payload.env,
            message: payload.message,
            loading: false,
            userID: payload.userID,
            config: payload.config,
          },
        };

      case "LOGOUT":
        return {
          ...initialState,
          auth: { ...initialState.auth, isLoaded: true },
        };

      case "BADGE": {
        return {
          ...state,
          badges: { ...state.badges, [payload.key]: payload.value },
        };
      }

      case "HIGHLIGHT": {
        return {
          ...state,
          highlights: { ...state.highlights, [payload.key]: payload.value },
        };
      }

      case "CONSOLE": {
        return {
          ...state,
          console: { ...state.console, display: payload.display },
        };
      }

      case "REFRESH_DATA":
        return { ...state, refreshKey: state.refreshKey + 1 };
      case "FETCH_OPERATION_COMPLETE":
        return {
          ...state,
          operations: normalize(state.operations, action.payload, [
            {
              name: "accounts",
              key: ["compte"],
            },
            {
              name: "years",
              key: ["year"],
            },
          ]),
        };

      case "SETTINGS_FETCH_USERS_COMPLETE":
        return { ...state, users: normalize(state.users, action.payload) };

      case "SETTINGS_UPDATE_USER_COMPLETE": {
        const user = payload;
        const newState = { ...state };
        newState.users.byID[user.id] = user;
        if (user.id == state.auth.userID) {
          newState.auth.config = user.config;
        }
        return newState;
      }

      case "LOG_STATE":
        console.log(state);
        return state;

      default:
        throw new Error(
          `[${action.type}] is unknown in App Reducer. Did you returned a new state ?`
        );
    }
  } catch (err) {
    return {
      ...state,
      errors: handleError(state.errors, err, "app"),
    };
  }
}

export default reducer;
