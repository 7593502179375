import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  EditableText,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useState } from "react";
import { useParams } from "react-router";
import { dateTimeFormatter } from "../../utils/Utils/Utils";
import ActivityListCompact from "../Activity/ActivityListCompact";
import { useTorgaContext } from "../TorgaContext";
import { actionEquipmentPersist } from "./actions";
import "./equipment.css";
import EquipmentCard from "./EquipmentCardEvo";
import EquipmentProperty from "./EquipmentProperty";
import RelatedEquipments from "./RelatedEquipments";
import RichTextEditor from "./RichTextEditor";

export default function Equipment() {
  const { state, dispatch, dispatchAction } = useTorgaContext();
  const [showParentIdOverwiteAlert, setShowParentIdOverwiteAlert] = useState({
    showAlert: false,
    currentID: null,
    childID: null,
  });
  let { id } = useParams();
  const { [id]: equipment } = state.equipments.byID;

  if (!id) {
    return <NonIdealState icon={IconNames.BUILD} />;
  }

  if (!equipment) {
    return <div> Loading ...</div>;
  }

  const childrenIDs = state.equipments.allIDs
    .map((mid) => state.equipments.byID[mid])
    .filter((e) => e.parent_id == id)
    .map((e) => e.id);

  function handleChange(field, value) {
    dispatch({ type: "EQUIPMENT_CHANGE", payload: { id, field, value } });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionEquipmentPersist(id, field, value));
  }

  function onEquipementAdd(childID) {
    // Check if allready associated
    const currentID = state.equipments.byID[childID].parent_id;
    if (currentID) {
      setShowParentIdOverwiteAlert({
        showAlert: true,
        childID,
        currentID,
      });
    } else {
      addChildrenEquipment(childID);
    }
  }

  function addChildrenEquipment(childID) {
    dispatchAction(actionEquipmentPersist(childID, "parent_id", id));
  }

  function drawLine(field, placeholder) {
    return (
      <tr>
        <td>{field.charAt(0).toUpperCase() + field.slice(1)}</td>
        <td style={{ width: "300px" }}>
          <EquipmentProperty
            property={field}
            placeholder={placeholder || "Add " + field + " ..."}
            value={equipment[field]}
            //onChange={(e) => handleChange(field, e)}
            onConfirm={(e) => handleConfirm(field, e)}
          />
        </td>
      </tr>
    );
  }

  return (
    <>
      <h1>
        <EditableText
          placeholder="Equipment Name"
          value={equipment.name}
          onChange={(e) => handleChange("name", e)}
          onConfirm={(e) => handleConfirm("name", e)}
        />
      </h1>
      <ButtonGroup className="equipment___toolbar">
        <Button text="Stock" />
        <Button text="Daily" />
        <Button text="In-Use" />
        <Button text="Selling" />
        <Button text="Disposed" />
      </ButtonGroup>{" "}
      <ButtonGroup>
        <Button text="Clone" onClick={null /*this.handleClone*/} />
      </ButtonGroup>{" "}
      <ButtonGroup>
        <a
          className="bp4-button"
          target="blank"
          role="button"
          href={
            "https://www.google.com/search?q=" +
            encodeURIComponent(equipment.name)
          }
        >
          Google
        </a>
      </ButtonGroup>
      <div className="equipment__related_equipment">
        <EquipmentCard equipment_id={equipment.id} />
      </div>
      <hr />
      <table>
        <tbody>
          <tr>
            <td>Id</td>
            <td>
              <Checkbox
                checked={equipment.mark}
                style={{ marginBottom: "0" }}
                onChange={(e) => {
                  handleConfirm("mark", !equipment.mark);
                }}
              >
                <div className="h1-label">E{equipment.id}</div>
              </Checkbox>
            </td>
          </tr>

          {drawLine("catego")}
          {drawLine("type")}
          {drawLine("brand")}
          {drawLine("model")}
          {drawLine("color")}
          {drawLine("serial")}
          {drawLine("location")}
          {drawLine("vendor")}
          {drawLine("warranty_month")}
          {drawLine("invoice")}
          {drawLine("price")}
          <tr>
            <td>Date acquisition</td>
            <td>
              {dateTimeFormatter.format(new Date(equipment.date_acquisition))}
            </td>
          </tr>
          <tr>
            <td style={{ width: "200px" }}>Created on</td>
            <td>{dateTimeFormatter.format(new Date(equipment.createdAt))}</td>
          </tr>
          <tr>
            <td>Last Modified</td>
            <td>{dateTimeFormatter.format(new Date(equipment.updatedAt))}</td>
          </tr>
          <tr>
            <td>Deleted</td>
            <td>
              <Checkbox
                checked={equipment.deleted}
                style={{ marginBottom: "0" }}
                onChange={(e) => {
                  handleConfirm("deleted", !equipment.deleted);
                }}
              ></Checkbox>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <RichTextEditor
        objectID={equipment.id}
        onConfirm={(value) => handleConfirm("description", value)}
        property={"description"}
        placeholder={"Description..."}
        value={equipment["description"]}
      />
      <hr />
      <RelatedEquipments
        equipmentIDs={childrenIDs}
        onEquipementAdd={onEquipementAdd}
        onEquipementRemove={(childID) => {
          dispatchAction(actionEquipmentPersist(childID, "parent_id", null));
        }}
      />
      {equipment.parent_id && equipment.parent_id != 0 && (
        <>
          <hr />
          Parent
          <EquipmentCard equipment_id={equipment.parent_id} />
        </>
      )}
      <hr />
      <ActivityListCompact
        activityIDs={state.activities?.idsBy?.equipmentID?.[id]}
      />
      <hr />
      <Alert
        isOpen={showParentIdOverwiteAlert.showAlert}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        intent={Intent.DANGER}
        onCancel={() => {
          setShowParentIdOverwiteAlert({
            showAlert: false,
            currentID: null,
            childID: null,
          });
        }}
        onConfirm={() => {
          addChildrenEquipment(showParentIdOverwiteAlert.childID);
          setShowParentIdOverwiteAlert({
            showAlert: false,
            currentID: null,
            childID: null,
          });
        }}
      >
        Selected children is already attached to another Equipment:
        <EquipmentCard
          equipment_id={showParentIdOverwiteAlert.currentID}
          equipment_name={
            state.equipments.byID[showParentIdOverwiteAlert.currentID]?.name
          }
        />
      </Alert>
    </>
  );
}
