import { tget } from "../../utils/Utils/Utils";

export function actionFetchTriggers() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/trigger").then((result) => {
      dispatch({ type: "TRIGGERS_FETCH_COMPLETE", payload: result });
    });
  };
}
