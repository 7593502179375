import { ObjectTypes } from "../../app/MainRouter";
import { searchIn } from "../../utils/Search/SearchUtils";
import { urlToAccountID } from "../BankRouter";

function buildSearchString(account) {
  return account.name + "|" + account.nom + "|" + account.type;
}

export function searchAccounts(accountsCollection, query) {
  return searchIn(
    ObjectTypes.USERS,
    accountsCollection,
    query,
    buildSearchString,
    (account) => ({
      id: account.id,
      key: "C" + account.id,
      name: account.name,
      details: [account.type].join(" "),
      link: urlToAccountID(account.id),
    })
  );
}
