export function healthcheck(state) {
  const { treeIndex: operations } = state.operations.idsBy;
  const { treeIndex: budgets } = state.budgets.idsBy;

  if (!operations || state.operations.allIDs.length == 0) {
    return [];
  }

  if (!budgets || state.budgets.allIDs.length == 0) {
    return [];
  }

  const iterateOverStruct = (result, cb) => {
    Object.keys(result).forEach((account) => {
      const { [account]: years } = result;
      Object.keys(years).forEach((year) => {
        const { [year]: months } = years;
        Object.keys(months).forEach((month) => {
          const { [month]: categos } = months;
          Object.keys(categos).forEach((catego) => {
            cb(account, year, month, catego, categos[catego]);
          });
        });
      });
    });
  };

  const result = {};
  const getOrCreateLine = (account, year, month, catego) => {
    if (!result[account]) {
      result[account] = {};
    }
    const accountRes = result[account];
    if (!accountRes[year]) {
      accountRes[year] = {};
    }
    const yearRes = accountRes[year];
    if (!yearRes[month]) {
      yearRes[month] = {};
    }
    const monthRes = yearRes[month];
    if (!monthRes[catego]) {
      monthRes[catego] = {
        budgetCredit: 0,
        budgetDebit: 0,
        budgetIDs: [],
        operationsCredit: 0,
        operationsDebit: 0,
        operationIDs: [],
      };
    }
    return monthRes[catego];
  };

  iterateOverStruct(
    operations,
    (account, year, month, catego, operationIDs) => {
      const line = getOrCreateLine(account, year, month, catego);

      line.operationIDs = operationIDs;
      line.operationsCredit = operationIDs
        .map((id) => state.operations.byID[id])
        .reduce((a, b) => a + parseFloat(b.credit), 0);

      line.operationsDebit = operationIDs
        .map((id) => state.operations.byID[id])
        .reduce((a, b) => a + parseFloat(b.debit), 0);
    }
  );

  iterateOverStruct(budgets, (account, year, month, catego, budgetIDs) => {
    const line = getOrCreateLine(account, year, month, catego);

    line.budgetCredit = budgetIDs
      .map((id) => state.budgets.byID[id])
      .reduce((a, b) => a + parseFloat(b.credit), 0);

    line.budgetDebit = budgetIDs
      .map((id) => state.budgets.byID[id])
      .reduce((a, b) => a + parseFloat(b.debit), 0);

    line.budgetIDs = budgetIDs;
  });

  const list = [];
  const currYear = new Date().getFullYear();
  const currMonth = new Date().getMonth() + 1;

  iterateOverStruct(result, (account, year, month, catego, line) => {
    if (
      (line.budgetCredit.toFixed(2) != line.operationsCredit.toFixed(2) ||
        line.budgetDebit.toFixed(2) != line.operationsDebit.toFixed(2)) &&
      (year < currYear || (year == currYear && month < currMonth))
    ) {
      list.push({
        id: `${account}_${year}_${month}_${catego}`,
        account,
        year,
        month,
        catego,
        ...line,
      });
    }
  });

  return list;
}
