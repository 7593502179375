import { Alignment, Button, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { actionLogin, actionSignup } from "../actions.js";
import { useAppContext } from "../AppContext";
import "./Login.css";

export default function Login() {
  const { state, dispatch, dispatchAction } = useAppContext();
  const [register, setRegister] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <div id="login-form-decorator">
      <form
        id="login-form"
        onSubmit={(e) => {
          e.preventDefault();

          dispatch({ type: "SIGN_START" });
          if (register) {
            dispatchAction(actionSignup(name, email, password));
          } else {
            dispatchAction(actionLogin(email, password));
          }
        }}
      >
        <h3 className="t51">{register ? "Sign-up" : "Login"}</h3>
        <div>
          {register && (
            <>
              <InputGroup
                type="text"
                large="true"
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <br />
            </>
          )}

          <InputGroup
            type="email"
            large="true"
            placeholder="Email"
            value={email}
            leftIcon={IconNames.PERSON}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <br />

          <InputGroup
            type="password"
            large="true"
            leftIcon="lock"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <br />
          <Button
            type="submit"
            large="true"
            alignText={Alignment.CENTER}
            fill="true"
            rightIcon="arrow-right"
            disabled={state.auth.loading}
            intent={Intent.PRIMARY}
            onClick={() => {}}
          >
            {register ? "Create Account" : "Login"}
          </Button>
          <p>{state.auth.message}</p>

          <Button
            large="true"
            minimal
            alignText={Alignment.CENTER}
            fill="true"
            onClick={() => {
              setRegister(!register);
            }}
          >
            {register ? "Login" : "Sign-up"}
          </Button>
        </div>
      </form>
    </div>
  );
}
