import React, { useContext } from "react";
import { Browser, List, Page as Page2 } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { TaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToTriggerID } from "../TaskOneRouter";
import { searchTriggers } from "./search";
import Trigger from "./Trigger";

export default function Triggers() {
  const { state, dispatch } = useContext(TaskOneContext);
  const [search, setSearch] = React.useState("");

  const triggers =
    search && search != ""
      ? searchTriggers(state.triggers, { text: search })
      : state.triggers.allIDs
          .map((id) => state.triggers.byID[id])
          .map((trigger) => ({
            id: trigger.id,
            name: trigger.name,
          }));

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={triggers}
          objectType={ObjectTypes.TRIGGERS}
          urlToObject={urlToTriggerID}
          onChange={setSearch}
        />
      </List>
      <Page2>
        <Trigger />
      </Page2>
    </Browser>
  );
}
