import { Button, Checkbox } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import ListComponent from "../../utils/ListComponent/ListComponent";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes } from "../TaskOneRouter";
import "./actionitem.css";
import { actionActionItemCreate, actionPersistActionItem } from "./actions";

export default function ActionItemsComponent({
  ids: actionItemIDs,
  context,
  header,
}) {
  const { state, dispatchAction } = useTaskOneContext();
  const [showCompleted, setShowCompleted] = React.useState(false);

  const actionItems =
    actionItemIDs?.map((id) => state.actionitems.byID[id]) || [];

  const filtered =
    actionItems
      .filter((actionItem) => actionItem?.status != "Done" || showCompleted)
      .filter((actionItem) => actionItem != undefined) || [];

  return (
    <ListComponent
      objectType={ObjectTypes.ACTION_ITEMS}
      values={actionItems}
      header={header}
      render={(actionItem) => [
        <Checkbox
          checked={actionItem.status === "Done"}
          onClick={() => {
            const status = actionItem.status == "Done" ? null : "Done";
            dispatchAction(
              actionPersistActionItem(actionItem.id, "status", status)
            );
          }}
        />,
        actionItem.name,
      ]}
      quickentry={{ placeholder: "Add new Action Item" }}
      onCreate={(name) => {
        dispatchAction(actionActionItemCreate(name, "", context));
      }}
      filter={
        <>
          <div style={{ padding: "6px", color: "grey" }}>
            {filtered.length} / {actionItems.length}
          </div>
          <Button
            icon={IconNames.COMPRESSED}
            active={showCompleted}
            onClick={() => setShowCompleted(!showCompleted)}
          />
        </>
      }
    />
  );
}
