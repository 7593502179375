import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchMeetings() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/meeting").then((result) => {
      dispatch({ type: "FETCH_DATA_COMPLETE", payload: result });
    });
  };
}

export function actionMeetingDelete(id) {
  return (dispatch, complete) => {
    tpost(`/api/v2/meeting/${id}/deleted`, { deleted: true })
      .then((result) => {
        dispatch({ type: "NAV", payload: `/t/meeting/M${result.parent_id}` });
        complete();
      })
      .catch((err) => dispatch({ type: "ERROR", payload: err }));
  };
}

export function actionPersistMeeting(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/meeting/${id}`, {
      [field]: value,
    }).then((result) => {
      dispatch({ type: "MEETING_PERSIST_COMPLETED", payload: [result] });
    });
  };
}

export function actionCreateMeeting(onMeetingCreated = null) {
  return (dispatch) => {
    tpost("/api/v2/meeting").then((result) => {
      dispatch({ type: "MEETING_CREATE_COMPLETE", payload: [result] });
      onMeetingCreated && onMeetingCreated(result.id);
    });
  };
}

export function actionFetcMeetingsLinks() {
  return (dispatch) => {
    tget(`/api/v2/meetinglinks`).then((result) =>
      dispatch({ type: "MEETING_LINKS_FETCH_COMPLETE", payload: result })
    );
  };
}
