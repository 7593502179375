import React from "react";
import { ButtonGroup, Button, Intent } from "@blueprintjs/core";

export const Status = {
  NONE: "none",
  TODO: "To Do",
  PAUSED: "Pause",
  INPROGRESS: "In Progress",
  DONE: "Done",
};

function selected(buttonType, status) {
  if (buttonType === status) {
    if (buttonType == Status.INPROGRESS) return { intent: Intent.WARNING };
    if (buttonType == Status.TODO) return { intent: Intent.WARNING };
    if (buttonType == Status.DONE) return { intent: Intent.SUCCESS };
    return { intent: Intent.PRIMARY };
  } else if (buttonType === Status.NONE && status === null) {
    return { intent: Intent.PRIMARY };
  }
  return null;
}

function StatusButton({ status, type, onChange }) {
  return (
    <Button
      text={type}
      {...selected(type, status)}
      onClick={(e) => {
        onChange(type);
      }}
    />
  );
}

export default function StatusBar({ status, onChange }) {
  return (
    <>
      <ButtonGroup>
        <StatusButton status={status} onChange={onChange} type={Status.NONE} />
        <StatusButton status={status} onChange={onChange} type={Status.TODO} />
        <StatusButton
          status={status}
          onChange={onChange}
          type={Status.PAUSED}
        />
        <StatusButton
          status={status}
          onChange={onChange}
          type={Status.INPROGRESS}
        />
        <StatusButton status={status} onChange={onChange} type={Status.DONE} />
      </ButtonGroup>
    </>
  );
}
