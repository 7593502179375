import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToActivityID } from "../TorgaRouter";

function buildActivitySearchString(e) {
  return [
    "A" + e.id,
    e.name,
    e.content,
    e.gtd,
    e.atus,
    e.location,
    e.nedt,
    e.type,
    e.status,
  ].join("|");
}

/**
 *
 * @param {Object} equiments collection from the state (allIDs/byID)
 * @param {Object} query the terms to search for
 * @param {Function} buildSearchString how to build the serach string
 * @param {Function} buildResult how to format the result list
 */
export function searchActivities(activities, query) {
  return searchIn(
    ObjectTypes.ACTIVITIES,
    activities,
    query,
    buildActivitySearchString,
    (activity) => {
      //const parentName = activities.byID[activity.parent_id]?.name;

      return {
        id: activity.id,
        key: "A" + activity.id,
        name: `${activity.name} `,
        details: activity.content,
        link: urlToActivityID(activity.id),
      };
    }
  );
}
