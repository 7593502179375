import { Hotkey, Hotkeys } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useNavigate } from "react-router";
import Badge from "../app/SideDrawer/Badge";
import Menu from "../app/SideDrawer/Menu";
import AppModuleConnector from "../utils/AppFramework/AppModuleConnector";
import { useHotkeys } from "../utils/Hotkeys";
import Activities from "./Activity/Activities";
import Calendar from "./Calendar/Calendar";
import Diary from "./Diary/Diary";
import Equipments from "./Equipment/Equipments";
import Review from "./Review/Review";
import { selectActivityToReview } from "./Review/selectors";
import { search } from "./search";
import { TorgaContext, TorgaContextProvider } from "./TorgaContext";
import Trips from "./Trip/Trips";
import { Routes, Route } from 'react-router-dom';

class TorgaObject {
  constructor(key, name, singular) {
    this.key = key;
    this.name = name;
    this.singular = singular;
    this.domain = "s";
    this.usePrefix = false;
  }

  get uri() {
    return `/${this.domain}/${this.singular}`;
  }

  objectUri(id) {
    const prefix = this.usePrefix ? this.key : "";
    return `/${this.domain}/${this.singular}/${prefix}${id}`;
  }

  withPrefixURI() {
    this.usePrefix = true;
    return this;
  }
}

export const ObjectTypes = {
  ACTIVITIES: new TorgaObject("A", "activities", "activity").withPrefixURI(),
  EQUIPMENTS: new TorgaObject("E", "equipments"),
  TRIPS: new TorgaObject("V", "trips"),
  DIARYPOSTS: new TorgaObject("D", "diaryposts"),
};

export const urlToActivity = () => ObjectTypes.ACTIVITY.uri;
export const urlToActivityID = (id) => {
  const isReview =
    window.location.pathname.slice(0, urlToReview().length) === urlToReview();
  return `/s/${isReview ? "review" : "activity"}/A${id}`;
};
export const urlToEquipmentID = (id) => `/s/equipment/${id}`;
export const urlToEquipment = (id) => `/s/equipment`;
export const urlToReview = () => `/s/review`;
export const urlToCalendar = () => `/s/calendar`;
export const urlToTripID = (id) => `/s/trip/${id}`;
export const urlToDiary = () => `s/diary`;
export const urlToDiaryPost = (id) => `/s/diary/${id}`;
export const urlToReviewID = (id) => `/s/review/A${id}`;

const MENU_ENTRIES = [
  { name: "Activities", path: `/s/activity`, icon: IconNames.PULSE },
  {
    name: "Review",
    path: `/s/review`,
    icon: IconNames.TICK_CIRCLE,
    badge: "TORGA_REVIEW",
  },
  { name: "Calendar", path: `/s/calendar`, icon: IconNames.CALENDAR },
  { name: "Equipment", path: `/s/equipment`, icon: IconNames.BUILD },
  { name: "Trip", path: `/s/trip`, icon: IconNames.AIRPLANE },
  { name: "Diary", path: `/s/diary`, icon: IconNames.DRAW },
];

export default function Torga() {
  const navigate = useNavigate();


  useHotkeys(
    <Hotkeys>
      <Hotkey
        label="Search Activities"
        allowInInput
        combo="ctrl+a"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToActivity());
        }}
      />
      <Hotkey
        label="Search Calendar"
        allowInInput
        combo="ctrl+c"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToCalendar());
        }}
      />
      <Hotkey
        label="Search Equipments"
        allowInInput
        combo="ctrl+e"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToEquipment());
        }}
      />
    </Hotkeys>
  );

  return (
    <TorgaContextProvider>
      <Menu name="Torga" entries={MENU_ENTRIES} />
      <Badge
        context={TorgaContext}
        name="TORGA_REVIEW"
        valueFct={(state) => selectActivityToReview(state, new Date()).length}
      />
      <AppModuleConnector
        context={TorgaContext}
        name="Torga"
        objectTypes={ObjectTypes}
        search={search}
      />

      <Routes>
        <Route path="/s/activity/:id?" element={<Activities />} />
        <Route path="/s/equipment/:id?" element={<Equipments />} />
        <Route path="/s/trip/:id?" element={<Trips />} />
        <Route path="/s/review/:id?" element={<Review />} />
        <Route path="/s/calendar" element={<Calendar />} />
        <Route path="/s/diary/:id?" element={<Diary />} />
      </Routes>

    </TorgaContextProvider>
  );
}
