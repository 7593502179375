import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Search from "../app/Search/Search";
import Toolbar from "../app/Toolbar/Toolbar";
import BankRouter from "../bank/BankRouter";
import TorgaRouter from "../torga/TorgaRouter";
import TaskOneRouter from "../pro/TaskOneRouter";
import AppModuleConnector from "../utils/AppFramework/AppModuleConnector";

import { AppContext, useAppContext } from "./AppContext";
import Console from "./Console/Console";
import "./MainRouter.css";
import { search } from "./search";
import Settings from "./Settings/Settings";
import SideDrawer from "./SideDrawer/SideDrawer";
import HotKeys from "./HotKeys";

export const urlToUsers = () => `/users`;
export const urlToUserID = (id) => `/users/${id}`;

export const ObjectTypes = {
  USERS: { name: "users", key: "U" },
};

export const DisplayModes = {
  COMPACT: "compact",
  MOBILE: "mobile",
  NORMAL: "normal",
};

export default function MainRouter() {
  const [displayMode, setDisplayMode] = React.useState(DisplayModes.COMPACT);
  const [showMenu, setShowMenu] = React.useState(false);
  const { state } = useAppContext();

  React.useEffect(() => {
    const resize = () => {
      if (window.innerWidth < 1194) {
        setDisplayMode(DisplayModes.MOBILE);
      } else {
        setDisplayMode(DisplayModes.COMPACT);
      }
    };
    resize();

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const config = state.auth.config || {};

  const mainrouter_content_visibility = !showMenu
    ? ""
    : "mainrouter__content-visibility";

  return (
    <BrowserRouter>
      <div className="mainrouter bp4-dark">
        <div className="mainrouter__left" style={{ flexGrow: true ? 0 : 1 }}>
          <Toolbar
            displayMode={displayMode}
            drawerClickHandler={() => {
              setShowMenu(!showMenu);
            }}
          />

          <SideDrawer
            show={displayMode !== DisplayModes.MOBILE || showMenu}
            hideDrawer={() => {}}
            compact={displayMode === DisplayModes.COMPACT}
            toggleCompact={() => {
              if (displayMode === DisplayModes.COMPACT) {
                setDisplayMode(DisplayModes.NORMAL);
              } else if (displayMode === DisplayModes.NORMAL) {
                setDisplayMode(DisplayModes.COMPACT);
              }
            }}
          />
        </div>
        {
          <div
            className={[
              "mainrouter__content",
              mainrouter_content_visibility,
            ].join(" ")}
          >
            <Console />
            <div className="mainrouter__center">
              <AppModuleConnector
                context={AppContext}
                name="App"
                objectTypes={ObjectTypes}
                search={search}
              />

              {config.torga && <TorgaRouter />}
              {config.pro && <TaskOneRouter />}
              {config.bank && <BankRouter />}

              <Routes>
                <Route path="/" element={<div>ROOT</div>}></Route>
                <Route path="/admin" element={<Settings />} />
                <Route path="/search" element={<Search />} />
                <Route path="/*" element={<div></div>} />
              </Routes>
            </div>
            {/*<Footer />*/}
          </div>
        }
      </div>
      <HotKeys />
    </BrowserRouter>
  );
}
