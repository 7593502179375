import { Hotkey, Hotkeys } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import Badge from "../app/SideDrawer/Badge";
import Highlight from "../app/SideDrawer/Highlight";
import Menu from "../app/SideDrawer/Menu";
import AppModuleConnector from "../utils/AppFramework/AppModuleConnector";
import { useHotkeys } from "../utils/Hotkeys";
import { currency } from "../utils/Utils/Utils";
import Accounts from "./Accounts/Accounts";
import {
  getAccountBalances,
  getTotalBalanceFromAccountBalances,
} from "./Accounts/selectors";
import { BankContext, BankContextProvider } from "./BankContext";
import Budget from "./Budget/Budget";
import Calc from "./Calc/Calc";
import Catego, {
  selectorBankOperationToCategorize as toCatego,
} from "./Catego/Catego";
import Checks from "./Check/Checks";
import BudgetDocuments from "./Projection/BudgetDocuments";
import { search } from "./search";

export const urlToCategoID = (id) => `/b/catego/${id}`;
export const urlToOperationID = (id) => `/b/operation/${id}`;
export const urlToAccounts = () => `/b/account/`;
export const urlToAccountID = (id) => `/b/account/${id}`;
export const urlToCheckID = (id) => `/b/check/${id}`;

export const ObjectTypes = {
  OPERATIONS: { name: "operations", key: "O" },
  BUDGET: { name: "budget", key: "B" },
  ACCOUNT: { name: "account", key: "C" },
  CHECKS: { name: "checks", key: "Q" },
};

const MENU_ENTRIES = [
  {
    name: "Accounts",
    path: `/b/account`,
    icon: IconNames.BANK_ACCOUNT,
    highlight: "ACCOUNTS_BALANCE",
  },
  { name: "Catego", path: `/b/catego`, icon: "tag", badge: "BANK_CATEGO" },
  {
    name: "Check",
    path: `/b/check`,
    icon: IconNames.COMPARISON,
    badge: "BANK_CHECK",
  },
  { name: "Calc", path: `/b/calc`, icon: IconNames.CALCULATOR },
  { name: "Projection", path: `/b/projection`, icon: IconNames.DASHBOARD },
  { name: "Budget", path: `/b/budget`, icon: IconNames.DOLLAR },
];

export default function BankRouter() {
  const navigate = useNavigate();

  useHotkeys(
    <Hotkeys>
      <Hotkey
        label="Go To Bank"
        allowInInput
        combo="ctrl+b"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToAccounts());
        }}
      />
    </Hotkeys>
  );

  return (
    <BankContextProvider>
      <Menu name="Bank" entries={MENU_ENTRIES} />
      <Badge
        context={BankContext}
        name="BANK_CATEGO"
        valueFct={(state) => toCatego(state)?.length}
      />
      <Badge
        context={BankContext}
        name="BANK_CHECK"
        valueFct={(state) => state.checks.allIDs.length}
      />
      <Highlight
        context={BankContext}
        name="ACCOUNTS_BALANCE"
        valueFct={(state) => {
          const balance = getTotalBalanceFromAccountBalances(
            getAccountBalances(state).filter(
              (p) => p.account === "CMB.COMPTE_CHEQUE"
            )
          );
          return balance ? currency(balance) : null;
        }}
      />
      <AppModuleConnector
        context={BankContext}
        name="Bank"
        objectTypes={ObjectTypes}
        search={search}
      />

      <Routes>
        <Route path="/b/account/:id?" element={<Accounts />} />
        <Route path="/b/catego/:id?" element={<Catego />} />
        <Route path="/b/check" element={<Checks />} />
        <Route path="/b/calc" element={<Calc />} />
        <Route path="/b/projection" element={<BudgetDocuments />} />
        <Route path={"/b/budget"} element={<Budget />} />

      </Routes>
    </BankContextProvider>
  );
}
