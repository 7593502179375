import { Checkbox, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useNavigate } from "react-router";
import { FullPage } from "../../utils/Layout/Layout";
import { capitalize } from "../../utils/Utils/Utils";
import { useAppContext } from "../AppContext";
import "./search.css";
import SearchCard from "./SearchCard";

export default function Search() {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [selectedObjectTypes, setSelectedObjectTypes] = React.useState([
    "activities",
    "projects",
    "triggers",
    "persons",
    "meetings",
  ]);

  const [results, setResults] = React.useState([]);
  const { state } = useAppContext();
  const { apis: contextAPIs } = state;

  const groupsFirstIndex = [];

  let timerToSearch = null;
  React.useEffect(() => {
    if (search === "") {
      setSelectedIndex(-1);
      setResults([]);
      return;
    }
    if (timerToSearch) {
      timerToSearch.clearTimeout();
    }
    timerToSearch = setTimeout(() => {
      doSearch(search);
    }, 250);

    return () => {
      clearTimeout(timerToSearch);
    };
  }, [search, selectedObjectTypes]);

  function doSearch(value) {
    setSelectedIndex(-1);
    const searchProperties = {
      text: value,
      objectTypes: selectedObjectTypes,
    };

    // Dispatch search on all modules
    setResults(
      contextAPIs.map((contextAPI) => {
        return contextAPI.search(searchProperties);
      })
    );
  }

  function onKeyDown(e) {
    if (e.key == "ArrowDown") {
      e.preventDefault();
      if (e.shiftKey) {
        // search next group index
        const next = groupsFirstIndex.filter((a) => a > selectedIndex);
        if (next[0]) {
          setSelectedIndex(Math.min(next[0], selectIndex - 1));
          return;
        }
      }
      setSelectedIndex(Math.min(selectedIndex + 1, selectIndex - 1));
    }
    if (e.key == "ArrowUp") {
      e.preventDefault();

      if (e.shiftKey) {
        const prev = groupsFirstIndex.filter((a) => a < selectedIndex);
        if (prev.length > 0) {
          const val = prev[prev.length - 1];
          setSelectedIndex(Math.max(val, 0));
          return;
        }
      }

      setSelectedIndex(Math.max(0, selectedIndex - 1));
    }
    if (e.key === "Escape") {
      navigate(-1);
    }
    if (e.key === "Enter") {
      keyboardLink && navigate(keyboardLink);
    }
  }

  let selectIndex = 0;
  let keyboardLink = null;

  return (
    <FullPage>
      <h1>Search</h1>

      <InputGroup
        leftIcon={IconNames.SEARCH}
        placeholder="Search..."
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={onKeyDown}
      ></InputGroup>

      <div className="search__options">
        {contextAPIs
          .flatMap((contextAPI) => Object.values(contextAPI.objectTypes))
          .map((a) => a.name)
          .sort()
          .map((value) => (
            <Checkbox
              key={value}
              inline
              label={value}
              checked={selectedObjectTypes.includes(value)}
              onChange={(e) => {
                setSelectedObjectTypes(
                  selectedObjectTypes.includes(value)
                    ? selectedObjectTypes.filter((v) => v != value)
                    : [...selectedObjectTypes, value]
                );
              }}
            ></Checkbox>
          ))}
      </div>

      <div>
        {results.map((moduleResults, id) => (
          <div key={id}>
            {Object.keys(moduleResults).map((key, iterIndex) => {
              if (moduleResults[key].length == 0) {
                return;
              }

              return (
                <div key={key + iterIndex} className="search__groups">
                  <h2>{capitalize(key)}</h2>
                  {groupsFirstIndex.push(selectIndex)}
                  {moduleResults[key]?.slice(0, 20).map((object) => {
                    const selected = selectIndex++ == selectedIndex;
                    if (selected) {
                      keyboardLink = object.link;
                    }

                    return (
                      <SearchCard
                        selected={selected}
                        key={key + object.id}
                        objectKey={object.key}
                        {...object}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </FullPage>
  );
}
