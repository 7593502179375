import { Button, ButtonGroup } from "@blueprintjs/core";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { actionActivityPersist } from "../Activity/actions";
import Activities from "../Activity/Activities";
import { useTorgaContext } from "../TorgaContext";
import { urlToReviewID } from "../TorgaRouter";
import "./review.css";
import { selectActivityToReview } from "./selectors";

export default function Review() {
  const { state, dispatchAction } = useTorgaContext();
  const [index, setIndex] = React.useState(0);
  const [flag, setFlag] = React.useState(true);
  const navigate = useNavigate();
  let { id: prefixedID } = useParams();
  const id = prefixedID?.slice(1);

  const sorted = selectActivityToReview(state, new Date());

  const first = sorted[index]?.id;

  React.useEffect(() => {
    if (first) navigate(urlToReviewID(first));
  }, [index]);

  if (!id && first) {
    navigate(urlToReviewID(first));
  } else if (flag && first) {
    setFlag(false);
    navigate(urlToReviewID(first));
  }

  if (sorted.length === 0) {
    return <div>Loading...</div>;
  }

  const nextReviewIn = (days) => {
    const nextReview = new Date(new Date().getTime() + days * 24 * 3600 * 1000);
    dispatchAction(
      actionActivityPersist(first, "next_review", nextReview, () => {
        setFlag(true);
      })
    );
  };

  return (
    <div className="review">
      <div className="review__controls">
        <div>
          {index + 1} / {sorted.length} | A{first} | Next review in
        </div>
        <div>
          <ButtonGroup>
            <Button onClick={() => nextReviewIn(7)}>1 week</Button>
            <Button onClick={() => nextReviewIn(30)}>1 month</Button>
            <Button onClick={() => nextReviewIn(90)}>3 months</Button>
            <Button onClick={() => nextReviewIn(365)}>1 year</Button>
          </ButtonGroup>
          {"  "}
          <ButtonGroup>
            <Button onClick={() => nextReviewIn(365 * 3)}>3 years</Button>
            <Button onClick={() => nextReviewIn(365 * 10)}>10 years</Button>
          </ButtonGroup>
        </div>
        <div>
          <Button
            onClick={() => setIndex(index + 1)}
            disabled={index + 1 >= sorted.length}
          >
            Next
          </Button>
        </div>
      </div>
      <div className="review_page">
        <Activities />
      </div>
    </div>
  );
}
