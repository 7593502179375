import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToContactID } from "../TaskOneRouter";

export function searchContacts(contacts, query) {
  function buildProjectSearchString(e) {
    return ["C" + e.id, e.name].join("|");
  }

  return searchIn(
    ObjectTypes.CONTACTS,
    contacts,
    query,
    buildProjectSearchString,
    (project) => {
      return {
        id: project.id,
        key: "P" + project.id,
        name: project.name,
        link: urlToContactID(project.id),
      };
    }
  );
}
