import React from "react";
import { useNavigate } from "react-router-dom";
import ListComponent from "../../utils/ListComponent/ListComponent";
import { useTorgaContext } from "../TorgaContext";
import { ObjectTypes, urlToEquipmentID } from "../TorgaRouter";
import EquipmentCard from "./EquipmentCardEvo";
import EquipmentSelect from "./EquipmentSelect";

export default function RelatedEquipments({
  equipmentIDs,
  onEquipementAdd,
  onEquipementRemove,
}) {
  const { state } = useTorgaContext();
  const navigate = useNavigate();

  const addComponent = (equipment, onComplete) => {
    return (
      <EquipmentSelect
        onSelect={(id) => {
          console.log("ok", id);
          onEquipementAdd(id);
        }}
      />
    );
  };

  const equipments = equipmentIDs?.map((id) => state.equipments.byID[id]) || [];

  return (
    <ListComponent
      add
      remove
      objectType={ObjectTypes.EQUIPMENTS}
      values={equipments}
      cards={(equipment, removeMode) => [
        <EquipmentCard
          key={equipment.id}
          equipment_id={equipment.id}
          deleteMode={removeMode}
          onClick={() => {
            if (removeMode) {
              onEquipementRemove(equipment.id);
            } else {
              navigate(urlToEquipmentID(equipment.id));
            }
          }}
        />,
      ]}
      createEditorComponent={addComponent}
    />
  );
}
