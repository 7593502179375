import { Tag } from "@blueprintjs/core";
import React from "react";
import { currency, dateShort } from "../../utils/Utils/Utils";
import { useBankContext } from "../BankContext";
import "./operation.css";

export default function Operation({ id }) {
  const { state } = useBankContext();

  const { [id]: o } = state.operations.byID;

  if (!o) {
    return <div>Loading...</div>;
  }

  return (
    <div className="operation">
      <div className="operation_text">
        <div className="operation__libelle">{o.libelle}</div>

        {o.catego && (
          <div>
            <Tag>{o.catego || "UNKNOWN"}</Tag> {o.year || "YEAR"} /
            {o.month || "MONTH"}
          </div>
        )}
        <div className="operation__account">
          {o.compte} {dateShort(o.date_operation)}
        </div>
      </div>
      <div className="operation__value">{currency(o.credit - o.debit)}</div>
    </div>
  );
}
