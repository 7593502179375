import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchTrips() {
  return (dispatch) => {
    tget("/api/v2/trip")
      .then((trips) =>
        dispatch({ type: "TRIP_FETCH_COMPLETE", payload: trips })
      )
      .then(() => {
        // Get next object
      });
  };
}

export function actionCreateTrip(tripCreatedCallback) {
  return (dispatch) => {
    tpost("/api/v2/trip").then((trip) => {
      dispatch({ type: "TRIP_FETCH_COMPLETE", payload: [trip] });
      tripCreatedCallback(trip.id);
    });
  };
}

export function actionUpdateTrip(id, props) {
  return (dispatch) => {
    return tpost(`/api/v2/trip/${id}`, props).then((trip) => {
      dispatch({ type: "TRIP_FETCH_COMPLETE", payload: [trip] });
    });
  };
}
