import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchWeeks() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/week").then((result) => {
      dispatch({ type: "WEEKS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionFetchWeeksProjects() {
  return (dispatch) => {
    tget("/api/v2/weeksprojects").then((result) => {
      dispatch({ type: "WEEKSPROJECTS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionCreateNewWeek(onWeekCreated = null) {
  return (dispatch) => {
    tpost("/api/v2/week").then((result) => {
      dispatch({ type: "WEEKS_CREATE_COMPLETED", payload: [result] });
      onWeekCreated && onWeekCreated(result.id);
    });
  };
}

export function actionArchiveWeek(id, archived) {
  return (dispatch) => {
    tpost(`/api/v2/week/${id}`, {
      archived: archived,
    }).then((result) => {
      dispatch({ type: "WEEKS_ARCHIVE_COMPLETE", payload: [result] });
    });
  };
}

export function actionAddProjectToWeek(project_id, week_id) {
  return (dispatch) => {
    tpost(`/api/v2/weeksprojects`, {
      project_id: project_id,
      week_id: week_id,
      deleted: false,
    }).then((result) => {
      dispatch({ type: "WEEKSPROJECTS_ADD_COMPLETE", payload: [result] });
    });
  };
}

export function actionRemoveProjectFromWeek(project_id, week_id) {
  return (dispatch) => {
    tpost(`/api/v2/weeksprojects`, {
      project_id: project_id,
      week_id: week_id,
      deleted: true,
    }).then((result) => {
      dispatch({ type: "WEEKSPROJECTS_REMOVE_COMPLETE", payload: [result] });
    });
  };
}

export function actionPersistWeek(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/week/${id}`, { [field]: value }).then((result) =>
      dispatch({ type: "WEEK_PERSIST_COMPLETE", payload: [result] })
    );
  };
}
