import React from "react";
import { Link } from "react-router-dom";

export default function SearchCard({
  objectKey,
  link,
  id,
  name,
  details,
  selected,
}) {
  const ref = React.useRef();
  const styles = [];
  styles.push("search__cards");
  if (selected) {
    styles.push("search__card_selected");
  }

  if (selected && ref.current) {
    ref.current.scrollIntoViewIfNeeded && ref.current.scrollIntoViewIfNeeded();
  }

  return (
    <div ref={ref} className={styles.join(" ")}>
      <Link to={link}>
        {objectKey} - {name}
      </Link>

      <div>{details?.substring(0, Math.min(400, details.length))}</div>
    </div>
  );
}
