import { Icon, Tag } from "@blueprintjs/core";
import React, { useState } from "react";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTorgaContext } from "../TorgaContext";
import { ObjectTypes, urlToEquipmentID } from "../TorgaRouter";
import { actionEquipmentNew } from "./actions";
import "./EquipmentSelect.css";
import { searchEquipments } from "./search";

export default function EquipmentSelect({ onSelect }) {
  const [showFilters, setShowFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);
  const { state, dispatchAction } = useTorgaContext();
  const [search, setSearch] = useState("");

  const { allIDs, byID } = state.equipments;

  if (allIDs.length == 0) {
    return "Loading ...";
  }

  const EMPTY = "<empty>";
  const TRUE = "<true>";
  const FALSE = "<false>";

  const FILTERS = [
    {
      name: "Category",
      field: "catego",
      values: [],
      getValue: (value) => (value == null ? EMPTY : value),
    },
    {
      name: "Type",
      field: "type",
      values: [],
      getValue: (value) => (value == null ? EMPTY : value),
    },
    {
      name: "Brand",
      field: "brand",
      values: [],
      getValue: (value) => (value == null ? EMPTY : value),
    },
    {
      name: "Location",
      field: "location",
      values: [],
      getValue: (value) => (value == null ? EMPTY : value),
    },
    {
      name: "Mark",
      field: "mark",
      values: [],
      getValue: (value) => (value ? TRUE : FALSE),
    },
  ];

  // Text search

  const filteredIDs = searchEquipments(state.equipments, { text: search })
    .map((object) => object.id)
    .filter((id) =>
      activeFilters
        .map((filter) => {
          return filter.getValue(byID[id][filter.field]) == filter.value;
        })
        .reduce((a, c) => a && c, true)
    );

  // Build filters

  const equipments = filteredIDs.map((id) => {
    const equipment = byID[id];
    FILTERS.forEach((filter) =>
      filter.values.push(filter.getValue(equipment[filter.field]))
    );
    return equipment;
  });

  return (
    <>
      <ObjectSelector
        objectType={ObjectTypes.EQUIPMENTS}
        urlToObject={urlToEquipmentID}
        objects={equipments}
        onCreate={() => dispatchAction(actionEquipmentNew(onSelect))}
        onChange={(value) => setSearch(value)}
        onSelect={onSelect}
      >
        <div className="equipmentselect">
          {activeFilters.length > 0 && (
            <div className="equipmentselect__tags">
              {activeFilters.map((filter, index) => (
                <Tag
                  key={filter.field}
                  onRemove={(e) => {
                    setActiveFilters(
                      activeFilters.filter((v, i) => i != index)
                    );
                  }}
                >
                  {filter.name} :{filter.value}
                </Tag>
              ))}
            </div>
          )}

          <ul>
            {FILTERS.map((filter) => {
              const values = filter.values.reduce(
                (a, c) => ({
                  ...a,
                  [c]: a[c] ? a[c] + 1 : 1,
                }),
                {}
              );
              return (
                <div key={filter.field}>
                  <li
                    onClick={() =>
                      setShowFilters({
                        ...showFilters,
                        [filter.field]: !showFilters[filter.field],
                      })
                    }
                  >
                    <Icon icon="filter-list" /> {filter.name} (
                    {Object.keys(values).length})
                  </li>
                  {showFilters[filter.field] && (
                    <ul>
                      {Object.keys(values)
                        .sort()
                        .map((k) => (
                          <li
                            key={k}
                            onClick={() => {
                              // Avoid adding same filter twice
                              if (
                                activeFilters.filter(
                                  (a) => a.field == filter.field
                                ).length > 0
                              ) {
                                return;
                              }
                              setActiveFilters([
                                ...activeFilters,
                                {
                                  name: filter.name,
                                  field: filter.field,
                                  value: k,
                                  getValue: filter.getValue,
                                },
                              ]);
                            }}
                          >
                            {k} ({values[k]})
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              );
            })}
          </ul>
        </div>
      </ObjectSelector>
    </>
  );
}
