import {
  add_index,
  handleError,
  normalize,
  normjoin,
} from "../utils/normalize";
import { weektag } from "./MeetingNote/selectors";
import { ObjectTypes } from "./TaskOneRouter";

function reducer(state, action) {
  try {
    const payload = action.payload;
    switch (action.type) {
      case "ERROR": {
        return {
          ...state,
          errors: handleError(state.errors, payload, "taskone"),
        };
      }
      case "CLEAR_ERRORS":
        return { ...state, errors: [] };
      case "NAV":
        return { ...state, nav: action.payload };
      case "CURRENT_WEEK_CODE": {
        const newState = { ...state };
        newState.weeks.currentWeekCode = payload;
      }

      case "FETCH_DATA":
        return state;
      case "CONTACT_CREATE_COMPLETE":
      case "CONTACT_FETCH_COMPLETE":
      case "CONTACT_PERSIST_COMPLETE":
        return {
          ...state,
          persons: normalize(state.persons, action.payload),
        };

      case "FETCH_DATA_COMPLETE":
        return {
          ...state,
          meetings: normalize(state.meetings, action.payload),
        };

      case "REFRESH_DATA":
        return { ...state, refreshKey: state.refreshKey + 1 };

      // == HYPERLINKS ==============================
      case "HYPERLINKS_FETCH_COMPLETE":
      case "HYPERLINK_CREATE_COMPLETE":
      case "HYPERLINK_UPDATE_COMPLETE":
        return {
          ...state,
          hyperlinks: normalize(state.hyperlinks, action.payload),
        };

      // == MEETINGS ================================
      case "MEETING_CREATE_COMPLETE":
      case "MEETING_FETCH_COMPLETE":
      case "MEETING_PERSIST_COMPLETED": {
        return {
          ...state,
          meetings: normalize(state.meetings, action.payload),
        };
      }

      case "MEETING_CHANGE":
        let nState = { ...state };
        const { [payload.id]: meeting } = nState.meetings.byID;
        meeting[payload.field] = payload.value;
        nState.meetings.byID[payload.id] = meeting;
        return nState;

      case "MEETINGNOTE_FETCH_COMPLETE":
      case "MEETINGNOTE_CREATE_COMPLETE":
      case "MEETINGNOTE_PERSIST_COMPLETE": {
        return {
          ...state,
          meetingnotes: normalize(state.meetingnotes, action.payload, [
            {
              name: "meetingID",
              keys: ["meeting_id"],
            },
            {
              name: "weekID",
              keys: ["week_id"],
            },
          ]),
        };
      }

      case "ACTIONITEMSMEETINGNOTES_FETCH_COMPLETE":
      case "ACTIONITEMSMEETINGNOTES_ADD_COMPLETE":
      case "ACTIONITEMSMEETINGNOTES_REMOVE_COMPLETE": {
        return normjoin(state, action.payload, {
          fields: ["actionitem_id", "meetingnote_id"],
          objects: [ObjectTypes.ACTION_ITEMS, ObjectTypes.MEETINGNOTES],
          keys: ["actionItemID", "meetingnoteID"],
        });
      }

      // == ACTION ITEM ========================
      case "ACTIONITEM_PERSIST_COMPLETED": {
        const newState = { ...state };
        newState.actionitems.byID[payload.id] = { ...payload.actionitem };
        return newState;
      }
      case "ACTIONITEM_FETCH_COMPLETE":
      case "ACTIONITEM_CREATE_COMPLETE":
        return {
          ...state,
          actionitems: normalize(state.actionitems, action.payload),
        };

      case "ACTIONITEM_CHANGE":
        let actChState = { ...state };
        const { [payload.id]: actionItem } = actChState.actionitems.byID;
        actionItem[payload.field] = payload.value;
        actChState.actionitems.byID[payload.id] = actionItem;
        return actChState;

      // == ACTIONITEM-CONTACTS =====================

      case "ACTIONITEMSCONTACTS_FETCH_COMPLETE":
      case "ACTIONITEMSCONTACTS_REMOVE":
      case "ACTIONITEMSCONTACTS_ADD": {
        return normjoin(state, action.payload, {
          fields: ["actionitem_id", "person_id"],
          objects: [ObjectTypes.ACTION_ITEMS, ObjectTypes.CONTACTS],
          keys: ["actionItemID", "contactID"],
        });
      }

      // == ACTIONITEM-TRIGGER =====================

      case "ACTIONITEMSTRIGGERS_FETCH_COMPLETE":
      case "ACTIONITEMSTRIGGERS_REMOVE":
      case "ACTIONITEMSTRIGGERS_ADD": {
        return normjoin(state, action.payload, {
          fields: ["actionitem_id", "trigger_id"],
          objects: [ObjectTypes.ACTION_ITEMS, ObjectTypes.TRIGGERS],
          keys: ["actionItemID", "triggerID"],
        });
      }

      // == PROJECT ================================

      case "PROJECT_FETCH_COMPLETE":
      case "PROJECT_CREATE_COMPLETE":
      case "PROJECT_PERSIST_COMPLETE": {
        return {
          ...state,
          projects: normalize(state.projects, action.payload, [
            { name: "parentID", keys: ["parent_id"] },
          ]),
        };
      }

      case "PROJECSMEETINGS_FETCH_COMPLETE":
      case "PROJECTSMEETINGS_REMOVE_COMPLETE":
      case "PROJECTSMEETINGS_ADD_COMPLETE": {
        return normjoin(state, action.payload, {
          fields: ["project_id", "meeting_id"],
          objects: [ObjectTypes.PROJECTS, ObjectTypes.MEETINGS],
          keys: ["projectID", "meetingID"],
        });
      }

      case "ACTIONITEMSPROJECTS_FETCH_COMPLETE":
      case "ACTIONITEMSPROJECTS_ADD_COMPLETE":
      case "ACTIONITEMSPROJECTS_REMOVE_COMPLETE": {
        return normjoin(state, action.payload, {
          fields: ["actionitem_id", "project_id"],
          objects: [ObjectTypes.ACTION_ITEMS, ObjectTypes.PROJECTS],
          keys: ["actionItemID", "projectID"],
        });
      }

      case "PROJECTSLINKS_FETCH_COMPLETE":
      case "PROJECTS_LINKS_ADD_COMPLETE":
      case "PROJECT_LINKS_DELETE_COMPLETE":
        return normjoin(state, action.payload, {
          fields: ["project_id", "hyperlink_id"],
          objects: [ObjectTypes.PROJECTS, ObjectTypes.HYPERLINKS],
          keys: ["projectID", "hyperlinkID"],
        });

      case "MEETING_LINKS_FETCH_COMPLETE":
      case "MEETING_LINKS_DELETE_COMPLETE":
      case "MEETING_LINKS_ADD_COMPLETE":
        return normjoin(state, action.payload, {
          fields: ["meeting_id", "hyperlink_id"],
          objects: [ObjectTypes.MEETINGS, ObjectTypes.HYPERLINKS],
          keys: ["meetingID", "hyperlinkID"],
        });

      // == TRIGGERS ===============================

      case "TRIGGERS_FETCH_COMPLETE": {
        return {
          ...state,
          triggers: normalize(state.triggers, action.payload),
        };
      }

      // == WEEKS ==================================

      case "WEEKS_CREATE_COMPLETED":
      case "WEEKS_FETCH_COMPLETE":
      case "WEEKS_ARCHIVE_COMPLETE":
      case "WEEK_PERSIST_COMPLETE": {
        return {
          ...state,
          weeks: add_index(
            "code",
            (week) => [weektag(week), week.id],
            normalize(state.weeks, action.payload)
          ),
        };
      }

      case "WEEKSPROJECTS_FETCH_COMPLETE":
      case "WEEKSPROJECTS_ADD_COMPLETE":
      case "WEEKSPROJECTS_REMOVE_COMPLETE": {
        return normjoin(state, action.payload, {
          fields: ["week_id", "project_id"],
          objects: [ObjectTypes.WEEKS, ObjectTypes.PROJECTS],
          keys: ["weekID", "projectID"],
        });
      }

      // == ACTIONITEM WEEKS ========================

      case "ACTIONITEMSWEEKS_FETCH_COMPLETE":
      case "ACTIONITEMSWEEKS_REMOVE":
      case "ACTIONITEMSWEEKS_ADD": {
        return normjoin(state, action.payload, {
          fields: ["actionitem_id", "week_id"],
          objects: [ObjectTypes.ACTION_ITEMS, ObjectTypes.WEEKS],
          keys: ["actionItemID", "weekID"],
        });
      }

      // == ACTIONITEM MEETINGS ========================

      case "ACTIONITEMSMEETINGS_FETCH_COMPLETE":
      case "ACTIONITEMSMEETINGS_REMOVE":
      case "ACTIONITEMSMEETINGS_ADD": {
        return normjoin(state, action.payload, {
          fields: ["actionitem_id", "meeting_id"],
          objects: [ObjectTypes.ACTION_ITEMS, ObjectTypes.MEETINGS],
          keys: ["actionItemID", "meetingID"],
        });
      }

      case "LOG_STATE":
        console.log(state);
        return state;

      default:
        throw new Error(
          `[${action.type}] is unknown in TaskOne Reducer. Did you returned a new state ?`
        );
    }
  } catch (err) {
    console.error(err);
    return {
      ...state,
      errors: handleError(state.errors, err, "taskone"),
    };
  }
}

export default reducer;
