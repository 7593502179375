import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToEquipmentID } from "../TorgaRouter";

function buildEquipmentSearchString(e) {
  return [
    "E" + e.id,
    e.name,
    e.catego,
    e.type,
    e.brand,
    e.color,
    e.description,
    e.location,
    e.model,
    e.price,
    e.vendor,
    e.serial,
    e.invoice,
  ].join("|");
}

/**
 *
 * @param {Object} equiments collection from the state (allIDs/byID)
 * @param {Object} query the terms to search for
 * @param {Function} buildSearchString how to build the serach string
 * @param {Function} buildResult how to format the result list
 */
export function searchEquipments(equipments, query) {
  return searchIn(
    ObjectTypes.EQUIPMENTS,
    equipments,
    query,
    buildEquipmentSearchString,
    (equipment) => ({
      id: equipment.id,
      key: "E" + equipment.id,
      name: [equipment.brand, equipment.name].join(" "),
      details: equipment.description,
      link: urlToEquipmentID(equipment.id),
    })
  );
}
