import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchDiaryPosts() {
  return (dispatch) =>
    tget("/api/v2/journal").then((result) => {
      dispatch({ type: "DIARYPOST_FETCH_COMPLETE", payload: result });
    });
}

export function actionCreateNewDiaryPost(onDiaryPostCreated = null) {
  return (dispatch) => {
    tpost(`/api/v2/journal`).then((result) => {
      dispatch({ type: "DIARYPOST_CREATE_COMPLETE", payload: [result] });
      onDiaryPostCreated && onDiaryPostCreated(result.id);
    });
  };
}

export function actionPersistDiaryPost(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/journal/${id}`, {
      [field]: value,
    }).then((result) => {
      dispatch({ type: "DIARYPOST_PERSIST_COMPLETE", payload: [result] });
    });
  };
}
