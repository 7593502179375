import { tdelete, tget, tpost } from "../utils/Utils/Utils";
import { healthcheck } from "./healthcheck";

export function actionFetchOperations() {
  return (dispatch) =>
    tget(`/api/bank/operation`).then(
      (result) => {
        dispatch({ type: "FETCH_OPERATION_COMPLETE", payload: result });
      },
      (err) => {
        dispatch({ type: "ERROR", payload: err.message });
      }
    );
}

export function actionFetchBudget() {
  return (dispatch) =>
    tget(`/api/bank/budget`).then(
      (result) => {
        dispatch({ type: "FETCH_BUDGETS_COMPLETE", payload: result });
      },
      (err) => {
        dispatch({ type: "ERROR", payload: err.message });
      }
    );
}

export function actionFetchAccounts() {
  return (dispatch) =>
    tget(`/api/bank/account`).then(
      (result) => {
        dispatch({ type: "FETCH_ACCOUNTS_COMPLETE", payload: result });
      },
      (err) => {
        dispatch({ type: "ERROR", payload: err.message });
      }
    );
}

export function actionFetchCrawlerImportsLatest() {
  return (dispatch) =>
    tget(`/api/bank/imports/latest`).then((result) =>
      dispatch({ type: "FETCH_CRAWLER_IMPORTS_COMPLETE", payload: result })
    );
}

export function actionBankPreload() {}

export function actionBankCategoSet(id, catego, year, month) {
  return (dispatch) =>
    tpost(`/api/bank/operation/${id}`, {
      catego,
      year,
      month,
    }).then((result) => {
      dispatch({ type: "FETCH_OPERATION_COMPLETE", payload: [result] });
    });
}

export function actionRunHealthcheck(state) {
  return (dispatch) => {
    const lines = healthcheck(state);
    dispatch({ type: "HEALTHCHECK", payload: lines });
  };
}

export function actionFixBudget(
  id,
  account,
  year,
  month,
  catego,
  credit,
  debit
) {
  return (dispatch) => {
    if (!id) {
      tpost(`/api/bank/budget`, {
        compte: account,
        year,
        month,
        catego,
        credit,
        debit,
      }).then(
        (result) => {
          dispatch({ type: "FETCH_BUDGETS_COMPLETE", payload: [result] });
        },
        (err) => dispatch({ type: "ERROR", payload: err.message })
      );
    } else {
      tpost(`/api/bank/budget/${id}`, {
        credit,
        debit,
      }).then(
        (result) => {
          dispatch({ type: "FETCH_BUDGETS_COMPLETE", payload: [result] });
        },
        (err) => dispatch({ type: "ERROR", payload: err.message })
      );
    }
  };
}

export function actionLoadBankBudgetDocuments() {
  return (dispatch) => {
    return tget(`/api/bank/budgetdoc`).then((result) => {
      dispatch({ type: "FETCH_BUDGET_DOCS_COMPLETE", payload: result });
    });
  };
}

export function actionCreateToken() {
  return tget(`/api/bank/sheets/token`);
}

export function actionBankSaveToken(token) {
  return tpost(`/api/bank/sheets/token`, { token: token });
}

export function actionDeleteBudgetDocument(id) {
  return (dispatch) => {
    return tdelete(`/api/bank/sheets/${id}`).then((result) => {
      tpost(`/api/bank/budgetdoc/${id}`, {
        deleted: true,
      }).then((result) =>
        dispatch({ type: "DELETE_BUDGET_DOCUMENT_COMPLETE", payload: [result] })
      );
    });
  };
}

export function actionGetBudgetDocumentInfo(id) {
  return (dispatch) => {
    return tget(`/api/bank/sheets/${id}`).then(
      (result) =>
        dispatch({
          type: "GET_BUDGET_DOCUMENT_INFO_COMPLETE",
          payload: result,
        }),
      (error) => {
        dispatch({ type: "ERROR", payload: error });
      }
    );
  };
}

export function actionCreateNewBudgetDocument() {
  return (dispatch) => {
    return tpost(`/api/bank/sheets`, { title: "Test Document 2" }).then(
      (result) => {
        tpost(`/api/bank/budgetdoc`, {
          documentID: result.data.spreadsheetId,
        }).then((r2) => {
          dispatch({ type: "CREATE_BUDGET_DOCUMENT_COMPLETE", payload: [r2] });
        });
      }
    );
  };
}

export function actionWriteToBudgetDocument(id) {
  return (dispatch) => {
    tpost(`/api/bank/sheets/${id}`);
  };
}

export function actionImportFromBudgetDocument(id) {
  return (dispatch) => {
    tget(`/api/bank/sheets/import/${id}`).then((result) =>
      dispatch({ type: "BUDGET_DOCUMENT_IMPORT_COMPLETE", payload: result })
    );
  };
}

export function actionPersistBudgetChanges(diffs) {
  return (dispatch) => {
    tpost(`/api/bank/budget/batch`, { diffs: diffs }).then((result) => {
      dispatch({ type: "BUDGET_UPDATE_COMPLETED", payload: result.updates });
    });
  };
}
