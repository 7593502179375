import React from "react";
import { useNavigate } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToActionItemID } from "../TaskOneRouter";
import ActionItem from "./ActionItem";
import { actionActionItemCreate } from "./actions";
import { searchActionItem } from "./search";

export default function ActionItems() {
  const { state, dispatchAction } = useTaskOneContext();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();

  const actionitems =
    search && search != ""
      ? searchActionItem(state.actionitems, { text: search })
      : state.actionitems.allIDs
          .map((id) => state.actionitems.byID[id])
          .map((object) => ({
            id: object.id,
            name: object.name,
          }));

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={actionitems}
          objectType={ObjectTypes.ACTION_ITEMS}
          urlToObject={urlToActionItemID}
          onChange={setSearch}
          onCreate={() => {
            dispatchAction(
              actionActionItemCreate(
                "New Action Item",
                "FREE",
                {},
                (createActionItemID) =>
                  navigate(urlToActionItemID(createActionItemID)),
                (createdActionItemID) => {
                  navigate(urlToActionItemID(createdActionItemID));
                }
              )
            );
          }}
        />
      </List>

      <Page>
        <ActionItem />
      </Page>
    </Browser>
  );
}
