import React from "react";

import "./calendar.css";

import InfiniteScroll from "./InfiniteScroll";
import TaskList from "./TaskList";
import { Page } from "../../utils/Layout/Layout";
import CalendarElement from "./CalendarElement";

export default function Calendar() {
  const [days, setDays] = React.useState([]);

  React.useEffect(() => {}, []);

  function loadMore() {
    //console.log("loadMore");
    const last = days.slice(-1)[0] || new Date();
    setDays([...days, new Date(last.setDate(last.getDate() + 1))]);
  }

  return (
    <Page>
      <div className="calendar__content">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={true}
          loader={<div key="{0}">Loading ...</div>}
          useWindow={true}
        >
          <h4>Today</h4>
          <CalendarElement day={new Date()} />
          <h4>Activities</h4>
          <TaskList />
          <h4>Later</h4>
          {days.map((day, i) => (
            <CalendarElement key={i} day={day} />
          ))}
        </InfiniteScroll>
      </div>
    </Page>
  );
}
