import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToUserID } from "../MainRouter";

function buildUserSearchString(e) {
  console.log(e);
  return ["U" + e.id, e.name, e.email].join("|");
}

export function searchUsers(users, query) {
  return searchIn(
    ObjectTypes.USERS,
    users,
    query,
    buildUserSearchString,
    (user) => {
      return {
        id: user.id,
        key: "U" + user.id,
        name: user.name,
        details: user.email,
        link: urlToUserID(user.id),
      };
    }
  );
}
