import { Button, ButtonGroup, EditableText } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useParams } from "react-router";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { actionActionItemCreate } from "../ActionItem/actions";
import { weektag } from "../MeetingNote/selectors";
import { actionCreateProject } from "../Project/actions";
import ProjectListTree from "../Project/ProjectListTree";
import { searchProjects } from "../Project/search";
import { allProjectsListEntries } from "../Project/selectors";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToProjectID } from "../TaskOneRouter";
import {
  actionAddProjectToWeek,
  actionArchiveWeek,
  actionPersistWeek,
} from "./actions";
import Checklist from "./Checklist";
import { actionItemForWeekID } from "./selectors";

export default function Week() {
  const [state, setState] = React.useState({
    addProject: false,
    removeProject: false,
  });
  const [searchProject, setSearchProject] = React.useState("");
  const [localState, setLocalState] = React.useState(null);
  const { state: taskOneState, dispatchAction } = useTaskOneContext();
  const { id } = useParams();

  const week = taskOneState.weeks.byID[id];

  React.useEffect(() => {
    setLocalState(week);
  }, [week]);

  if (!localState) {
    return <div>Loading ...</div>;
  }

  const actionItems = actionItemForWeekID(taskOneState, week.id);

  function handleChange(field, value) {
    setLocalState({ ...localState, [field]: value });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionPersistWeek(id, field, value));
  }

  return (
    <div>
      <h1>
        {weektag(week)} {week.archived && "(Archived)"}
      </h1>
      <ButtonGroup>
        <Button>Import from&hellip;</Button>
        <Button
          icon={IconNames.PLUS}
          active={state?.addProject}
          onClick={() =>
            setState({
              ...state,
              addProject: !state.addProject,
              removeProject: false,
            })
          }
        >
          Add Project
        </Button>
        <Button
          icon={IconNames.MINUS}
          active={state?.removeProject}
          onClick={() =>
            setState({
              ...state,
              removeProject: !state.removeProject,
              addProject: false,
            })
          }
        >
          Remove Project
        </Button>
        <Button
          onClick={() => {
            dispatchAction(actionArchiveWeek(week.id, !week.archived));
          }}
          active={week.archived}
        >
          Archive
        </Button>
      </ButtonGroup>

      <h3>Quick notes</h3>
      <EditableText
        multiline
        minLines={3}
        value={localState.content}
        onChange={(e) => handleChange("content", e)}
        onConfirm={(e) => handleConfirm("content", e)}
      />

      {state.addProject && (
        <div>
          <ObjectSelector
            objects={
              searchProject && searchProject != ""
                ? searchProjects(taskOneState.projects, { text: searchProject })
                : allProjectsListEntries(taskOneState)
            }
            objectType={ObjectTypes.PROJECTS}
            urlToObject={urlToProjectID}
            onChange={setSearchProject}
            onCreate={() => {
              dispatchAction(
                actionCreateProject("New Project", "parent_id", {}, () => {})
              );
            }}
            onSelect={(projectID) => {
              setState({ ...state, addProject: false });
              dispatchAction(actionAddProjectToWeek(projectID, week.id));
            }}
          />
        </div>
      )}

      <ProjectListTree context={{ week_id: id }} />

      <div>
        <Checklist
          name={"Start of week checklist"}
          items={actionItems}
          type={"PRE_WEEK"}
          onItemAdd={(name, type) => {
            dispatchAction(
              actionActionItemCreate(name, type, {
                week_id: week.id,
              })
            );
          }}
        />

        <Checklist
          name={"End of week checklist"}
          items={actionItems}
          type={"POST_WEEK"}
          onItemAdd={(name, type) => {
            dispatchAction(
              actionActionItemCreate(name, type, {
                week_id: week.id,
              })
            );
          }}
        />
      </div>
    </div>
  );
}
