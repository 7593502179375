import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToMeetingNoteID } from "../TaskOneRouter";
import { weektag } from "./selectors";

export function searchMeetingNotes(state, query) {
  function buildSearchString(e) {
    return [
      "N" + e.id,
      e.name,
      e.agenda,
      e.content,
      e.decisional,
      state.meetings.byID[e.meeting_id]?.name,
      weektag(state.weeks.byID[e.week_id]),
    ].join("|");
  }
  return searchIn(
    ObjectTypes.MEETINGNOTES,
    state.meetingnotes,
    query,
    buildSearchString,
    (meetingNote) => {
      function meetingNoteName(meetingNote) {
        return (
          state.meetings.byID[meetingNote.meeting_id]?.name +
          " " +
          weektag(state.weeks.byID[meetingNote.week_id])
        );
      }

      return {
        id: meetingNote.id,
        key: "N" + meetingNote.id,
        name: meetingNoteName(meetingNote),
        details: meetingNote.content?.substring(0, 100),
        link: urlToMeetingNoteID(meetingNote.id),
      };
    }
  );
}
