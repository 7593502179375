import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToOperationID } from "../BankRouter";

function buildOperationSearchString(operation) {
  return operation.libelle;
}

export function searchBankOperations(operations, query) {
  return searchIn(
    ObjectTypes.OPERATIONS,
    operations,
    query,
    buildOperationSearchString,
    (operation) => ({
      id: operation.id,
      key: "O" + operation.id,
      name: operation.libelle,
      details: [
        operation.compte,
        operation.credit - operation.debit,
        " EUR ",
        operation.date_operation,
      ].join(" "),
      link: urlToOperationID(operation.id),
    })
  );
}
