import { EditableText, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { Page } from "../../utils/Layout/Layout";
import StatusBar from "../../utils/StatusBar/StatusBar";
import ContactsSelector from "../Contact/ContactsSelector";
import { TaskOneContext } from "../TaskOneContext";
import TriggersSelector from "../Trigger/TriggersSelectors";
import {
  actionAddTriggerToActionItem,
  actionPersistActionItem,
  actionRemoveTriggerFromActionItem,
} from "./actions";

export default function ActionItem() {
  const { state, dispatch, dispatchAction } = useContext(TaskOneContext);
  let { id } = useParams();
  const { [id]: actionItem } = state.actionitems.byID;

  const { [id]: triggers } = state.triggers.idsBy.actionItemID;

  if (!id) {
    return <NonIdealState icon={IconNames.ADD_TO_ARTIFACT}></NonIdealState>;
  }

  if (!actionItem) {
    return "Loading...";
  }

  function handleChange(field, value) {
    dispatch({ type: "ACTIONITEM_CHANGE", payload: { id, field, value } });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionPersistActionItem(id, field, value));
  }

  return (
    <Page>
      <h1>
        T{actionItem.id} {"  "}
        <EditableText
          placeholder="Action name"
          value={actionItem.name}
          onChange={(e) => handleChange("name", e)}
          onConfirm={(e) => handleConfirm("name", e)}
          selectAllOnFocus={true}
        />
      </h1>
      <StatusBar
        status={actionItem.status}
        onChange={(status) => handleConfirm("status", status)}
      />
      <hr />

      <table>
        <tbody>
          <tr>
            <td>Trigger</td>
            <td>
              <TriggersSelector
                value={triggers}
                addTrigger={(trigger_id) => {
                  dispatchAction(
                    actionAddTriggerToActionItem(trigger_id, actionItem.id)
                  );
                }}
                removeTrigger={(trigger_id) => {
                  dispatchAction(
                    actionRemoveTriggerFromActionItem(trigger_id, actionItem.id)
                  );
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Contacts</td>
            <td>
              <ContactsSelector />
            </td>
          </tr>
          <tr>
            <td>Projects</td>
          </tr>
          <tr>
            <td>Meetings</td>
            <td>{state.meetings.idsBy.actionItemID[id]}</td>
          </tr>
          <tr>
            <td>Meetings Notes</td>
            <td>{state.meetingnotes.idsBy.actionItemID[id]}</td>
          </tr>
          <tr>
            <td>Weeks</td>
            <td>{state.weeks.idsBy.actionItemID[id]}</td>
          </tr>
        </tbody>
      </table>

      <hr />

      <div>
        <EditableText
          multiline
          minLines={10}
          value={actionItem.content}
          onChange={(e) => handleChange("content", e)}
          onConfirm={(e) => handleConfirm("content", e)}
        />
      </div>
    </Page>
  );
}
