import { Button, Icon, Navbar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import { DisplayModes } from "../MainRouter";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Toolbar.css";

let i = 0;

export default function Toolbar({ displayMode, drawerClickHandler }) {
  const styles = ["toolbar"];

  if (displayMode === DisplayModes.COMPACT) {
    styles.push("compact");
  }

  return (
    <header className={styles.join(" ")}>
      <nav className="toolbar__navigation">
        {displayMode === DisplayModes.MOBILE && (
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={drawerClickHandler} />
          </div>
        )}
        {displayMode !== DisplayModes.MOBILE && (
          <div>
            <Icon
              icon={IconNames.LAYERS}
              iconSize={22}
              style={{ color: "white" }} //#db8a2c
            />
          </div>
        )}

        <div className="toolbar__logo">
          <a href="/">TORGA</a>
        </div>
        <div className="spacer" />
        <div className="toolbar_navigation-items">
          <Navbar.Group>
            <NavLink to="/search">
              <Button
                minimal
                icon={<Icon icon="search" color="white" />}
                large
              />
            </NavLink>
          </Navbar.Group>
        </div>
      </nav>
    </header>
  );
}
