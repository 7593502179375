// Find inconsistencies between aggregated operations and budget

import React from "react";
import { Page } from "../../utils/Layout/Layout";
import { actionFixBudget } from "../actions";
import { useBankContext } from "../BankContext";
import Check from "./Check";

export default function Checks() {
  const { state, dispatchAction } = useBankContext();

  const { checks } = state;

  return (
    <Page>
      {checks.allIDs
        .map((id) => checks.byID[id])
        .map((line) => {
          return (
            <Check
              key={line.id}
              line={line}
              onFixBudget={(
                id,
                account,
                year,
                month,
                catego,
                credit,
                debit
              ) => {
                dispatchAction(
                  actionFixBudget(
                    id,
                    account,
                    year,
                    month,
                    catego,
                    credit,
                    debit
                  )
                );
              }}
            />
          );
        })}
    </Page>
  );
}
