import { handleError, normalize } from "../utils/normalize";

function reducer(state, action) {
  try {
    const payload = action.payload;
    switch (action.type) {
      case "ERROR": {
        return {
          ...state,
          errors: handleError(state.errors, payload, "bank"),
        };
      }
      case "CLEAR_ERRORS":
        return { ...state, errors: [] };
      case "REFRESH_DATA":
        return { ...state, refreshKey: state.refreshKey + 1 };
      case "FETCH_OPERATION_COMPLETE":
        const operations = action.payload.map((o) => {
          return {
            ...o,
            date_operation: Date.parse(o.date_operation),
            date_valeur: Date.parse(o.date_valeur),
            created_on: Date.parse(o.created_on),
            last_modified: Date.parse(o.last_modified),
          };
        });
        return {
          ...state,
          operations: normalize(state.operations, operations, [
            {
              name: "account",
              keys: ["compte"],
            },
            {
              name: "year",
              keys: ["year"],
            },
            {
              name: "treeIndex",
              keys: ["compte", "year", "month", "catego"],
            },
          ]),
        };

      case "FETCH_ACCOUNTS_COMPLETE":
        return {
          ...state,
          accounts: normalize(state.accounts, action.payload, [
            {
              name: "account",
              keys: ["nom"],
            },
            {
              name: "type",
              keys: ["type"],
            },
          ]),
        };

      case "BUDGET_UPDATE_COMPLETED":
      case "FETCH_BUDGETS_COMPLETE":
        return {
          ...state,
          budgets: normalize(state.budgets, action.payload, [
            {
              name: "account",
              keys: ["compte"],
            },
            {
              name: "treeIndex",
              keys: ["compte", "year", "month", "catego"],
            },
          ]),
        };

      case "DELETE_BUDGET_DOCUMENT_COMPLETE":
      case "FETCH_BUDGET_DOCS_COMPLETE":
      case "CREATE_BUDGET_DOCUMENT_COMPLETE": {
        return {
          ...state,
          budgetDocuments: normalize(state.budgetDocuments, action.payload),
        };
      }
      case "FETCH_CRAWLER_IMPORTS_COMPLETE":
        return { ...state, crawler: { imports: { latests: action.payload } } };

      case "CRYPTO_FETCH_COMPLETE":
        return {
          ...state,
          cryptos: normalize(state.cryptos, action.payload, [
            { name: "currency", keys: ["currency"] },
          ]),
        };

      case "CRYPTO_FETCH_STATS_COMPLETE":
        return {
          ...state,
          cryptostats: normalize(state.cryptostats, action.payload),
        };

      case "HEALTHCHECK":
        return {
          ...state,
          checks: normalize({ byID: {}, allIDs: [] }, action.payload),
        };

      case "LOG_STATE":
        console.info(state);
        return state;

      default:
    }
  } catch (err) {
    console.error(err);
    return {
      ...state,
      errors: handleError(state.errors, err, "bank"),
    };
  }
}

export default reducer;
