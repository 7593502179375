import React, { useContext } from "react";
import styled from "styled-components";
import { TaskOneContext } from "../TaskOneContext";

const ItemDiv = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;

export default function TriggerSelector(props) {
  const { state } = useContext(TaskOneContext);

  return (
    <div>
      {state.triggers.allIDs.map((id) => {
        const trigger = state.triggers.byID[id];
        return (
          <ItemDiv
            key={id}
            onClick={(e) => props.onSelect(id)}
            style={{ cursor: "pointer" }}
          >
            {id}. {trigger.name}
          </ItemDiv>
        );
      })}
    </div>
  );
}
