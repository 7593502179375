import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToTriggerID } from "../TaskOneRouter";

export function searchTriggers(triggers, query) {
  function buildSearchString(e) {
    return ["T" + e.id, e.name].join("|");
  }
  return searchIn(
    ObjectTypes.TRIGGERS,
    triggers,
    query,
    buildSearchString,
    (trigger) => {
      return {
        id: trigger.id,
        key: "T" + trigger.id,
        name: trigger.name,
        link: urlToTriggerID(trigger.id),
      };
    }
  );
}
