import { searchIn } from "../../utils/Search/SearchUtils";
import { dateFromYearWeek } from "../../utils/weekUtils";
import { weektag } from "../MeetingNote/selectors";
import { ObjectTypes, urlToWeekID } from "../TaskOneRouter";

export function searchWeeks(weeks, query) {
  function buildSearchString(e) {
    return ["W" + e.id, weektag(e)].join("|");
  }
  return searchIn(
    ObjectTypes.WEEKS,
    weeks,
    query,
    buildSearchString,
    (week) => {
      return {
        id: week.id,
        key: "W" + week.id,
        name: weektag(week),
        details:
          " - " +
          dateFromYearWeek(week?.year, week.week)?.toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
        link: urlToWeekID(week.id),
      };
    }
  );
}
