import React from "react";

/**
 * Compare operations to budget
 */
export default function Calc() {
  return (
    <div>
      <h1>Calc</h1>
    </div>
  );
}
