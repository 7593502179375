import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { dateFromYearWeek } from "../../utils/weekUtils";
import { weektag } from "../MeetingNote/selectors";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToWeekID } from "../TaskOneRouter";
import { actionCreateNewWeek } from "./actions";
import { searchWeeks } from "./search";
import { selectCurrentWeekID } from "./selectors";
import Week from "./Week";

export default function Weeks() {
  const { state, dispatchAction } = useTaskOneContext();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const weeks =
    search && search != ""
      ? searchWeeks(state.weeks, { text: search })
      : state.weeks.allIDs
          .map((id) => state.weeks.byID[id])
          .sort((a, b) => {
            return b.year * 52 - a.year * 52 + b.week - a.week;
          })
          .map((object) => ({
            id: object.id,
            name: weektag(object),
            details:
              " - " +
              dateFromYearWeek(object.year, object.week)?.toLocaleDateString(
                "fr-FR",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              ),
            deleted: object.archived,
          }));

  React.useEffect(() => {
    if (!id) {
      const currentWeekID = selectCurrentWeekID(state);
      if (currentWeekID) {
        navigate(urlToWeekID(currentWeekID));
      } else {
        // Ask to create new week ?
      }
    }
  });

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={weeks}
          objectType={ObjectTypes.WEEKS}
          urlToObject={urlToWeekID}
          onChange={setSearch}
          onCreate={() => {
            dispatchAction(
              actionCreateNewWeek((createdWeekID) => {
                navigate(urlToWeekID(createdWeekID));
              })
            );
          }}
        />
      </List>

      <Page>
        <Week />
      </Page>
    </Browser>
  );
}
