import { Tag } from "@blueprintjs/core";
import React from "react";
import ListComponent from "../../utils/ListComponent/ListComponent";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import {
  actionAddMeetingToProject,
  actionRemoveMeetingToProject,
} from "../Project/actions";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToMeetingID } from "../TaskOneRouter";
import { selectOpenActionItemsForMeeting } from "../Week/selectors";
import { actionCreateMeeting } from "./actions";
import { searchMeetings } from "./search";
import { allMeetingsListItem } from "./selectors";

export default function MeetingsComponent({ ids, onMeetingAdd, context }) {
  const { state, dispatchAction } = useTaskOneContext();
  const [searchMeeting, setSearchMeeting] = React.useState("");

  if (!ids) {
    ids = [];
  }

  const meetings = ids.map((meetingID) => state.meetings.byID[meetingID]);

  const selector = (object, onComplete) => {
    return (
      <ObjectSelector
        objects={
          searchMeeting && searchMeeting != ""
            ? searchMeetings(state.meetings, { text: searchMeeting })
            : allMeetingsListItem(state)
        }
        objectType={ObjectTypes.MEETINGS}
        urlToObject={urlToMeetingID}
        onChange={setSearchMeeting}
        onCreate={() => {
          dispatchAction(
            actionCreateMeeting((createdMeetingID) => {
              dispatchAction(
                actionAddMeetingToProject(createdMeetingID, context.project_id)
              );
            })
          );
        }}
        onSelect={(id) => {
          dispatchAction(actionAddMeetingToProject(id, context.project_id));
        }}
      />
    );
  };

  return (
    <ListComponent
      add
      createEditorComponent={selector}
      onCreate={true}
      objectType={ObjectTypes.MEETINGS}
      values={meetings}
      render={(meeting) => {
        const openActionItemCount = selectOpenActionItemsForMeeting(
          state,
          meeting.id
        );

        return [
          meeting.name,
          openActionItemCount > 0 ? (
            <Tag round>{openActionItemCount} </Tag>
          ) : null,
        ];
      }}
      badge={(meeting) => {}}
      onRemove={(meetingID) => {
        dispatchAction(
          actionRemoveMeetingToProject(meetingID, context.project_id)
        );
      }}
    />
  );
}
