import React from "react";
import { useNavigate } from 'react-router';
import { useHotkeys } from "../utils/Hotkeys";
import { Hotkey, Hotkeys } from "@blueprintjs/core";
import { useAppContext } from "./AppContext";

export default function HotKeys() {
    const navigate = useNavigate();
    const { state, dispatch } = useAppContext();
    

  useHotkeys(
      <Hotkeys>
        <Hotkey
          allowInInput
          label="Console"
          combo="cmd+shift+k"
          global
          onKeyDown={() => {
            dispatch({
              type: "CONSOLE",
              payload: { display: !state.console.display },
            });
          }}
        />
        <Hotkey
          allowInInput
          label="Search"
          combo="cmd+k"
          global
          onKeyDown={(e) => {
            e.preventDefault();
            navigate("/search");
          }}
        />
      </Hotkeys>
    );
}