import React from "react";
import { useNavigate } from "react-router";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToContactID } from "../TaskOneRouter";
import { actionCreateContact } from "./actions";
import Contact from "./Contact";
import { searchContacts } from "./search";

export default function Contacts() {
  const { state, dispatchAction } = useTaskOneContext();
  const [search, setSearch] = React.useState();
  const navigate = useNavigate();

  const persons =
    search && search != ""
      ? searchContacts(state.persons, { text: search })
      : state.persons.allIDs
          .map((id) => state.persons.byID[id])
          .map((contact) => ({
            id: contact.id,
            name: contact.name,
          }));

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={persons}
          objectType={ObjectTypes.CONTACTS}
          urlToObject={urlToContactID}
          onChange={setSearch}
          onCreate={() => {
            dispatchAction(
              actionCreateContact(null, (createdContactID) => {
                navigate(urlToContactID(createdContactID));
              })
            );
          }}
        />
      </List>
      <Page>
        <Contact />
      </Page>
    </Browser>
  );
}
