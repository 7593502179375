import React from "react";
import { Link } from "react-router-dom";
import { useTorgaContext } from "../TorgaContext";
import { urlToActivityID } from "../TorgaRouter";

export default function TaskList() {
  const {
    state: { activities: activities },
  } = useTorgaContext();

  const filtered = activities.allIDs
    .map((id) => activities.byID[id])
    .filter((activity) => ["In Progress", "To Do"].includes(activity.status));

  return (
    <div>
      <h4>In Progress</h4>
      {filtered
        .filter((a) => a.status == "In Progress")
        .map((a) => (
          <div key={a.id}>
            <Link to={urlToActivityID(a.id)}>A{a.id}</Link> - {a.name} - [
            {a.type}]
          </div>
        ))}

      <h4>To Do</h4>
      {filtered
        .filter((a) => a.status == "To Do")
        .map((a) => (
          <div key={a.id}>
            <Link to={urlToActivityID(a.id)}>A{a.id}</Link> - {a.name} - [
            {a.type}]
          </div>
        ))}
    </div>
  );
}
