import "./label.css";
import React from "react";

const Label = (props) => {
  let id = props.id;
  if (props.id < 10) {
    id = "0" + id;
  }
  if (props.id < 100) {
    id = "0" + id;
  }

  return (
    <div {...props.style} className="label" onClick={props.onClick || null}>
      {props.prefix}
      {id}
    </div>
  );
};

export default Label;
