import React from "react";
import {
  HotkeyScope,
  HotkeysEvents,
} from "@blueprintjs/core/lib/esm/components/hotkeys/hotkeysEvents";

export function useHotkeys(hotkeys) {
  const localHotkeysEvents = React.useMemo(
    () => new HotkeysEvents(HotkeyScope.LOCAL),
    []
  );
  const globalHotkeysEvents = React.useMemo(
    () => new HotkeysEvents(HotkeyScope.GLOBAL),
    []
  );
  React.useEffect(() => {
    document.addEventListener("keydown", globalHotkeysEvents.handleKeyDown);
    document.addEventListener("keyup", globalHotkeysEvents.handleKeyUp);
    return () => {
      document.removeEventListener(
        "keydown",
        globalHotkeysEvents.handleKeyDown
      );
      document.removeEventListener("keyup", globalHotkeysEvents.handleKeyUp);

      globalHotkeysEvents.clear();
    };
  }, []);

  localHotkeysEvents.setHotkeys(hotkeys.props);
  globalHotkeysEvents.setHotkeys(hotkeys.props);

  const tabIndex =
    hotkeys.props.tabIndex === undefined ? 0 : hotkeys.props.tabIndex;

  const onKeyDown = React.useCallback(
    (e) => {
      localHotkeysEvents.handleKeyDown(e.nativeEvent);
    },
    [localHotkeysEvents]
  );

  const onKeyUp = React.useCallback(
    (e) => {
      localHotkeysEvents.handleKeyUp(e.nativeEvent);
    },
    [localHotkeysEvents]
  );
  return { tabIndex, onKeyDown, onKeyUp };
}
