import { Button } from "@blueprintjs/core";
import React from "react";
import { useBankContext } from "../BankContext";

export default function Budget() {
  const { state } = useBankContext();

  const today = new Date();

  const [currTime, setTime] = React.useState({
    year: today.getFullYear(),
    month: today.getMonth(),
  });

  const comptes = state.accounts.allIDs.map(
    (accountID) => state.accounts.byID[accountID]
  );
  //  .filter((account) => account.archived == false);

  const data = comptes.map((compte) => {
    const budget =
      state.budgets.idsBy.treeIndex[compte.nom]?.[currTime.year]?.[
        currTime.month
      ];

    return { name: compte.name, budget };
  });

  return (
    <div>
      <h1>Budget</h1>
      For Year {currTime.year} and Month {currTime.month}
      <Button
        onClick={() => {
          let year = currTime.year,
            month = currTime.month;
          if (month == 1) {
            year--;
            month = 12;
          } else {
            month--;
          }
          setTime({ year, month });
        }}
      >
        Previous
      </Button>
      <Button
        onClick={() => {
          let year = currTime.year,
            month = currTime.month;
          if (month == 12) {
            year++;
            month = 1;
          } else {
            month++;
          }
          setTime({ year, month });
        }}
      >
        Next
      </Button>
      {data.map((account, accountIdx) => (
        <div key={accountIdx}>
          <h2>{account.name}</h2>
          {account.budget &&
            Object.keys(account.budget).map((k, id) => {
              const budgetObjectID = account.budget[k];
              const budgetObject = state.budgets.byID[budgetObjectID];
              return (
                <div key={id}>
                  {k} -{" "}
                  {JSON.stringify({
                    credit: budgetObject.credit,
                    debit: budgetObject.debit,
                  })}
                </div>
              );
            })}
        </div>
      ))}
    </div>
  );
}
