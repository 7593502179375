import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import {
  actionDeleteBudgetDocument,
  actionGetBudgetDocumentInfo,
  actionImportFromBudgetDocument,
  actionPersistBudgetChanges,
  actionWriteToBudgetDocument,
} from "../actions";
import { useBankContext } from "../BankContext";

export default function BudgetDocument({ id }) {
  const [data, setData] = React.useState({ loaded: false, missing: false });
  const [diff, setDiff] = React.useState([]);
  const { state, dispatchAction } = useBankContext();

  const localDispatch = (action) => {
    switch (action.type) {
      case "BUDGET_DOCUMENT_IMPORT_COMPLETE": {
        setDiff(action.payload);
        break;
      }
    }
  };

  const localDispatcher = (action) => {
    action(localDispatch);
  };

  React.useEffect(() => {
    const getBudgetDocInfo = actionGetBudgetDocumentInfo(id);
    getBudgetDocInfo((action) => {
      switch (action.type) {
        case "ERROR":
          setData({ loaded: true, missing: true });
          return;
        case "GET_BUDGET_DOCUMENT_INFO_COMPLETE":
          console.log("action", action);
          setData({ loaded: true, missing: false, ...action.payload.data });
          return;
      }
    });
  }, []);

  return (
    <div>
      Doc {id}
      <Button
        icon={IconNames.TRASH}
        onClick={() => {
          dispatchAction(actionDeleteBudgetDocument(id));
        }}
      />
      <div>
        <Button onClick={() => dispatchAction(actionWriteToBudgetDocument(id))}>
          Write to doc
        </Button>{" "}
        <Button
          onClick={() => localDispatcher(actionImportFromBudgetDocument(id))}
        >
          Import
        </Button>
      </div>
      <pre>
        {JSON.stringify(
          {
            id: data.id,
            version: data.version,
            createdTime: data.createdTime,
            modifiedTime: data.modifiedTime,
          },
          null,
          2
        )}
      </pre>
      {data.webViewLink && (
        <a href={data.webViewLink} target="_blank">
          {data.webViewLink}
        </a>
      )}
      <div>
        <h3>Diffs</h3>
        {diff.map((o, id) => {
          return <div key={id}>{JSON.stringify(o)}</div>;
        })}
      </div>
      <Button onClick={() => dispatchAction(actionPersistBudgetChanges(diff))}>
        Update Budget
      </Button>
      {/*
      <div>
        <iframe
          style={{ width: "100%", height: "400px" }}
          src={`https://docs.google.com/spreadsheets/d/${data.id}/edit?embedded=true&widget=true`}
        />
      </div>
       */}
    </div>
  );
}
