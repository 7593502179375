import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchEquipments() {
  return (dispatch) =>
    tget("/api/v2/equipment").then((result) => {
      dispatch({ type: "EQUIPMENT_FETCH_COMPLETE", payload: result });
    });
}

export function actionEquipmentPersist(id, field, value) {
  return (dispatch) =>
    tpost(`/api/v2/equipment/${id}/${field}`, { [field]: value }).then(
      (result) => {
        dispatch({ type: "EQUIPMENT_PERSIST_COMPLETE", payload: [result] });
      }
    );
}
export function actionEquipmentDelete() {
  throw new Error("Incomplete impl");
  /*
  return (dispatch) =>
    tpost("/api/v2/equipment", {}).then((result) => {
      dispatch({ type: "EQUIPMENT_DELETE_COMPLETE", payload: result });
    });*/
}
export function actionEquipmentAdd(parentID, name) {
  return (dispatch) =>
    tpost("/api/v2/equipment", { name, parentID }).then((result) => {
      dispatch({ type: "EQUIPMENT_ADD_COMPLETE", payload: result });
    });
}

export function actionEquipmentNew(onSelect) {
  return (dispatch) => {
    tpost("/api/v2/equipment").then((result) => {
      dispatch({ type: "EQUIPMENT_FETCH_COMPLETE", payload: [result] });
      onSelect(result.id);
    });
  };
}
