import React from "react";
import { dateTimeFormatter } from "../Utils/Utils";

export function UTCtoLocaleDate(dateStr) {
  const duo = (val) => {
    return ("0" + val).slice(-2);
  };

  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return (
    year +
    "-" +
    duo(month + 1) +
    "-" +
    duo(day) +
    "T" +
    duo(hour) +
    ":" +
    duo(minute)
  );
}

export function localToUTCDate(dateStr) {
  return new Date(dateStr).toISOString();
}

export function ObjectFieldDateTimeEditable({
  field,
  value,
  handleConfirm = (field, value) => {
    console.error("handleConfirm not implemented");
  },
}) {
  const ref = React.useRef(value);
  const timer = React.useRef();

  // DateStr is using local time-zone
  const [dateStr, setDateStr] = React.useState(UTCtoLocaleDate(value));

  React.useEffect(() => {
    if (value) {
      //setDateStr(UTCtoLocaleDate(value));
      ref.current.value = UTCtoLocaleDate(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (UTCtoLocaleDate(dateStr) != UTCtoLocaleDate(value)) {
        const newValue = localToUTCDate(dateStr);
        handleConfirm(field, newValue);
      }
    }, 1000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [dateStr]);

  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>
        <input
          type="datetime-local"
          ref={ref}
          onChange={(e) => setDateStr(e.target.value)}
        />
      </div>
    </div>
  );
}

export function ObjectFieldDateEditable({
  field,
  value,
  handleConfirm = (field, value) => {
    console.error("handleConfirm not implemented");
  },
}) {
  const ref = React.useRef(value);
  const timer = React.useRef();

  // DateStr is using local time-zone
  const [dateStr, setDateStr] = React.useState(
    UTCtoLocaleDate(value).substring(0, 10)
  );

  React.useEffect(() => {
    if (value) {
      ref.current.value = UTCtoLocaleDate(value).substring(0, 10);
    }
  }, [value]);

  React.useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (
        UTCtoLocaleDate(dateStr + "T00:00").substring(0, 10) !=
        UTCtoLocaleDate(value).substring(0, 10)
      ) {
        const newValue = localToUTCDate(dateStr+ "T00:00");
        handleConfirm(field, newValue);
      }
    }, 1000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [dateStr]);

  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>
        <input
          type="date"
          ref={ref}
          onChange={(e) => setDateStr(e.target.value)}
        />
      </div>
    </div>
  );
}

export function ObjectFieldDateTime({ field, value }) {
  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>{dateTimeFormatter.format(new Date(value))}</div>
    </div>
  );
}
