import React from "react";
import { tpost } from "../../utils/Utils/Utils";
import "./EquipmentProperty.css";

export default class EquipmentProperty extends React.Component {
  constructor({ placeholder, value, onConfirm }) {
    super();
    this.state = {
      placeholder,
      value: value || "",
      suggestions: [],
      suggestionIndex: 0,
    };
    this.onConfirm = onConfirm; //.bind(this);
    this.suggest = this.suggest.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelected = (index) => {
      const equipmentPropertyValue =
        this.state.suggestions[index][this.props.property];
      this.setState(
        { value: equipmentPropertyValue, suggestionIndex: 0, suggestions: [] },
        () => {}
      );
    };
    this.onMouseOver = (index) => {
      this.setState({ suggestionIndex: index });
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value) {
      this.setState({
        value: this.props.value || "",
        suggestions: [],
        suggestionIndex: 0,
      });
      this.onConfirm = this.props.onConfirm;
    }
  }

  suggest() {
    tpost(`/api/v2/equipment/suggest/${this.props.property}`, {
      field: this.props.property,
      input: this.state.value,
    }).then((s) => {
      this.setState({ suggestions: s, suggestionIndex: 0 });
    });
  }

  renderItem(index, suggestion, onSelected, onMouseOver) {
    function handleSuggestionClick() {
      onSelected(index);
    }

    function handleMouseOver() {
      onMouseOver(index);
    }

    return (
      <li
        className={this.state.suggestionIndex == index ? "ep__selected" : ""}
        key={index}
        onMouseDown={handleSuggestionClick}
        onMouseOver={handleMouseOver}
      >
        {suggestion[this.props.property]} ({suggestion.count})
      </li>
    );
  }

  onKeyDown(e) {
    const target = e.target;
    if (e.key == "ArrowDown") {
      e.preventDefault();
      this.setState({
        suggestionIndex: Math.min(
          this.state.suggestions.length - 1,
          this.state.suggestionIndex + 1
        ),
      });
    }
    if (e.key == "ArrowUp") {
      e.preventDefault();
      this.setState({
        suggestionIndex: Math.max(0, this.state.suggestionIndex - 1),
      });
    }
    if (e.key == "Escape") {
      this.setState(
        { value: this.props.value, suggestions: [], suggestionIndex: 0 },
        () => {
          target.blur();
        }
      );
    }
    if (e.key == "Enter") {
      if (this.state.suggestions.length > 0) {
        const value =
          this.state.suggestions[this.state.suggestionIndex][
            this.props.property
          ];
        this.setState({ value, suggestions: [], suggestionIndex: 0 }, () => {
          target.blur();
        });
      } else {
        target.blur();
      }
    }
  }

  render() {
    return (
      <>
        <input
          type="text"
          className="equipment-property__input"
          placeholder={this.state.placeholder}
          onChange={(e) =>
            this.setState({ value: e.target.value }, this.suggest)
          }
          onKeyDown={this.onKeyDown}
          value={this.state.value}
          onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
          onBlur={() => {
            this.onConfirm(this.state.value);
          }}
        ></input>
        {this.state.suggestions.length > 0 && (
          <div className="equipment-property__suggest">
            <ul>
              {this.state.suggestions.map((s, i) =>
                this.renderItem(i, s, this.onSelected, this.onMouseOver)
              )}
            </ul>
          </div>
        )}
      </>
    );
  }
}
