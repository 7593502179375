import React, { useEffect, useReducer } from "react";
import { useAppContext } from "../app/AppContext";
import { currentWeekCode } from "../utils/weekUtils";
import {
  actionFetchActionItems,
  actionFetchActionItemsContacts,
  actionFetchActionItemsMeetingNotes,
  actionFetchActionItemsMeetings,
  actionFetchActionITemsProjects,
  actionFetchActionItemsTriggers,
  actionFetchActionItemsWeeks,
} from "./ActionItem/actions";
import { actionFetchPersons } from "./Contact/actions";
import { actionFetchHyperlinks } from "./Hyperlink/actions";
import initialState from "./initialState";
import {
  actionFetchMeetings,
  actionFetcMeetingsLinks,
} from "./Meeting/actions";
import { actionFetchMeetingNotes } from "./MeetingNote/actions";
import {
  actionFetchProjects,
  actionFetchProjectsLinks,
  actionFetchProjectsMeetings,
} from "./Project/actions";
import reducer from "./reducer";
import { actionFetchTriggers } from "./Trigger/actions";
import { actionFetchWeeks, actionFetchWeeksProjects } from "./Week/actions";

export const TaskOneContext = React.createContext();

export const useTaskOneContext = () => React.useContext(TaskOneContext);

export const TaskOneContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dispatch: appDispatch } = useAppContext();
  const { refreshKey, errors } = state;

  useEffect(() => {
    if (errors.length)
      appDispatch({
        type: "ERROR_UP",
        payload: errors,
      });
  }, [errors]);

  useEffect(() => {
    // Action Items
    dispatchAction(actionFetchActionItems());
    dispatchAction(actionFetchActionItemsContacts());
    dispatchAction(actionFetchActionItemsTriggers());
    dispatchAction(actionFetchActionItemsWeeks());
    dispatchAction(actionFetchActionItemsMeetings());
    dispatchAction(actionFetchActionItemsMeetingNotes());
    dispatchAction(actionFetchActionITemsProjects());

    // Meetings
    dispatchAction(actionFetchMeetings());
    dispatchAction(actionFetcMeetingsLinks());

    // Person
    dispatchAction(actionFetchPersons());
    //Projects
    dispatchAction(actionFetchProjects());
    dispatchAction(actionFetchProjectsMeetings());
    dispatchAction(actionFetchProjectsLinks());
    // Triggers
    dispatchAction(actionFetchTriggers());
    // Weeks
    dispatchAction(actionFetchWeeks());
    dispatchAction(actionFetchWeeksProjects());

    // Meeting notes
    dispatchAction(actionFetchMeetingNotes());

    // Hyperlinks
    dispatchAction(actionFetchHyperlinks());
  }, [refreshKey]);

  React.useEffect(() => {
    dispatch({ type: "CURRENT_WEEK_CODE", payload: currentWeekCode() });
  }, [refreshKey]);

  async function dispatchAction(action, completeCallback) {
    try {
      await action(dispatch, completeCallback);
    } catch (err) {
      dispatch({ type: "ERROR", payload: err });
    }
  }

  return (
    <TaskOneContext.Provider value={{ state, dispatch, dispatchAction }}>
      {props.children}
    </TaskOneContext.Provider>
  );
};
