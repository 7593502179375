export function getAccountBalances(state) {
  const { account: idsByAccount } = state.operations.idsBy;

  const accounts = Object.keys(idsByAccount);
  const soldes = accounts.map((account) => ({
    account,
    solde: parseFloat(
      idsByAccount[account]
        .map((id) => state.operations.byID[id])
        .map((o) => o.credit - o.debit)
        .reduce((acc, curr) => acc + curr)
    ).toFixed(2),
  }));
  return soldes;
}

export function getTotalBalanceFromAccountBalances(balances) {
  return balances
    .map((solde) => parseFloat(solde.solde))
    .reduce((a, b) => a + b, 0);
}

export function getAccountBalance(state) {
  return getTotalBalanceFromAccountBalances(getAccountBalances(state));
}
