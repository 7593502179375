import React from "react";
import { Checkbox, EditableText } from "@blueprintjs/core";
import "./objectfield.css";
import Status from "../StatusLabel/Status";
import {
  ObjectFieldDateTimeEditable,
  ObjectFieldDateTime,
  ObjectFieldDateEditable,
} from "./ObjectFieldDate";
import { filterValue } from "../Inputs/CurrencyInput";

export function ObjectFieldDecimalEur({
  field,
  value,
  placeholder,
  multiline = false,
  handleConfirm,
}) {
  const [val, setVal] = React.useState(value);

  React.useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>
        <EditableText
          multiline={multiline}
          placeholder={placeholder || "Add " + field + " ..."}
          value={val}
          onChange={(e) => setVal(filterValue(e))}
          onConfirm={(v) => handleConfirm(field, v)}
          selectAllOnFocus
        />
      </div>
    </div>
  );
}

export {
  ObjectFieldDateTimeEditable,
  ObjectFieldDateTime,
  ObjectFieldDateEditable,
};

export function ObjectFieldEditableText({
  field,
  value,
  placeholder,
  multiline = false,
  handleConfirm,
}) {
  const [val, setVal] = React.useState(value);

  React.useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>
        <EditableText
          multiline={multiline}
          placeholder={placeholder || "Add " + field + " ..."}
          value={val}
          onChange={(e) => setVal(e)}
          onConfirm={(v) => handleConfirm(field, v)}
          selectAllOnFocus
        />
      </div>
    </div>
  );
}

export function ObjectTextField({ field, value }) {
  const [val, setVal] = React.useState(value);

  React.useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>{value}</div>
    </div>
  );
}

export function ObjectTitleEditable({ name, id, onConfirm }) {
  const [value, setValue] = React.useState(name);

  React.useEffect(() => {
    setValue(name);
  }, [name]);

  return (
    <h1>
      {id} -{" "}
      <EditableText
        value={value}
        onChange={(e) => setValue(e)}
        onConfirm={onConfirm}
      />
    </h1>
  );
}

export function ObjectTitle({ name, id }) {
  return (
    <h1>
      {id} - {name}
    </h1>
  );
}

export function ObjectFieldBoolean({ field, value }) {
  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>{value ? "YES" : "NO"}</div>
    </div>
  );
}

export function ObjectFieldBooleanEditable({ field, value, handleConfirm }) {
  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <Checkbox
        checked={value}
        onChange={(e) => handleConfirm(field, !value)}
      />
    </div>
  );
}

export function ObjectFieldStatus({ field, value }) {
  return (
    <div className="objectfield__box">
      <div className="objectfield__label">
        {field.charAt(0).toUpperCase() + field.slice(1)}
      </div>
      <div>
        {" "}
        <Status status={value} />
      </div>
    </div>
  );
}
