import { EditableText, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { Page } from "../../utils/Layout/Layout";
import { TaskOneContext } from "../TaskOneContext";

export default function Trigger() {
  const { state } = useContext(TaskOneContext);
  let { id } = useParams();
  const { [id]: trigger } = state.triggers.byID;
  if (!id) {
    return <NonIdealState icon={IconNames.NINJA}></NonIdealState>;
  }
  if (!trigger) {
    return <div>Loading...</div>;
  }

  function handleChange(field, value) {
    // dispatch({ type: "PROJECT_CHANGE", payload: { id, field, value } });
  }

  function handleConfirm(field, value) {
    //dispatchAction(actionPersistProject(id, field, value));
  }

  return (
    <Page>
      <h1>
        <EditableText
          placeholder="Trigger Name"
          value={trigger.name}
          onChange={(e) => handleChange("name", e)}
          onConfirm={(e) => handleConfirm("name", e)}
          selectAllOnFocus={true}
        />
      </h1>

      <hr />
    </Page>
  );
}
