import { handleError, normalize, normjoin } from "../utils/normalize";
import { ObjectTypes } from "./TorgaRouter";

function reducer(state, action) {
  try {
    const payload = action.payload;
    switch (action.type) {
      case "ERROR": {
        return {
          ...state,
          errors: handleError(state.errors, payload, "torga"),
        };
      }
      case "CLEAR_ERRORS":
        return { ...state, errors: [] };
      case "REFRESH_DATA":
        return { ...state, refreshKey: state.refreshKey + 1 };
      case "FETCH_DATA":
        return state;

      case "ACTIVITY_CHANGE":
        let actChState = { ...state };
        const { [payload.id]: activity } = actChState.activities.byID;
        activity[payload.field] = payload.value;
        actChState.activities[payload.id] = activity;
        return actChState;

      case "FETCH_ACTIVITY_COMPLETE":
      case "ACTIVITY_PERSIST_COMPLETED":
      case "ACTIVITY_ADD_COMPLETE":
        return {
          ...state,
          activities: normalize(state.activities, action.payload, [
            { name: "parentID", keys: ["parent_id"] },   
          ]),
        };

      // ==== EQUIPMENTS  ====

      case "EQUIPMENT_CHANGE":
        const equChState = { ...state };
        equChState.equipments.byID[payload.id] = {
          ...equChState.equipments.byID[payload.id],
          [payload.field]: payload.value,
        };
        return equChState;

      case "EQUIPMENT_PERSIST_COMPLETE":
      case "EQUIPMENT_FETCH_COMPLETE": {
        return {
          ...state,
          equipments: normalize(state.equipments, action.payload),
        };
      }

      // ==== ACTIVITYEQUIPMENTS ====

      case "ACTIVITYEQUIPMENTS_FETCH_COMPLETE":
      case "ACTIONITEMSTRIGGERS_REMOVE":
      case "ACTIONITEMSTRIGGERS_ADD": {
        return normjoin(state, action.payload, {
          fields: ["activity_id", "equipment_id"],
          objects: [ObjectTypes.ACTIVITIES, ObjectTypes.EQUIPMENTS],
          keys: ["activityID", "equipmentID"],
        });
      }

      // ==== TRIPS ====

      case "TRIP_FETCH_COMPLETE":
        return { ...state, trips: normalize(state.trips, action.payload) };

      case "LOG_STATE":
        console.log(state);
        return state;

      // ==== DIARY POSTS ====

      case "DIARYPOST_FETCH_COMPLETE":
      case "DIARYPOST_CREATE_COMPLETE":
      case "DIARYPOST_PERSIST_COMPLETE":
        return {
          ...state,
          diaryposts: normalize(state.diaryposts, action.payload),
        };

      // ==== DEFAULT ====
      default:
        throw new Error(
          `[${action.type}] is unknown in Torga Reducer. Did you returned a new state ?`
        );
    }
  } catch (err) {
    console.error(err);
    return {
      ...state,
      errors: handleError(state.errors, err, "torga"),
    };
  }
}

export default reducer;
