import { Icon } from "@blueprintjs/core";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./line.css";
import { actionPersistTask } from "../../vesta/Tasks/actions";

function ensureTwoLetters(id) {
  return id < 10 ? "0" + id : id;
}

export default function Line(props) {
  const {
    id,
    context,
    type,
    onSelect,
    selectedID,
    level = 0,
    prefix = "M",
    link = () => "",
    moveObject = null,
    useCode = false,
    persistState = false,
  } = props;
  const { state, dispatchAction } = useContext(context);
  const object = state[type.name].byID[id];
  const [open, setOpen] = useState(level < 2);
  const [dragTarget, setDragTarget] = React.useState(false);

  React.useEffect(() => {
    let timer;
    if (dragTarget) {
      timer = setTimeout(() => {
        setOpen(true);
      }, 1000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [dragTarget]);

  if (!object) {
    return "Loading...";
  }

  const isOpen = persistState ? object.open || level < 1 : open;

  const { [id]: childrenIDs = [] } = state[type.name].idsBy.parentID;

  let icon = "chevron-right";
  let iconStyle = {};

  if (childrenIDs.length > 0) {
    if (isOpen) {
      icon = "chevron-down";
    }
  } else {
    iconStyle = { color: "#5F6B7C" };
  }

  const classNames = [
    "line",
    "line-" + level,
    selectedID == id ? "line_selected" : "",
    dragTarget ? "line__drag_target" : "",
  ];

  return (
    <div>
      <div
        className={classNames.join(" ")}
        onClick={(e) => {
          e.stopPropagation();
          if (onSelect) {
            onSelect(id);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = "move";
          setDragTarget(true);
        }}
        onDragLeave={(e) => {
          setDragTarget(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          const data = e.dataTransfer.getData("text/plain");
          setDragTarget(false);
          moveObject && moveObject(data, id);
        }}
      >
        {level > 0 && (
          <div
            className="line-icon"
            onClick={(e) => {
              e.stopPropagation();
              if (persistState) {
                persistState(object.id, !isOpen);
              } else {
                setOpen(!isOpen);
              }
            }}
          >
            <Icon icon={icon} style={iconStyle} className="search-icon" />
          </div>
        )}

        <div className="line-label">
         
            {useCode ? object.code + " " : <Link to={link(id)}>{ `${prefix}${ensureTwoLetters(id)}`} </Link>}
         
          {object.name} {object.icon ? " " : ""} {object.icon}
        </div>
      </div>
      <div className={"line-ch line-ch-" + level}>
        {isOpen &&
          childrenIDs
            .filter((id) => state[type.name].byID[id].deleted === false)
            .map((childID) => (
              <Line
                {...props}
                id={childID}
                key={childID}
                level={level + 1}
              />
            ))}
      </div>
    </div>
  );
}
