import React from "react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dateLong } from "../../utils/Utils/Utils";

export default function CalendarElement({ day }) {
  return (
    <div>
      <Button icon={IconNames.PLUS} style={{ float: "right" }} />
      <h4>{dateLong(day || new Date())}</h4>
    </div>
  );
}
