import { FocusStyleManager } from "@blueprintjs/core";
import React from "react";
import { tget } from "../utils/Utils/Utils";
import "./app.css";
import { useAppContext } from "./AppContext";
import Login from "./Login/Login";
import MainRouter from "./MainRouter";

export default function App() {
  const { state, dispatch } = useAppContext();

  React.useEffect(() => {
    FocusStyleManager.onlyShowFocusOnTabs();
  }, []);

  React.useEffect(() => {
    tget("/auth").then((res) => dispatch({ type: "AUTH", payload: res }));
  }, []);

  if (!state.auth.isLoaded) {
    return <div>Loading...</div>;
  }

  if (!state.auth.auth) {
    return <Login />;
  }

  return (
    <>
      <MainRouter />
      <div style={{ display: "none" }}>
        <div>Session ID: {state.auth.sessionID}</div>
        <div>Env: {state.auth.env}</div>
        <div>Auth: {state.auth.auth.toString()}</div>
      </div>
    </>
  );
}
