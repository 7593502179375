import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TorgaContext } from "../TorgaContext";
import { urlToActivityID } from "../TorgaRouter";

export default function ActivityListCompact({ activityIDs }) {
  const { state } = useContext(TorgaContext);

  if (!activityIDs) {
    return <div></div>;
  }

  return activityIDs.map((id) => {
    const activity = state.activities.byID[id];
    return (
      <div key={id}>
        <Link to={urlToActivityID(id)}>A{id}</Link> - {activity.name}
      </div>
    );
  });
}
