import { EditableText, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useParams } from "react-router-dom";
import Label from "../../utils/Label/Label";
import { useTorgaContext } from "../TorgaContext";
import { actionUpdateTrip } from "./action";

export default function Trip() {
  const { state, dispatch, dispatchAction } = useTorgaContext();
  const { id } = useParams();

  const trip = state.trips.byID[id];

  if (!id) {
    return <NonIdealState icon={IconNames.AIRPLANE} />;
  }

  if (!trip) {
    return <div>Loading ...</div>;
  }

  function onChange(name) {
    return (e) => {
      const newTrip = { ...trip, [name]: e };
      dispatch({ type: "TRIP_FETCH_COMPLETE", payload: [newTrip] });
    };
  }

  function onConfirm(name) {
    return (e) => {
      dispatchAction(actionUpdateTrip(id, { [name]: e }));
    };
  }

  return (
    <div>
      <h1>
        <EditableText
          placeholder="Trip name"
          value={trip.name}
          onConfirm={onConfirm("name")}
          onChange={onChange("name")}
        />
      </h1>
      <table>
        <tbody>
          <tr>
            <td>ID</td>
            <td>
              <Label id={id} prefix="V" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
