import { Button, Intent } from "@blueprintjs/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Label from "../../utils/Label/Label";
import { TorgaContext } from "../TorgaContext";
import { generateCard } from "./card";
import "./EquipmentCardEvo.css";
import {
  fetchEquipmentPicture,
  saveEquipmentPicture,
} from "./EquipmentPicture";

const DEFAULT_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAACqCAYAAAA9dtSCAAAECklEQVR4nO3d3VEbZxiG4ceZNKCUQEpQDijAKQGXQErAJZgSrBLsEkwBHIQSTAuUQA5WiiQsdhe0P7ziumY0A6Nhdw/u+fbbXxIAAAAAAAAAAAAAAAAAAAAAAADeiw9Tr/D29vZx6nUyvPPz80nb+W3KlcFrCZUShEoJv8+9AZlhnsyrzHpsYUSlBKFSglApQaiUIFRKEColCJUShEoJQqUEoVKCUClBqJQgVEoQKiUIlRKESglCpQShUoJQKeEtPDNV1VmSi/XPl+vfD/me5G798/XYG3WqhPpyV2kP86mLbIP+kuQmTbyr4TftdNn193eV5knML+kf6SEfk3xN8jPbgOkg1G5nSf5NE+jQy/22/tBBqO2WaSJdjriOi/U6jhmlT55Qn7dM8iPJYsJ1ifUZQj1skWaXPEWkG5upAAcI9bCv6T+63SX5nOSvNK8n2v18Wn/30HNZy/W6eUKov9o9ndTmLsnfaQK9zvZc6a7v6+/+SPJP+gV7mebMADuE+qs+R/erNIHevGC5m785FPRTRtUnhLrvY7p3+as0o+Nr3Kdf4Gcxqu4R6r7Lju/v8vpId31KE+0x2/KuCHVrke656RCRJs1ctWtZRtQdQt3qCmOVfvPLvjbX/J+zyLgXGkoR6lZXFG1RvVbXMoW6JtStthH1IS87wu+rK9QpLzi8aULdahu9xhhNN4acTpwsoTa6Tkl1HaEfo+9Vq3fNjdON+zSXPJfZnwJsbpAeY7e/Yffeg1D33WV/VzzFoyNto/mYI3kpdv3zWqZ9RB1zJC9FqPNqu/p0F/PX/wl1Psu0hzrmmYZyhDqPzY3ZbYS6Q6jz6HrsZBUHUnuEOr0fab+48JDmqQB2CHU6izSRdt380vdJgHdFqNM4S79IP8fc9CAn/Me3ibTPkwPeTfUMI+q4Ni+w6Ir0JsPdlH2ShDqevi+wuEnzNCsthDoOkQ5MqMPr+5aVVUTam1CH9y3dc9LrmJO+iKP+YV2l3ykoR/cvZEQdziJNqG1E+kpCHc5l2uelzpMeQajDaRtN72NOehShDuMi7aOpSI8k1GG03Q11H4+UHE2ow5jrnQDvhlCH0Xbe1AsmBiDUYQh1ZEIdn0dKBiDU4/mXOxMQ6riMpgMR6vHEOAE3pQzjw9wbcOqMqJQgVEoQKiUIlRKESglCPc5lkseWz8/5Nu20CJUShEoJQqUEoVKCUClBqJQg1OOs0tyQ8tznz/k27bQIlRKESglCpQShUoJQKUGolCBUShAqJQiVEoRKCUKlBKFSglApQaiUIFRKEColCJUShEoJQqUEoVKCUClBqJQgVEoQKiUIlRLewr/veZx7A3j7jKiUIFRKECoAAAAAAAAAAAAAAAAAAAAAAADwpvwHf/h356OkJl0AAAAASUVORK5CYII=";

export default function EquipmentCardEvo({
  onClick,
  equipment_id,
  deleteMode,
}) {
  const navigate = useNavigate();
  const { state } = useContext(TorgaContext);
  const [highlight, setHighlight] = useState(false);
  const [file, setFile] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const [saved, setSaved] = useState(true);
  const fileInputRef = useRef(null);
  const [imageData, setImageData] = useState();

  const { [equipment_id]: equipment } = state.equipments.byID;

  function onDragOver(event) {
    event.preventDefault();
    setHighlight(true);
  }

  function onDragLeave(event) {
    setHighlight(false);
  }

  function onDrop(event) {
    event.preventDefault();
    const onDropFile = event.dataTransfer.files[0];
    onFileAdded(onDropFile);
    setHighlight(false);
  }

  function openFileDialog() {
    fileInputRef.current.click();
  }

  function onFileAdded(onFileAddedfile) {
    setFile(onFileAddedfile);
  }

  function saveImage(e) {
    saveEquipmentPicture(equipment_id, imageData).then((image) => {
      setSaved(true);
    });
  }

  useEffect(() => {
    uploadFile();
  }, [file]);

  useEffect(() => {
    fetchEquipmentPicture(equipment_id).then((result) =>
      result ? setImageData(result) : setImageData(DEFAULT_IMAGE)
    );
  }, [state.refreshKey, equipment_id]);

  async function uploadFile() {
    setUploadProgress({});
    try {
      await sendRequest(file).then((data) => {
        setSaved(false);
        setImageData(data);
      });
    } catch (e) {
      // Do nothing
    }
  }

  function sendRequest(sendRequestFile) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = { ...uploadProgress };
          copy[sendRequestFile.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          };
          setUploadProgress(copy);
        }
      });

      req.upload.addEventListener("load", (event) => {
        const copy = { ...uploadProgress };
        copy[sendRequestFile.name] = { state: "done", percentage: 100 };
        setUploadProgress(copy);
      });

      req.upload.addEventListener("error", (event) => {
        const copy = { ...uploadProgress };
        copy[sendRequestFile.name] = { state: "error", percentage: 0 };
        setUploadProgress(copy);
        reject(req.response);
      });

      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          resolve(JSON.parse(req.responseText));
        }
      };

      const formData = new FormData();
      formData.append("file", sendRequestFile, sendRequestFile.name);
      req.open("POST", "/api/v2/upload");
      req.send(formData);
    });
  }

  function handleClick(e) {
    e.stopPropagation();
    if (onClick) {
      onClick(equipment_id);
    } else {
      navigate(`/s/equipment/${equipment_id}`);
    }
  }

  function copyMxCard(e) {
    const el = document.createElement("textarea");
    el.value = generateCard(
      equipment.id,
      equipment.name,
      "&amp;lt;address&amp;gt;&lt;br/&gt;&amp;lt;address&amp;gt;",
      imageData
    );
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  const style = highlight
    ? { background: "rgb(188, 185, 236)" }
    : { cursor: "pointer" };

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        copyMxCard(e);
      }}
    >
      <div
        className="equipmentcard_box"
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={openFileDialog}
        style={style}
      >
        <input
          ref={fileInputRef}
          className="equipmentcard_fileinput"
          type="file"
          onChange={(evt) => onFileAdded(evt.target.files[0])}
          style={{ display: "none" }}
        />
        <img className="equipmentcard_image" src={imageData} />
        <div className="equipmentcard_box2">
          <Label prefix="E" id={equipment_id} onClick={handleClick}></Label>
        </div>
        <div
          className={`equipmentcard_footer ${
            deleteMode ? "equipmentcard_footer-red" : ""
          }`}
        >
          <div className="equipmentcard_footer-test" onClick={handleClick}>
            {equipment?.name ? equipment.name : "Equipment Name"}
          </div>
        </div>
      </div>
      {!saved && (
        <div>
          <Button onClick={saveImage} small intent={Intent.SUCCESS}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
