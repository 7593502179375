import { EditableText } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";
import ActionItemsComponent from "../ActionItem/ActionItemsComponent";
import { useTaskOneContext } from "../TaskOneContext";
import { actionMeetingnotePersist } from "./actions";
import { weektag } from "./selectors";

const StyledMeetingNote = styled.div`
  border-top: solid 1px grey;
  margin-top: 20px;
`;

export default function MeetingNoteComponent({ id }) {
  const { state: taskOneState, dispatchAction } = useTaskOneContext();
  const [state, setState] = React.useState({
    agenda: "",
    content: "",
    decisional: "",
  });

  const { [id]: meetingnote } = taskOneState.meetingnotes.byID;
  const { [meetingnote?.week_id]: week } = taskOneState.weeks.byID;
  const { [meetingnote?.meeting_id]: meeting } = taskOneState.meetings.byID;
  const { [id]: actionItemIDs } = taskOneState.actionitems.idsBy.meetingnoteID;

  React.useEffect(() => {
    if (meetingnote) {
      setState(meetingnote);
    }
  }, [meetingnote]);

  function handleChange(field, value) {
    setState({ ...state, [field]: value });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionMeetingnotePersist(id, field, value));
  }

  if (!meetingnote || !week || !meeting) {
    return <div>Loading...</div>;
  }

  return (
    <StyledMeetingNote>
      <h2>{weektag(week)}</h2>
      <h4>Agenda</h4>
      <EditableText
        multiline
        minLines={3}
        value={state.agenda}
        onChange={(e) => handleChange("agenda", e)}
        onConfirm={(e) => handleConfirm("agenda", e)}
      />
      <h4>Notes</h4>
      <EditableText
        multiline
        minLines={3}
        value={state.content}
        onChange={(e) => handleChange("content", e)}
        onConfirm={(e) => handleConfirm("content", e)}
      />
      <h4>Take-aways</h4>
      <EditableText
        multiline
        minLines={3}
        value={state.decisional}
        onChange={(e) => handleChange("decisional", e)}
        onConfirm={(e) => handleConfirm("decisional", e)}
      />
      <ActionItemsComponent
        ids={actionItemIDs}
        context={{ meetingnote_id: id }}
        header={(title) => <h4>{title}</h4>}
      />
    </StyledMeetingNote>
  );
}
