import React from "react";
import { useAppContext } from "../AppContext";

export default function Menu({ name, entries }) {
  const { dispatch } = useAppContext();

  React.useEffect(() => {
    dispatch({
      type: "MENU_ADD",
      payload: {
        name,
        entries,
      },
    });
    return () => {
      dispatch({
        type: "MENU_REMOVE",
        payload: {
          name,
          entries,
        },
      });
    };
  }, []);
  return null;
}
