import { searchIn } from "../../utils/Search/SearchUtils";
import { ObjectTypes, urlToProjectID } from "../TaskOneRouter";

export function searchProjects(projects, query) {
  function buildProjectSearchString(e) {
    return ["P" + e.id, e.name, e.content].join("|");
  }

  return searchIn(
    ObjectTypes.PROJECTS,
    projects,
    query,
    buildProjectSearchString,
    (project) => {
      return {
        id: project.id,
        key: "P" + project.id,
        name: project.name,
        details: project.content?.substring(0, 100),
        link: urlToProjectID(project.id),
      };
    }
  );
}
