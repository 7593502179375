import React from "react";
import { Route } from "react-router";
import { useNavigate } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import { urlToEquipmentID } from "../TorgaRouter";
import Equipment from "./Equipment";
import EquipmentSelect from "./EquipmentSelect";

export default function Equipments() {
  const navigate = useNavigate();
  return (

    <Browser>
      <List>
        <EquipmentSelect
          onSelect={(equipmentID) => {
            navigate(urlToEquipmentID(equipmentID));
          }}
        />
      </List>
      <Page>
        <Equipment />
      </Page>
    </Browser>

  );
}
