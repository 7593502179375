import { Button, InputGroup } from "@blueprintjs/core";
import React from "react";
import { actionBankSaveToken, actionCreateToken } from "../actions";

export default function GoogleToken() {
  const [tokenURL, setTokenURL] = React.useState();
  const [token, setToken] = React.useState();

  return (
    <>
      <Button
        onClick={() =>
          actionCreateToken().then((result) => setTokenURL(result.authUrl))
        }
      >
        Get Auth URL
      </Button>
      {tokenURL && (
        <a href={tokenURL} target="_blank">
          Get Token
        </a>
      )}
      {}
      <InputGroup
        onChange={(e) => setToken(e.target.value)}
        rightElement={
          <Button
            onClick={() => {
              actionBankSaveToken(token);
            }}
          >
            Save
          </Button>
        }
      ></InputGroup>
      {token}
    </>
  );
}
