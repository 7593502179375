import { tget, tpost } from "../../utils/Utils/Utils";

export function actionFetchPersons() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/person").then((result) => {
      dispatch({ type: "CONTACT_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionCreateContact(name, callback) {
  return (dispatch) => {
    tpost("/api/v2/person", { name: name ? name : "New Person" })
      .then((result) => {
        dispatch({ type: "CONTACT_CREATE_COMPLETE", payload: [result] });
        callback && callback(result.id);
      })
      .catch((e) => dispatch({ type: "ERROR", payload: { e } }));
  };
}

export function actionPersistContact(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/person/${id}`, { [field]: value }).then((result) => {
      dispatch({ type: "CONTACT_PERSIST_COMPLETE", payload: [result] });
    });
  };
}
