import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Browser, List, Page } from "../../utils/Layout/Layout";
import Line from "../../utils/Line/Line";
import ObjectSelector from "../../utils/ObjectSelector/ObjectSelector";
import { TaskOneContext, useTaskOneContext } from "../TaskOneContext";
import { ObjectTypes, urlToProjectID } from "../TaskOneRouter";
import { actionPersistProject } from "./actions";
import Project from "./Project";
import { searchProjects } from "./search";
import { orphanProjectListEntries } from "./selectors";

export default function Projects() {
  const { state, dispatchAction } = useTaskOneContext();
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const params = useParams();

  if (!state.projects.rootID) {
    return <div>Loading...</div>;
  }

  return (
    <Browser>
      <List>
        <ObjectSelector
          objects={
            search && search != ""
              ? searchProjects(state.projects, { text: search })
              : orphanProjectListEntries(state)
          }
          objectType={ObjectTypes.PROJECTS}
          urlToObject={urlToProjectID}
          onChange={setSearch}
        />

        <Line
          id={state.projects.rootID}
          selectedID={params.id}
          context={TaskOneContext}
          type={ObjectTypes.PROJECTS}
          prefix={"P"}
          link={(id) => urlToProjectID(id)}
          onSelect={(id) => navigate(urlToProjectID(id))}
          moveObject={(id, newParent) => {
            dispatchAction(actionPersistProject(id, "parent_id", newParent));
          }}
        />
      </List>
      <Page>
        <Project />
      </Page>
    </Browser>
  );
}
