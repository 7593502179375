export function weektag(week) {
  if (!week) {
    return "Loading ...";
  }
  return week.year + "_W" + (week.week < 10 ? `0${week.week}` : week.week);
}

export function allMeetingNotesListItem(state) {
  return state.meetingnotes.allIDs
    .map((id) => state.meetingnotes.byID[id])
    .map((object) => ({
      id: object.id,
      name:
        state.meetings.byID[object.meeting_id]?.name +
        " " +
        weektag(state.weeks.byID[object.week_id]),
      deleted: false,
    }));
}

export function getSortedMeetingNoteIDsForMeeting(state, meetingID) {
  const { [meetingID]: meetingNotesIDs } = state.meetingnotes.idsBy.meetingID;
  return meetingNotesIDs
    ?.map((id) => state.meetingnotes.byID[id])
    .map((o) => {
      const week = state.weeks.byID[o.week_id];
      return {
        id: o.id,
        year: week.year,
        week: week.week,
      };
    })
    .sort((b, a) => a.year - b.year || a.week - b.week);
}
