import { tget, tpost } from "../../utils/Utils/Utils";

export function actionPersistActionItem(id, field, value) {
  return (dispatch) => {
    tpost(`/api/v2/actionitem/${id}`, {
      [field]: value,
    }).then((actionitem) => {
      dispatch({
        type: "ACTIONITEM_PERSIST_COMPLETED",
        payload: { id, actionitem },
      });
    });
  };
}

export function actionFetchActionItems() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/actionitem").then((result) => {
      dispatch({ type: "ACTIONITEM_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionFetchActionItemsContacts() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/actionitemscontacts").then((result) => {
      dispatch({ type: "ACTIONITEMSCONTACTS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionFetchActionItemsMeetings() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/actionitemsmeetings").then((result) => {
      dispatch({ type: "ACTIONITEMSMEETINGS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionFetchActionItemsMeetingNotes() {
  return (dispatch) => {
    tget(`/api/v2/actionitemsmeetingnotes`).then((result) => {
      dispatch({
        type: "ACTIONITEMSMEETINGNOTES_FETCH_COMPLETE",
        payload: result,
      });
    });
  };
}

export function actionFetchActionItemsTriggers() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/actionitemstriggers").then((result) => {
      dispatch({ type: "ACTIONITEMSTRIGGERS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionFetchActionITemsProjects() {
  return (dispatch) => {
    tget(`/api/v2/actionitemsprojects`).then((result) =>
      dispatch({ type: "ACTIONITEMSPROJECTS_FETCH_COMPLETE", payload: result })
    );
  };
}

export function actionFetchActionItemsWeeks() {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tget("/api/v2/actionitemsweeks").then((result) => {
      dispatch({ type: "ACTIONITEMSWEEKS_FETCH_COMPLETE", payload: result });
    });
  };
}

export function actionAddTriggerToActionItem(trigger_id, actionitem_id) {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tpost(`/api/v2/actionitemstriggers`, {
      actionitem_id: actionitem_id,
      trigger_id: trigger_id,
      deleted: false,
    }).then((result) => {
      dispatch({ type: "ACTIONITEMSTRIGGERS_ADD", payload: [result] });
    });
  };
}

export function actionRemoveTriggerFromActionItem(trigger_id, actionitem_id) {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tpost(`/api/v2/actionitemstriggers`, {
      actionitem_id: actionitem_id,
      trigger_id: trigger_id,
      deleted: true,
    }).then((result) => {
      dispatch({ type: "ACTIONITEMSTRIGGERS_REMOVE", payload: [result] });
    });
  };
}

export function actionAddWeekToActionItem(week_id, actionitem_id) {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tpost(`/api/v2/actionitemsweeks`, {
      actionitem_id: actionitem_id,
      week_id: week_id,
      deleted: false,
    }).then((result) => {
      dispatch({ type: "ACTIONITEMSWEEKS_ADD", payload: [result] });
    });
  };
}

export function actionRemoveWeekFromActionItem(week_id, actionitem_id) {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tpost(`/api/v2/actionitemsweeks`, {
      actionitem_id: actionitem_id,
      week_id: week_id,
      deleted: true,
    }).then((result) => {
      dispatch({ type: "ACTIONITEMSWEEKS_REMOVE", payload: [result] });
    });
  };
}

export function actionAddMeetingToActionItem(meeting_id, actionitem_id) {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tpost(`/api/v2/actionitemsmeetings`, {
      actionitem_id: actionitem_id,
      meeting_id: meeting_id,
      deleted: false,
    }).then((result) => {
      dispatch({ type: "ACTIONITEMSMEETINGS_ADD", payload: [result] });
    });
  };
}

export function actionRemoveMeetingFromActionItem(meeting_id, actionitem_id) {
  return (dispatch) => {
    dispatch({ type: "FETCH_DATA" });
    tpost(`/api/v2/actionitemsmeetings`, {
      actionitem_id: actionitem_id,
      meeting_id: meeting_id,
      deleted: true,
    }).then((result) => {
      dispatch({ type: "ACTIONITEMSMEETINGS_REMOVE", payload: [result] });
    });
  };
}

export function actionAddMeetingnoteToActionItem(
  meetingnote_id,
  actionitem_id
) {
  return (dispatch) => {
    tpost(`/api/v2/actionitemsmeetingnotes`, {
      actionitem_id: actionitem_id,
      meetingnote_id: meetingnote_id,
      deleted: false,
    }).then((result) => {
      dispatch({
        type: "ACTIONITEMSMEETINGNOTES_ADD_COMPLETE",
        payload: [result],
      });
    });
  };
}

export function actionAddProjectToActionItem(project_id, actionitem_id) {
  return (dispatch) => {
    tpost("/api/v2/actionitemsprojects", {
      actionitem_id: actionitem_id,
      project_id: project_id,
      deleted: false,
    }).then((result) => {
      dispatch({
        type: "ACTIONITEMSPROJECTS_ADD_COMPLETE",
        payload: [result],
      });
    });
  };
}

export function actionRemoveMeetingnoteFromActionItem(
  meetingnote_id,
  actionitem_id
) {
  return (dispatch) => {
    tpost(`/api/v2/actionitemsmeetingnotes`, {
      actionitem_id: actionitem_id,
      meetingnote_id: meetingnote_id,
      deleted: true,
    }).then((result) => {
      dispatch({
        type: "ACTIONITEMSMEETINGNOTES_REMOVE_COMPLETE",
        payload: [result],
      });
    });
  };
}

export function actionActionItemCreate(
  name,
  type,
  links,
  onActionItemCreated = null
) {
  return (dispatch) => {
    tpost("/api/v2/actionitem", { name: name, type: type })
      .then((result) => {
        dispatch({ type: "ACTIONITEM_CREATE_COMPLETE", payload: [result] });
        const { week_id, trigger_id, meeting_id, meetingnote_id, project_id } =
          links;
        if (week_id) {
          actionAddWeekToActionItem(week_id, result.id)(dispatch);
        }
        if (trigger_id) {
          actionAddTriggerToActionItem(trigger_id, result.id)(dispatch);
        }
        if (meeting_id) {
          actionAddMeetingToActionItem(meeting_id, result.id)(dispatch);
        }
        if (meetingnote_id) {
          actionAddMeetingnoteToActionItem(meetingnote_id, result.id)(dispatch);
        }
        if (project_id) {
          actionAddProjectToActionItem(project_id, result.id)(dispatch);
        }
        onActionItemCreated && onActionItemCreated(result.id);
      })
      .catch((e) => dispatch({ type: "ERROR", payload: { e } }));
  };
}
