import { Hotkey, Hotkeys } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useNavigate } from "react-router";
import { Route } from "react-router-dom";
import Menu from "../app/SideDrawer/Menu";
import AppModuleConnector from "../utils/AppFramework/AppModuleConnector";
import ErrorDisplay from "../utils/Error/ErrorDisplay";
import { useHotkeys } from "../utils/Hotkeys";
import ActionItems from "./ActionItem/ActionItems";
import Contacts from "./Contact/Contacts";
import Meetings from "./Meeting/Meetings";
import MeetingNotes from "./MeetingNote/MeetingNotes";
import Projects from "./Project/Projects";
import { search } from "./search";
import { TaskOneContext, TaskOneContextProvider } from "./TaskOneContext";
import Triggers from "./Trigger/Triggers";
import Weeks from "./Week/Weeks";

export const urlToWeekID = (id) => `/t/week/${id}`;
export const urlToWeek = () => `/t/week`;
export const urlToTriggerID = (id) => `/t/trigger/${id}`;
export const urlToTrigger = () => `/t/trigger`;
export const urlToProjectID = (id) => `/t/project/${id}`;
export const urlToProject = () => `/t/project`;
export const urlToActionItemID = (id) => `/t/actionitem/${id}`;
export const urlToMeetingID = (id) => `/t/meeting/${id}`;
export const urlToMeeting = () => `/t/meeting`;
export const urlToMeetingNoteID = (id) => `/t/meetingnote/${id}`;
export const urlToContactID = (id) => `/t/contact/${id}`;

class TaskOneObject {
  constructor(key, name, singular) {
    this.key = key;
    this.name = name;
    this.singular = singular;
    this.domain = "t";
    this.usePrefix = false;
  }

  get uri() {
    return `/${this.domain}/${this.singular}`;
  }

  objectUri(id) {
    const prefix = this.usePrefix ? this.key : "";
    return `/${this.domain}/${this.singular}/${prefix}${id}`;
  }

  withPrefixURI() {
    this.usePrefix = true;
    return this;
  }
}

export const ObjectTypes = {
  MEETINGS: new TaskOneObject("M", "meetings", "meeting"),
  PROJECTS: new TaskOneObject("P", "projects", "project"),
  TRIGGERS: new TaskOneObject("T", "triggers", "trigger"),
  ACTION_ITEMS: new TaskOneObject("I", "actionitems", "actionitem"),
  CONTACTS: new TaskOneObject("G", "contacts", "contact"),
  WEEKS: new TaskOneObject("W", "weeks", "week"),
  MEETINGNOTES: new TaskOneObject("N", "meetingnotes", "meetingnote"),
  HYPERLINKS: new TaskOneObject("L", "hyperlinks", "hyperlink"),
};

const MENU_ENTRIES = [
  {
    name: "Week",
    path: urlToWeek(),
    icon: IconNames.CALENDAR,
  },
  {
    name: "Project",
    path: `/t/project`,
    icon: IconNames.PROJECTS,
  },
  {
    name: "Meeting",
    path: `/t/meeting`,
    icon: IconNames.PEOPLE,
  },
  {
    name: "Meeting Note",
    path: "/t/meetingnote",
    icon: IconNames.ANNOTATION,
  },
  {
    name: "Action Item",
    path: `/t/actionitem`,
    icon: IconNames.ADD_TO_ARTIFACT,
  },
  {
    name: "People",
    path: `/t/contact`,
    icon: IconNames.PERSON,
  },
  {
    name: "Trigger",
    path: urlToTrigger(),
    icon: IconNames.NINJA,
  },
];

export default function TaskOneRouter() {
  const navigate = useNavigate();

  useHotkeys(
    <Hotkeys>
      <Hotkey
        allowInInput
        label="Search Projects"
        combo="ctrl+p"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToProject());
        }}
      />
      <Hotkey
        allowInInput
        label="Search Meetings"
        combo="ctrl+m"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToMeeting());
        }}
      />
      <Hotkey
        allowInInput
        label="Search Weeks"
        combo="ctrl+w"
        global
        onKeyDown={(e) => {
          e.preventDefault();
          navigate(urlToWeek());
        }}
      />
    </Hotkeys>
  );

  return (
    <TaskOneContextProvider>
      <Menu name="Pro" entries={MENU_ENTRIES} />
      <AppModuleConnector
        context={TaskOneContext}
        name="TaskOne"
        objectTypes={ObjectTypes}
        search={search}
      />
      <ErrorDisplay context={TaskOneContext} />

      <Route path="/t/week/:id?">
        <Weeks />
      </Route>

      <Route path="/t/project/:id?">
        <Projects />
      </Route>

      <Route path="/t/meeting/:id?">
        <Meetings />
      </Route>

      <Route path="/t/actionitem/:id?">
        <ActionItems />
      </Route>

      <Route path="/t/contact/:id?">
        <Contacts />
      </Route>
      <Route path="/t/trigger/:id?">
        <Triggers />
      </Route>

      <Route path="/t/meetingnote/:id?">
        <MeetingNotes />
      </Route>
    </TaskOneContextProvider>
  );
}
