export default {
  errors: [],
  nav: null,
  refreshKey: 0,
  meetings: {
    rootID: null,
    byID: {},
    allIDs: [],
    idsBy: {
      actionItemID: {},
      projectID: {},
      hyperlinkID: {},
    },
  },
  meetingnotes: {
    byID: {},
    allIDs: [],
    idsBy: {
      weekID: {},
      meetingID: {},
      actionItemID: {},
    },
  },
  actionitems: {
    byID: {},
    allIDs: [],
    idsBy: {
      triggerID: {},
      weekID: {},
      meetingID: {},
      meetingnoteID: {},
      projectID: {},
    },
  },
  persons: {
    byID: {},
    allIDs: [],
  },
  projects: {
    byID: {},
    allIDs: [],
    rootID: null,
    idsBy: {
      meetingID: {},
      weekID: {},
      actionItemID: {},
      parentID: {},
      hyperlinkID: {},
    },
  },
  triggers: {
    byID: {},
    allIDs: [],
    rootID: null,
    idsBy: {
      actionItemID: {},
    },
  },
  weeks: {
    currentWeekCode: null,
    byID: {},
    allIDs: [],
    idsBy: {
      actionItemID: {},
      projectID: {},
    },
    idBy: {
      code: {},
    },
  },
  hyperlinks: {
    byID: {},
    allIDs: [],
    idsBy: {
      projectID: {},
      meetingID: {},
    },
  },
};
