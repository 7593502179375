import { tget, tpost } from "../../utils/Utils/Utils";

export function fetchEquipmentPicture(id) {
  return new Promise((resolve, reject) => {
    tget(`/api/v2/equipment/${id}/image`).then((result) => {
      resolve(result?.image);
    });
  });
}

export function saveEquipmentPicture(id, image) {
  return new Promise((resolve, reject) => {
    tpost(`/api/v2/equipment/${id}/image`, { image }).then((result) =>
      resolve(result?.image)
    );
  });
}
