import React, { createContext } from "react";
import initialState from "./initialState";
import reducer from "./reducer";

// Create Context Object
export const AppContext = createContext();

export const useAppContext = () => React.useContext(AppContext);

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { errors } = state;

  React.useEffect(() => {
    if (errors.length)
      dispatch({
        type: "ERROR_UP",
        payload: errors,
      });
  }, [errors]);

  async function dispatchAction(action, completeCallback) {
    try {
      await action(dispatch, completeCallback);
    } catch (err) {
      dispatch({ type: "ERROR", payload: err });
    }
  }

  return (
    <AppContext.Provider value={{ state, dispatch, dispatchAction }}>
      {children}
    </AppContext.Provider>
  );
};
