import { EditableText, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { Page } from "../../utils/Layout/Layout";
import { dateTimeFormatter } from "../../utils/Utils/Utils";
import { TaskOneContext } from "../TaskOneContext";
import { actionPersistContact } from "./actions";

export default function Contact() {
  const { state, dispatchAction } = useContext(TaskOneContext);
  const [localState, setLocalState] = React.useState();
  let { id } = useParams();

  const { [id]: person } = state.persons.byID;

  React.useEffect(() => {
    if (person) {
      setLocalState(person);
    }
  }, [person]);

  if (!id) {
    return <NonIdealState icon={IconNames.PERSON}></NonIdealState>;
  }

  if (!localState) return <div>Loading...</div>;

  function handleChange(field, value) {
    setLocalState({ ...localState, [field]: value });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionPersistContact(id, field, value));
  }

  function TextEdit({ field }) {
    return (
      <EditableText
        placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
        value={localState[field]}
        onChange={(e) => handleChange(field, e)}
        onConfirm={(e) => handleConfirm(field, e)}
        selectAllOnFocus={true}
      />
    );
  }

  console.log(localState);

  return (
    <Page>
      <h1>
        C{localState.id}
        {" - "}
        <TextEdit field="name" />
      </h1>
      <hr />

      <table>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{localState.id}</td>
          </tr>
          <tr>
            <td style={{ width: "200px" }}>Created on</td>
            <td>{dateTimeFormatter.format(new Date(localState.createdAt))}</td>
          </tr>
          <tr>
            <td>Last Modified</td>
            <td>{dateTimeFormatter.format(new Date(localState.updatedAt))}</td>
          </tr>
        </tbody>
      </table>
      <hr />
    </Page>
  );
}
