export default {
  errors: [],
  refreshKey: 0,
  operations: {
    byID: {},
    allIDs: [],
    idsBy: {
      account: {},
      year: {},
    },
  },
  budgets: {
    byID: {},
    allIDs: [],
    idsBy: {
      account: {},
      treeIndex: {},
    },
  },
  accounts: {
    byID: {},
    allIDs: [],
    idsBy: {
      account: {},
      type: {},
    },
  },
  checks: {
    byID: {},
    allIDs: [],
  },
  budgetDocuments: {
    byID: {},
    allIDs: [],
  },
  crawler: {
    imports: {
      latests: [],
    },
  },
  cryptos: {
    byID: {},
    allIDs: [],
    idsBy: {
      currency: {},
    },
  },
  cryptostats: {
    byID: {},
    allIDs: [],
  },
};
