import { Checkbox, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { Link } from "react-router-dom";
import { urlToActionItemID } from "../TaskOneRouter";

export default function Checklist({ items, type, onItemAdd, name }) {
  const filteredItems = items.filter((i) => i.type == type);
  const [newItemName, setNewItemName] = React.useState("");

  return (
    <div style={{ marginTop: "20px" }}>
      <strong>{name}</strong>
      {filteredItems.map((item) => {
        return (
          <div key={item.id}>
            <Checkbox>
              <Link to={urlToActionItemID(item.id)}>{item.id}</Link> -{" "}
              {item.name}
            </Checkbox>
          </div>
        );
      })}
      <div>
        <InputGroup
          leftIcon={IconNames.PLUS}
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (newItemName.trim() === "") {
                return;
              }
              onItemAdd(newItemName, type);
              setNewItemName("");
            }
          }}
        />
      </div>
    </div>
  );
}
