import React from "react";
import styled from "styled-components";

export const Browser = (props) => {
  return <div className="mainrouter__browser">{props.children}</div>;
};

const StyledListContainer = styled.div`
  border: solid 2px green;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

export const List = ({ width, children, persistScroll }) => {
  const [scroll, setScroll] = React.useState();
  const ref = React.useRef();

  React.useEffect(() => {
    if (scroll != null && undefined != scroll) {
      sessionStorage.setItem(persistScroll, scroll);
    }
  }, [scroll]);

  React.useEffect(() => {
    const value = sessionStorage.getItem(persistScroll);

    ref.current.scrollTop = value;
  }, []);

  const onScrollHandler = (e) => {
    const s = e.target.scrollTop;
    if (s !== scroll) setScroll(s);
  };

  return (
    <div
      ref={ref}
      className="mainrouter__list"
      style={{ width: width || 375 }}
      onScroll={onScrollHandler}
    >
      {children}
    </div>
  );
};
const StyledLine = styled.div`
  padding: 10px 20px;
  border-bottom: solid 1px grey;
  background: #383e47;
  &:hover {
    background: #cdcdcd;
    cursor: pointer;
  }
`;
export const Line = ({ selected, handleClick, children }) => {
  return (
    <StyledLine
      style={{ background: selected ? "#394b59" : "" }}
      onClick={handleClick}
    >
      {children}
    </StyledLine>
  );
};

export const Page = ({ children }) => {
  return <div className="mainrouter__page">{children}</div>;
};

const StyledFullPage = styled.div`
  padding: 0 20px;
  min-width: 375px;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  overflow-y: scroll;
  overscroll-behavior: none;
`;

export const FullPage = ({ children }) => (
  <StyledFullPage>{children}</StyledFullPage>
);
