import {
  Button,
  ButtonGroup,
  EditableText,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Page } from "../../utils/Layout/Layout";
import StatusBar from "../../utils/StatusBar/StatusBar";
import Status from "../../utils/StatusLabel/Status";
import { dateTimeFormatter } from "../../utils/Utils/Utils";
import ActionItemsComponent from "../ActionItem/ActionItemsComponent";
import HyperlinksComponent from "../Hyperlink/HyperlinksComponent";
import MeetingsComponent from "../Meeting/MeetingsComponent";
import { TaskOneContext } from "../TaskOneContext";
import { urlToProjectID } from "../TaskOneRouter";
import {
  actionAddProjectToWeek,
  actionRemoveProjectFromWeek,
} from "../Week/actions";
import { selectCurrentWeekID } from "../Week/selectors";
import WeekListComponent from "../Week/WeekListComponent";
import { actionPersistProject } from "./actions";
import ProjectsComponent from "./ProjectsComponent";

export default function Project() {
  const { state, dispatch, dispatchAction } = useContext(TaskOneContext);
  const [projectState, setProjectState] = React.useState(null);

  const [alertOpen, setAlertOpen] = useState(false);
  let { id } = useParams();

  const { [id]: projectFromCache } = state.projects.byID;

  React.useEffect(() => {
    if (projectFromCache) {
      setProjectState(projectFromCache);
    }
  }, [projectFromCache]);

  if (!id) {
    return <NonIdealState icon={IconNames.PROJECTS}></NonIdealState>;
  }

  if (!projectState) {
    return <div>Loading...</div>;
  }

  const { [projectState.parent_id]: parent } = state.projects.byID;
  const { [id]: childrenIDs } = state.projects.idsBy.parentID;
  const { [projectState.id]: meetingIDs } = state.meetings.idsBy.projectID;
  const { [projectState.id]: weeksIDs } = state.weeks.idsBy.projectID;
  const { [id]: actionItemIDs } = state.actionitems.idsBy.projectID;
  const currentWeekID = selectCurrentWeekID(state);

  function handleChange(field, value) {
    setProjectState({ ...projectState, [field]: value });
  }

  function handleConfirm(field, value) {
    dispatchAction(actionPersistProject(id, field, value));
  }

  /*
  function selected(key) {
    if (key === project.status) {
      if (key == 'In Progress') return { intent: Intent.WARNING };
      if (key == 'Todo') return { intent: Intent.WARNING };
      if (key == 'Done') return { intent: Intent.SUCCESS };
      return { intent: Intent.PRIMARY };
    }
    return null;
  }*/

  function drawLine(field, placeholder, multiline) {
    return (
      <tr>
        <td>{field.charAt(0).toUpperCase() + field.slice(1)}</td>
        <td>
          <EditableText
            multiline={multiline}
            placeholder={placeholder || "Add " + field + " ..."}
            value={projectState[field]}
            onChange={(e) => handleChange(field, e)}
            onConfirm={(e) => handleConfirm(field, e)}
            selectAllOnFocus
          />
        </td>
      </tr>
    );
  }

  return (
    <Page>
      <h1>
        <div style={{ float: "right", textAlign: "right" }}>
          <EditableText
            placeholder="Icon"
            value={projectState.icon}
            onChange={(e) => handleChange("icon", e)}
            onConfirm={(e) => handleConfirm("icon", e)}
            selectAllOnFocus={true}
          />
        </div>
        <EditableText
          placeholder="Project Name"
          value={projectState.name}
          onChange={(e) => handleChange("name", e)}
          onConfirm={(e) => handleConfirm("name", e)}
          selectAllOnFocus={true}
        />
      </h1>
      <StatusBar
        status={projectState.status}
        onChange={(status) => handleConfirm("status", status)}
      />{" "}
      <ButtonGroup>
        <Button
          text="Move"
          draggable={true}
          onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", id);
            e.dataTransfer.dropEffect = "move";
          }}
        />
      </ButtonGroup>{" "}
      <ButtonGroup>
        <Button onClick={(e) => this.handleReviewed()}> Reviewed</Button>
      </ButtonGroup>{" "}
      <ButtonGroup>
        <Button
          onClick={(e) =>
            weeksIDs?.includes(currentWeekID)
              ? dispatchAction(
                  actionRemoveProjectFromWeek(
                    projectFromCache.id,
                    currentWeekID
                  )
                )
              : dispatchAction(
                  actionAddProjectToWeek(projectFromCache.id, currentWeekID)
                )
          }
          active={weeksIDs?.includes(currentWeekID)}
        >
          This week
        </Button>
      </ButtonGroup>
      {/*       {this.state.moveSwitch && (
            <div style={{ marginBottom: "1.5em", marginTop: "1.5em" }}>
              <h3>Move to </h3>
              <Search onSelected={this.onSelected} />
            </div>
          )} */}
      <hr />
      <table>
        <tbody>
          <tr>
            <td>Id</td>
            <td>{projectState.id}</td>
          </tr>
          {drawLine("type")}

          {parent !== undefined && (
            <tr>
              <td>Parent</td>
              <td>
                <Link to={urlToProjectID(projectState.parent_id)}>
                  {parent.name}
                </Link>
              </td>
            </tr>
          )}
          <tr>
            <td style={{ width: "200px" }}>Created on</td>
            <td>
              {dateTimeFormatter.format(new Date(projectState.createdAt))}
            </td>
          </tr>
          <tr>
            <td>Last Modified</td>
            <td>
              {dateTimeFormatter.format(new Date(projectState.updatedAt))}
            </td>
          </tr>
          <tr>
            <td>Deleted</td>
            <td>{projectState.deleted ? "🚫" : ""}</td>
          </tr>
          {projectState.status !== null && (
            <tr>
              <td>Status</td>
              <td>
                <Status status={projectState.status} />
              </td>
            </tr>
          )}

          {projectState.status == null && (
            <tr>
              <td>Status</td>
              <td> -</td>
            </tr>
          )}
          {drawLine("location")}
        </tbody>
      </table>
      <hr />
      <h3>Project's Notes</h3>
      <EditableText
        multiline
        minLines={3}
        value={projectState.content}
        onChange={(e) => handleChange("content", e)}
        onConfirm={(e) => handleConfirm("content", e)}
      />
      <HyperlinksComponent context={{ project_id: id }} type="PROJECT" input />
      <ActionItemsComponent context={{ project_id: id }} ids={actionItemIDs} />
      <MeetingsComponent context={{ project_id: id }} ids={meetingIDs} />
      <ProjectsComponent context={{ project_id: id }} ids={childrenIDs} />
      <WeekListComponent context={{ project_id: id }} ids={weeksIDs} />
      <div style={{ height: "300px" }}></div>
    </Page>
  );
}
