import { weekNumber } from "weeknumber";

var tzDiff = function tzDiff(first, second) {
  return first.getTimezoneOffset() - second.getTimezoneOffset();
};

export function currentWeekCode(optionalDate = null) {
  const date = optionalDate ? optionalDate : new Date();
  const week = weekNumber(date);
  const year = date.getFullYear();
  const weekFormated = week < 10 ? `0${week}` : week;
  return `${year}_W${weekFormated}`;
}

export function dateFromYearWeek(year, week) {
  // Jan 1st
  const date = new Date(year, 0, 1, 12);
  const day = date.getDay();

  if (day > 3) {
    // First day of the year is in last last year week
    // First week is (8-day) days after jan1st
    date.setDate(date.getDate() + (8 - day));
  } else {
    // First day of the year is in the first week of year
    // First weeks start ( n day - 1) days before jan1st
    date.setDate(date.getDate() + (1 - day));
  }

  date.setDate(date.getDate() + (week - 1) * 7);
  date.setMinutes(date.getMinutes() + tzDiff(new Date(), date));

  return date;
}
