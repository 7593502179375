import React, { useEffect } from "react";
import { useAppContext } from "../app/AppContext";
import {
  actionFetchAccounts,
  actionFetchBudget,
  actionFetchCrawlerImportsLatest,
  actionFetchOperations,
  actionRunHealthcheck,
} from "./actions";
import initialState from "./initialState";
import reducer from "./reducer";

export const BankContext = React.createContext();

export const useBankContext = () => React.useContext(BankContext);

export function BankContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { dispatch: appDispatch } = useAppContext();
  const { refreshKey, errors } = state;
  const [timerIndex, setTimerIndex] = React.useState(0);

  React.useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setTimerIndex(timerIndex + 1);
    }, 60 * 1000);
    return () => clearTimeout(interval);
  }, [timerIndex]);

  useEffect(() => {
    if (errors.length)
      appDispatch({
        type: "ERROR_UP",
        payload: errors,
      });
  }, [errors]);

  useEffect(() => {
    dispatchAction(actionFetchAccounts());
    dispatchAction(actionFetchBudget());
    dispatchAction(actionFetchOperations());
    dispatchAction(actionFetchCrawlerImportsLatest());
  }, [refreshKey]);

  useEffect(() => {
    dispatchAction(actionRunHealthcheck(state));
  }, [state.operations, state.budgets]);

  async function dispatchAction(action, completeCallback) {
    try {
      await action(dispatch, completeCallback);
    } catch (err) {
      dispatch({ type: "ERROR", payload: err });
    }
  }

  return (
    <BankContext.Provider value={{ state, dispatch, dispatchAction }}>
      {children}
    </BankContext.Provider>
  );
}
