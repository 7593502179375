import React, { useContext, useEffect } from "react";
import { useAppContext } from "../../app/AppContext";

export default function AppModuleConnector({
  context,
  name,
  search,
  objectTypes = [],
}) {
  const { dispatch: appDispatch } = useAppContext();
  const { state, dispatch } = useContext(context);

  const refresh = React.useCallback(() => {
    dispatch({ type: "REFRESH_DATA" });
  }, []);

  const logState = React.useCallback(() => {
    dispatch({ type: "LOG_STATE" });
  }, []);

  const clearErrors = React.useCallback(() => {
    dispatch({ type: "CLEAR_ERRORS" });
  }, []);

  const searchCallback = React.useCallback((searchProps) => {
    try {
      return search(state, searchProps);
    } catch (e) {
      console.error("Search failed for module " + name, e);
    }
  }, []);

  const ContextAPI = {
    name,
    refresh,
    logState,
    clearErrors,
    search: searchCallback,
    objectTypes,
  };

  useEffect(() => {
    appDispatch({ type: "ADD_API", payload: ContextAPI });

    return () => {
      appDispatch({ type: "REMOVE_API", payload: ContextAPI });
    };
  }, []);

  return null;
}
