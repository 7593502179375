const card = `<mxGraphModel>
    <root>
        <mxCell id="0" />
        <mxCell id="1" parent="0" />
        <mxCell id="2" value="" style="rounded=0;whiteSpace=wrap;html=1;strokeColor=#000000;strokeWidth=1;fillColor=#EDF1F7;container=0;" vertex="1" parent="1">
            <mxGeometry x="-320" y="-40" width="120" height="160" as="geometry" />
        </mxCell>
        <mxCell id="3" value="&lt;b&gt;##EQUIPMENT##&lt;/b&gt;&lt;br style=&quot;font-size: 10px&quot;&gt;##METADATA##" style="rounded=0;whiteSpace=wrap;html=1;strokeColor=default;strokeWidth=1;fillColor=#FFFFFF;fontSize=10;" vertex="1" parent="2">
            <mxGeometry y="120" width="120" height="40" as="geometry" />
        </mxCell>
        <mxCell id="4" value="" style="shape=image;verticalLabelPosition=bottom;labelBackgroundColor=#ffffff;verticalAlign=top;imageAspect=1;image=##IMAGE##;" vertex="1" parent="2">
            <mxGeometry x="5" y="5" width="110" height="90" as="geometry" />
        </mxCell>
        <mxCell id="5" value="E##ID##" style="rounded=1;whiteSpace=wrap;html=1;labelBackgroundColor=none;strokeWidth=1.2;fontSize=10;glass=0;comic=0;shadow=0;spacing=0;spacingTop=-3;align=center;spacingLeft=1;arcSize=29;" vertex="1" parent="2">
            <mxGeometry x="44" y="100" width="32" height="12" as="geometry" />
        </mxCell>
    </root>
</mxGraphModel>`;

export function generateCard(id, name, metadata, imgdata) {
  const img = imgdata.replace(";base64", "");
  const c = card
    .replace("##IMAGE##", img)
    .replace("##EQUIPMENT##", htmlEntities(name))
    .replace("##ID##", id)
    .replace("##METADATA##", metadata);
  return encodeURIComponent(c);
}

function htmlEntities(str) {
  const htmlEntities = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&apos;",
  };

  return str.replace(/([&<>\"'])/g, (match) => htmlEntities[match]);
}
