import { Button } from "@blueprintjs/core";
import React from "react";
import "./textedit.css";

export default function TextEdit({ value, onChange, placeholder, onConfirm }) {
  const [changed, setChanged] = React.useState(false);
  const element = React.useRef(null);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      element.current.style.height = "1px";
      element.current.style.height = element.current.scrollHeight + "px";
    }, 20);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <>
      <textarea
        ref={element}
        value={value}
        className={["textedit", changed && "textedit_changed"].join(" ")}
        placeholder={placeholder}
        onChange={(e) => {
          setChanged(true);
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key == "Enter" && e.metaKey == true) {
            e.preventDefault();
            onConfirm && onConfirm(value);
            setChanged(false);
          }
        }}
      ></textarea>
      {changed && (
        <div className="textedit__controlpanel">
          <Button
            onClick={() => {
              onConfirm && onConfirm(value);
              setChanged(false);
            }}
          >
            Save
          </Button>{" "}
          {value.split(" ").length} words
        </div>
      )}
    </>
  );
}
