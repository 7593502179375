import { Button, HTMLSelect } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useTaskOneContext } from "../TaskOneContext";
import { actionCreateNewWeek } from "../Week/actions";
import { getAllActiveWeeksNames } from "../Week/selectors";
import { actionMeetingNoteCreate } from "./actions";
import MeetingNoteComponent from "./MeetingNoteComponent";
import { getSortedMeetingNoteIDsForMeeting } from "./selectors";

export default function MeetingNotes({ meetingID }) {
  const { state, dispatchAction } = useTaskOneContext();
  const [weekID, setWeekID] = React.useState();
  const weeksNames = getAllActiveWeeksNames(state);

  const meetingNoteIDs = getSortedMeetingNoteIDsForMeeting(state, meetingID);

  React.useEffect(() => {
    if (weeksNames.length > 0) {
      setWeekID(weeksNames[0].id);
    }
  }, [weeksNames.length]);

  return (
    <div>
      <h3>MeetingNotes</h3>
      <Button
        icon={IconNames.PLUS}
        onClick={() => {
          dispatchAction(actionCreateNewWeek());
        }}
      ></Button>
      <HTMLSelect
        value={weekID}
        onChange={(e) => {
          setWeekID(e.target.value);
        }}
      >
        {weeksNames.map((weekName) => (
          <option value={weekName.id} key={weekName.id}>
            {weekName.key}
          </option>
        ))}
      </HTMLSelect>{" "}
      <Button
        onClick={() => {
          dispatchAction(actionMeetingNoteCreate(meetingID, weekID));
        }}
      >
        New meeting note
      </Button>
      <Button>This week</Button>
      <Button>Next week</Button>
      <div>
        {meetingNoteIDs?.map((meetingnote) => (
          <MeetingNoteComponent key={meetingnote.id} id={meetingnote.id} />
        ))}
      </div>
    </div>
  );
}
